const questions = [
  {
    id : 100,
    title: "آیا دوره های آموزشی حضوری هم دارید؟",
    text: "خیر. در حال حاضر آموزش ها فقط به صورت آنلاین است اما به زودی بستر آموزش های حضوری نیز مهیا خواهد شد.",
  },
  {
    id : 200,
    title: "چگونه می‌توان دوره‌های آموزشی را متناسب با سطح خود انتخاب کرد؟",
    text: "آموزش های ما تمام سطوح را از مبتدی تا پیشرفته در برمی گیرد و ما با آگاهی از نیازهای آموزشی شما، در تمام سطوح آموزش های لازم را تهیه کرده ایم. "
  },
  {
    id : 300,
    title: " دوره ستاره شناسی، برای چه افرادی توصیه می شود؟",
    text: "این دوره مختص تمام افراد به ویژه صاحبان کسب و کار و افراد فعال در بازارهای مالی است و همچنین برای مادران گرامی توصیه می شود.",
  },
  {
    id : 400,
    title: " نحوه ارتباط با مجموعه چگونه است؟",
    text: "از طریق لینک های پشتیبانی که در سایت قرار داده شده است.",
  },
  {
    id : 500,
    title:"آیا امکان دانلود جلسات دوره ها وجود دارد؟",
    text: "خیر. دوره ها به شکل آنلاین از طریق نرم افزار اسپات پلیر قابل دیدن است.",
  },
  {
    id : 600,
    title: "آیا دوره ها پشتیبان آموزشی دارد؟",
    text: "بله. تمام دوره ها دارای پشتیبان آموزشی سه ماه تا یکساله است. ",
  },
  {
    id : 700,
    title: "در مورد پشتیبانی مختصری توضیح دهید.",
    text: "پشتیبانی از طریق تلگرام و توسط پشتیبان ها صورت می پذیرد و در پایان هر ماه تمام سؤالات توسط استاد در قالب ویدئو پاسخ داده می شود و این ویدئو در دسترس تمام دانشجویان دوره قرار می گیرد.  ",
  },
];
export default questions;
