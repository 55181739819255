import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { Input, Select, Table, Pagination, PrintModal, Button } from "../../../../components";
import { paymentMethods, purchaseStatus , pass } from "../../../../constants";
import { convertPhone, jsonToXlsx, scrollToTop } from "../../../../methods";
import { axios, moment } from "../../../../boot";
import Purchase from "../Purchase";


export default function Tether() {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [purchases, setPurchases] = useState([]);
  const [pages, setPages] = useState({});
  const [modalInfo, setModalInfo] = useState(null)
  const [ update , setUpdate ] = useState(0)
  const [ phone , setPhone ] = useState("")

  const saveParams = () => {
    const value = JSON.stringify(params);
    sessionStorage.setItem("params", value);
  };
  const getPurchases = (enter) => {
    saveParams();
    const url = "/admins/shop/accountantPurchases";
    const config = {
      params: {
        userName :enter,
        pass,
        sort: "createdAt:-1",
        perPage: 20,
        paymentMethod : "USD",
        ...params,
      },
    };
    params.date &&
      ([config.params.startDate, config.params.endDate] = params.date);
    delete config.params.date;
    config.params.phone && (config.params.phone = convertPhone(params.phone))
    axios.get(url, config).then(({ data }) => {
      setPurchases(data.data);
      setPages(data.pages);
      scrollToTop();
    });
  };
  const downloadXlsx = async () => {
    const url = "/admins/shop/accountantPurchases";
    const perPage = 100;
    const totalPages = Math.ceil(pages.totalItems / perPage);
    const config = (page) => ({ params: { ...params,userName : phone , pass , perPage, page , paymentMethod : "USD" } });
    const getAllData = await [...Array(totalPages)]
      .map((e, i) => i + 1)
      .map(
        async (page) => await (await axios.get(url, config(page))).data?.data
      );
    const allData = await Promise.all(getAllData);
    // console.log("all Data : " , allData)
    const title = "report";
    
    const json = allData.flat().map((e , index) => ({
      "ردیف" : `${index+1}` ,
      تاریخ: moment.miladiToShamsi({ date: e.createdAt }),
      خدمات: e.items
      ?.map((e) => e?.product ? `${e.product?.title_fa} ${e.count} عدد` : `${e.course?.title} ${e.count} عدد`)
      .join(" - "),
      "نوع مشترک" : "حقیقی",
      "کد ملی"  : `${e?.codeMelli ? e?.codeMelli : (e.user?.codeMelli ? e.user?.codeMelli : "" ) }`,
      "نام کاربر": `${e?.shipping?.firstName ? e?.shipping?.firstName : e.user?.firstName} ${e?.shipping?.lastName ? e?.shipping?.lastName : e.user?.lastName}`,
      آدرس: `${e.shipping?.address ? e.shipping?.address : "" }`,
      کدپستی: `${e.shipping?.postCode ? e.shipping?.postCode : "" }`,
      "شماره موبایل": `${convertPhone(e.shipping?.phone)}`,
      "نوع پرداخت": paymentMethods.find((mt) => mt.id === e.paymentMethod)
        ?.name,
      قیمت: `${e.totalPrice} تومان -  ${e.totalPriceUsd} تتر`,
      // تخفیف: !!e.voucher
      //   ? `${e.offPrice} تومن -  ${e.offPriceUsd} تتر`
      //   : "بدون تخفیف",
      وضعیت: purchaseStatus.find((it) => it.id === e.status)?.name,
    }));
    jsonToXlsx({ json, title });
  };
  const formControls = [
    {
      label: "تاریخ",
      state: "date",
      type: "date",
      cancelBtn: true,
    },
    {
      label: "نام",
      state: "firstName",
      type: "text",
      clear: true,
    },
    {
      label: "نام خانوادگی",
      state: "lastName",
      type: "text",
      clear: true,
    },
    {
      label: "شماره پیگیری",
      state: "refId",
      type: "number",
      clear: true,
    },
    {
      label: "شماره تلفن",
      state: "phone",
      type: "number",
      clear: true,
    },
    
    {
      tag: Select,
      label: "وضعیت",
      state: "status",
      cancelBtn: true,
      items: purchaseStatus.filter((e) => e.filterable),
    },
  ];
  const showStatus = (id = 0) => {
    const { color, name } = purchaseStatus.find((e) => e.id === id) ?? {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };

  useEffect(()=>{
    const getPhone = JSON.parse(localStorage.getItem('phone'))
    setPhone(getPhone)
    getPurchases(getPhone)
  }, [params, update]);
  return (
    <React.Fragment>
      <PrintModal show={modalInfo != null} onHide={() => setModalInfo(null)} size="lg">
        <Purchase data={modalInfo} onUpdate={setUpdate} />
      </PrintModal>

      
      <div>
        <button
          className="bi bi-filetype-xlsx fs-3 text-success"
          onClick={downloadXlsx}
        />
      </div>
      <Row className="d-print-none">
        {formControls.map((e) => (
          <Col key={e.state} xs="12" md="6" lg="4">
            {React.createElement(e.tag ?? Input, {
              ...e,
              value: params[e.state],
              setValue: (val) => {
                setPurchases([]);
                setParams((p) => ({ ...p, page: 1, [e.state]: val  }));
              },
            })}
          </Col>
        ))}
      </Row>
      
      <Table className="d-print-none">
        <thead>
          <tr>
            <th>شماره پیگیری</th>
            <th>نام خریدار</th>
            <th>شماره تلفن</th>
            <th>تاریخ</th>
            <th>مبلغ نهایی</th>
            <th>تخفیف</th>
            <th>نوع خدمت</th>
            <th>وضعیت</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {purchases.map((e, i) => (
            <tr key={e._id + i}  >
              <td>{e?.refId ? e.refId : ""}</td>
              <td onClick={() => setModalInfo(e)}>
              {e.shipping?.firstName ? e.shipping?.firstName : e.user?.firstName } {e.shipping?.lastName ? e.shipping?.lastName : e.user?.lastName}
              </td>
              <td>{convertPhone(e.shipping?.phone)}</td>
              <td onClick={() => setModalInfo(e)}>
                <span dir="ltr">
                  {moment.miladiToShamsi({
                    date: e.createdAt,
                    format: "jYYYY/jMM/jDD - HH:mm:ss",
                  })}
                </span>
              </td>
              <td onClick={() => setModalInfo(e)} className="text-success">{`${e.totalPrice} تومان -  ${e.totalPriceUsd} تتر`}</td>
              <td onClick={() => setModalInfo(e)} className="text-danger">
                {!!e.voucher
                  ? `${e.offPrice} تومن -  ${e.offPriceUsd} تتر`
                  : "بدون تخفیف"}
              </td>
              <td onClick={() => setModalInfo(e)} className="fw-bold">
                {e?.items?.map((item)=> item.course ? " آموزش " : " کتاب  ").join(" - ")}
              </td>
              <td onClick={() => setModalInfo(e)}>{showStatus(e.status)}</td>
              
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
      {/* {params.page < pages.totalPages && (
        <button
          className="d-block mx-auto w-fit text-primary"
          onClick={() => {
            setParams((p) => ({ ...p, page: p.page + 1 }));
          }}
        >
          نمایش بیشتر...
        </button>
      )} */}
    </React.Fragment>
  );
}
