import React, { useRef, useState, useEffect } from 'react'

import { Col, Row, Container } from 'react-bootstrap'
import { PopularClassCard } from '../../../../components/NewVersion'
import { Link, NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper'
// import { HiOutlineArrowSmRight, HiOutlineArrowSmLeft } from 'react-icons/hi'
// import Pic1 from '../../../../assets/images/Home/pic1.png'
// import Pic7 from '../../../../assets/images/Home/pic7.png'
import { axios } from '../../../../boot'

const PopularClass = () => {
    const [data, setData] = useState([])

    const getData = () => {
        const url = "/pub/shop/bestcourses"
        axios.get(url).then(({ data }) => {
            const res = data?.data?.filter(e => e.status !== "disable")
            setData(res)
        })
    }
    const swiperRef = useRef();
    SwiperCore.use([Navigation])
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    useEffect(getData, [])
    return (
        <div className="land-PopularClass">
            <Container className="d-flex flex-wrap align-items-center justify-content-md-between justify-content-start">
                <div>
                    <p className=" text-center text-lg-right fw-700 fs-18 lh-md text-white  d-lg-inline-block mx-2">پرطرفدارترین کلاس های آموزشی</p>
                    <Link className="text-primary fs-14 fw-400 mx-2 d-lg-inline-block " to='/tutorials'>مشاهده همه</Link>
                </div>
                <div className="d-flex">
                    <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-dark-black text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slidePrev()}> <i className="bi bi-arrow-right-short fs-20"></i> </button>
                    <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-dark-black text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slideNext()}> <i className="bi bi-arrow-left-short fs-20"></i> </button>
                </div>
            </Container>
            <div className=" PopularClass-swiper w-100 d-flex  justify-content-between align-items-center py-2 ">
                <Swiper
                    className="w-100 py-4"
                    // centeredSlides={true}
                    spaceBetween={10}
                    initialSlide={1}
                    pagination={{ clickable: true }}
                    modules={[Navigation, Scrollbar, Pagination]}
                    breakpoints={{
                        1200: {
                            slidesPerView: 5,
                        },
                        992: {
                            slidesPerView: 4,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        0: {
                            slidesPerView: 2,
                        },
                    }}
                    slidesPerView={3}
                    autoplay={{
                        delay: 2500,
                    }}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                // scrollbar={{ draggable: true }}
                >
                    {data.map((item) =>
                        <SwiperSlide key={item._id} className="h-auto d-flex justify-content-center">
                            <PopularClassCard {...item} />
                        </SwiperSlide>
                    )}

                </Swiper>
            </div>
            {/* <div className="w-100 d-flex justify-content-center align-items-center  p-4">
                <Link to='/tutorials' className="btn btn-gray1 Button font-sans fs-12 py-2 px-4 fw-500 mx-auto">
                    مشاهده همه دوره‌ها
                </Link>
            </div> */}
        </div>
    )
}

export default PopularClass