import React ,  {useState , useEffect} from 'react'

import { useNavigate, useParams } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import { Col, ButtonGroup } from "react-bootstrap";
import { rules, roles , accept } from "../../../constants";
import { fileToBase64, toast } from "../../../methods";
import { Form, AdminInput, Select, Button } from "../../../components";
import { axios } from "../../../boot";

const Media = () => {
    const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const isNewUser = params.id === "new";
  const fromControls = [
    {
      label: "عنوان",
      state: "title",
    },
    {
      label: "فایل",
      state: "file",
      type : "file",
      accept: accept.every,
      rules: rules.optional,
      
    },
    
  ];
  const getData = () => {
    if (!isNewUser) {
      const url = `/admins/pub/files/${params.id}`;
      axios.get(url).then(({ data }) => {
        // const body = cloneDeep(data);
        // body.phone = convertPhone(data.phone);
        console.log(data)
        setData(data);
      });
    }
  };
  const files = ["video", "faSubtitle", "thumbnail" , "image" , "file"];
  const addMedia = async () => {
    const url = "/admins/pub/upload";
    // const body = { ...data };
    // body.image && (body.image = await fileToBase64(body.image[0]));
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      const isFile = files.includes(key);
      body.append(key, isFile ? data[key][0] : data[key]);
    });

    axios.post(url, body).then(() => {
      toast({});
      navigate("/admin/medias", { replace: true });
    });
  };
  const changeMedia = async () => {
    const url = "/admins/pub/files";
    // const body = cloneDeep(data);
    // delete body.createdAt;
    // delete body.updatedAt;
    // delete body.__v
    // body.file = await fileToBase64(body.image[0])
    const body = new FormData();
    Object.keys(data).forEach((key) => {
      const isFile = files.includes(key);
      if(key !== "createdAt" && key !== "updatedAt"){
        body.append(key, isFile ? data[key][0] : data[key]);
      }
      
    });

    console.log(body)
    axios.put(url, body).then(() => {
      toast({});
    });
  };
  const deleteUser = () => {
    const message = "آیا از درخواست خود اطمیمان دارید؟";
    if (window.confirm(message)) {
      const url = "/admins/pub/files";
      const body = { _id: params.id };
      axios.delete(url, { data: body }).then(() => {
        toast({});
        navigate("/admin/medias", { replace: true });
      });
    }
  };
  useEffect(getData, []);
  return (
    <Form onSubmit={isNewUser ? addMedia : changeMedia} className="row">
      <h5 className="text-center col-12">
        {isNewUser ? "اضافه کردن رسانه جدید" : "اطلاعات رسانه"}
      </h5>
      {fromControls.map((props, index) => (
        <Col key={index} xs="12" md={index === 3 ? "12" : "6"}>
          {React.createElement(props.tag ?? AdminInput, {
            ...props,
            value: data[props.state],
            setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
          })}
        </Col>
      ))}
      <div className="col-12 d-flex flex-center">
        <ButtonGroup>
          {!isNewUser && (
            <Button variant="danger" onClick={deleteUser}>
              حذف
            </Button>
          )}
          <Button type="submit">ثبت</Button>
        </ButtonGroup>
      </div>
    </Form>
  )
}

export default Media