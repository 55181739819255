import React, { useEffect, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Button, Form, Input, Modal } from "../../../../components";
import { rules, purchaseStatus , paymentMethods, pass } from "../../../../constants";
import MailingLabel from "./MailingLabel";
import PayInfo from "./PayInfo";
import ShowInfo from "./ShowInfo";
import { convertPhone, jsonToXlsx, toast } from "../../../../methods";
import { axios, moment } from "../../../../boot";


export default function CardPurchase({ data = {}, onUpdate = () => {} , phone = ""}) {
  
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  // const [info, setInfo] = useState(data);
  const [reason, setReason] = useState("");
  // const [address, setAddress] = useState("")
  // const getInfo = () => {
  //   const url = `/admins/shop/purchases/${params.id}`;
  //   axios.get(url).then(({ data }) => {
  //     setInfo(data);
  //   });
  // };
  const updateStatus = (status = "") => {
    data = { ...data, status };
  };
  const verifyPurchase = (status = "") => {
    const url = "/admins/shop/purchases/accountantverify";
    const body = {
      _id: data._id,
      status,
      userName : `+${phone}`,
      pass
    };
    if(status === "rejected"){
      body.reason = reason
    }
    axios.post(url, body).then(() => {
      toast({});
      updateStatus(status);
      setShowModal(false);
      setReason("")
      onUpdate(p => p+1)
    });
  };
  // const updatePurchase = (status = "") => {
  //   const url = "/admins/shop/purchases/accountantverify";
  //   const body = { _id: data._id, status };
  //   body.userName = `+${phone}`
  //   body.pass = "marvel123654"
  //   axios.post(url, body).then(() => {
  //     toast({});
  //     updateStatus(status);
  //     onUpdate(p => p+1)
  //   });
  // };

  const showChangeStatus = !purchaseStatus
    .filter((e) => e.isUpdateStatus)
    .map((e) => e.id)
    .includes(data?.status);

    const downloadXlsx = async () => {
      // const url = "/admins/shop/purchases";
      // const perPage = 100;
      // const totalPages = Math.ceil(pages.totalItems / perPage);
      // const config = (page) => ({ params: { ...params, perPage, page } });
      // const getAllData = await [...Array(totalPages)]
      //   .map((e, i) => i + 1)
      //   .map(
      //     async (page) => await (await axios.get(url, config(page))).data?.data
      //   );
      const title = "report";
      const allData = [...Array(data)]
      const json = allData.flat().map((e , index) => ( {
        "ردیف" : `1` ,
        تاریخ: moment.miladiToShamsi({ date: data?.createdAt }),
        خدمات: data?.items && data?.items
        ?.map((e) => e?.product ? `${e.product?.title_fa} ${e.count} عدد` : `${e.course?.title} ${e.count} عدد`)
        .join(" - "),
        "نوع مشترک" : "حقیقی",
        "نام کاربر": `${e?.shipping?.firstName ? e?.shipping?.firstName : e.user?.firstName} ${e?.shipping?.lastName ? e?.shipping?.lastName : e.user?.lastName}`,
        "کد ملی"  :`${data?.codeMelli ? data?.codeMelli : (data.user?.codeMelli ? data.user?.codeMelli : "" ) }`,
        آدرس: `${data?.shipping?.address ? data?.shipping?.address : ""}`,
        کدپستی: `${data?.shipping?.postCode ? data?.shipping?.postCode : "" }`,
        "شماره موبایل": `${convertPhone(data?.shipping?.phone)}`,
        "نوع پرداخت": paymentMethods.find((mt) => mt.id === data?.paymentMethod)
          ?.name,
          تخفیف: !!data.voucher
          ? `${data.offPrice} تومن -  ${data.offPriceUsd} تتر`
          : "بدون تخفیف",
        قیمت: `${data?.totalPrice} تومان -  ${data?.totalPriceUsd} تتر`,
        وضعیت: purchaseStatus.find((it) => it.id === data?.status)?.name,
      }));
      jsonToXlsx({ json, title });
    };
  return (
    <div style={{ overflow: "hidden" }}>
      <Row className="row-gap-4 d-print-none PURCHASE">
        <ShowInfo data={data} />
        {data?.pay && <PayInfo pay={data?.pay} />}
        {data?.status !== "rejected" && (
          <Col xs="12" className="d-print-none d-flex flex-center">
            <ButtonGroup>
              {showChangeStatus ? (
                <React.Fragment>
                  <Button
                    variant="success"
                    onClick={() => verifyPurchase("accepted")}
                  >
                    تایید پرداخت
                  </Button>
                  <Button variant="danger" onClick={() => setShowModal(p => p = !p)}>
                    رد پرداخت
                  </Button>
                </React.Fragment>
              ) : null}
            </ButtonGroup>
          </Col>
        )}
      </Row>
      <MailingLabel info={data} />
      <Button
      variant="success"
        className="d-print-none mt-5 d-block mx-auto"
        onClick={() => downloadXlsx()}
      >
        اکسل پرداخت
      </Button>
      {showModal &&
        <Form className="d-block d-print-none row" onSubmit={() => verifyPurchase("rejected")} >
          <Col xs="12">
            <Input
              label="توضیحات"
              as="textarea"
              value={reason}
              setValue={setReason}
              rules={rules.required}
            />
          </Col>
          <Col xs="12">
            <Button type="submit" className="w-100">
              ثبت دلیل و رد پرداخت
            </Button>
          </Col>
        </Form>
      }
    </div>
  );
}
