import React ,  {useState , useEffect} from 'react'

import { useNavigate, useParams } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import { Col, ButtonGroup } from "react-bootstrap";
import { rules, roles } from "../../../constants";
import { fileToBase64, toast } from "../../../methods";
import { Form, AdminInput, Select, Button } from "../../../components";
import { axios } from "../../../boot";

const Teacher = () => {
    const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const isNewUser = params.teacherId === "new";
  const fromControls = [
    {
      label: "نام و نام خانوادگی",
      state: "name",
    },
    {
      label: "عنوان",
      state: "post",
      
    },
    {
      type : "file",
      label: "عکس",
      state: "image",
    },
    { 
        label: "توضیحات",
        state: "description",
        as: "textarea",
      },
  ];
  const getData = () => {
    if (!isNewUser) {
      const url = `/admins/pub/shop/teachers/${params.teacherId}`;
      axios.get(url).then(({ data }) => {
        // const body = cloneDeep(data);
        // body.phone = convertPhone(data.phone);
        console.log(data)
        setData(data);
      });
    }
  };
  const addNewUser = async () => {
    const url = "/admins/pub/shop/teachers";
    const body = { ...data };
    body.image && (body.image = await fileToBase64(body.image[0]));
    axios.post(url, body).then(() => {
      toast({});
      navigate("/admin/teachers", { replace: true });
    });
  };
  const changeUser = async () => {
    const url = "/admins/pub/shop/teachers";
    const body = cloneDeep(data);
    delete body.createdAt;
    delete body.updatedAt;
    delete body.__v
    body.image = await fileToBase64(body.image[0])
    console.log("body : ",body)
    axios.put(url, body).then(() => {
      toast({});
    });
  };
  const deleteUser = () => {
    const message = "آیا از درخواست خود اطمیمان دارید؟";
    if (window.confirm(message)) {
      const url = "/admins/pub/shop/teachers";
      const body = { _id: params.teacherId };
      axios.delete(url, { data: body }).then(() => {
        toast({});
        navigate("/admin/teachers", { replace: true });
      });
    }
  };

  useEffect(getData, []);
  return (
    <Form onSubmit={isNewUser ? addNewUser : changeUser} className="row">
      <h5 className="text-center col-12">
        {isNewUser ? "اضافه کردن کاربر جدید" : "اطلاعات کاربر"}
      </h5>
      {fromControls.map((props, index) => (
        <Col key={index} xs="12" md={index === 3 ? "12" : "6"}>
          {React.createElement(props.tag ?? AdminInput, {
            ...props,
            value: data[props.state],
            setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
          })}
        </Col>
      ))}
      <div className="col-12 d-flex flex-center">
        <ButtonGroup>
          {!isNewUser && (
            <Button variant="danger" onClick={deleteUser}>
              حذف
            </Button>
          )}
          <Button type="submit">ثبت</Button>
        </ButtonGroup>
      </div>
    </Form>
  )
}

export default Teacher