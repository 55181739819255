// import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import { Col, Row, Form } from "react-bootstrap";
// import { AdminInput, Select, Table, Pagination, PrintModal, Button, Print } from "../../../../components";
// import { paymentMethods, purchaseStatus } from "../../../../constants";
// import { convertPhone, jsonToXlsx, scrollToTop } from "../../../../methods";
// import { axios, moment } from "../../../../boot";
// import CardPurchase from "../CardPurchase";
// import { toast } from "../../../../methods";
// import { useGetCourse } from "../../../../hooks";
// import NewInstallment from "../newInstallment";

import Normal from "../CourseController/Normal";

// export default function CardPurchasesCourse() {
//   const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
//   const navigate = useNavigate();
//   const [params, setParams] = useState({ page: 1, ...basicParams });
//   const [purchases, setPurchases] = useState([]);
//   const [pages, setPages] = useState({});
//   const [modalInfo, setModalInfo] = useState(null)
//   const [id, setId] = useState([])
//   const [course, getCourses] = useGetCourse()
//   const [installment, setInstallment] = useState(null)

//   // const [reason, setReason] = useState(null)
//   // const [update, setUpdate] = useState(0)


//   const saveParams = () => {
//     const value = JSON.stringify(params);
//     sessionStorage.setItem("params", value);
//   };
//   const getPurchases = () => {
//     saveParams();
//     const url = "/admins/shop/cardpurchases";
//     const config = {
//       params: {
//         sort: "createdAt:-1",
//         perPage: 20,
//         paymentMethod: "Card",
//         status: "paid",
//         service: "course",
//         ...params,
//       },
//     };
//     params.date &&
//       ([config.params.startDate, config.params.endDate] = params.date);
//     delete config.params.date;
//     config.params.phone && (config.params.phone = convertPhone(params.phone))
//     axios.get(url, config).then(({ data }) => {
//       setPurchases(data.data);
//       setPages(data.pages);
//       scrollToTop();
//     });
//   };
//   const downloadXlsx = async () => {
//     const url = "/admins/shop/cardpurchases";
//     const perPage = 100;
//     const totalPages = Math.ceil(pages.totalItems / perPage);
//     const config = (page) => ({ params: { ...params, perPage, page, paymentMethod: "Card", status: "paid", service: "course", } });
//     const getAllData = await [...Array(totalPages)]
//       .map((e, i) => i + 1)
//       .map(
//         async (page) => await (await axios.get(url, config(page))).data?.data
//       );
//     const allData = await Promise.all(getAllData);
//     const title = "report";
//     const json = allData.flat().map((e) => ({
//       "نام ": `${e?.shipping?.firstName ? e?.shipping?.firstName : e.user?.firstName}`,
//       "نام خانوادگی ": `${e?.shipping?.lastName ? e?.shipping?.lastName : e.user?.lastName}`, "کد ملی": `${e?.codeMelli ? e?.codeMelli : (e.user?.codeMelli ? e.user?.codeMelli : "")}`,
//       "شماره موبایل": `${convertPhone(e.shipping?.phone)}`,
//       تاریخ: moment.miladiToShamsi({ date: e.createdAt }),

//       قیمت: `${e.totalPrice} تومان -  ${e.totalPriceUsd} تتر`,
//       // تخفیف: !!e.voucher
//       //   ? `${e.offPrice} تومن -  ${e.offPriceUsd} تتر`
//       //   : "بدون تخفیف",
//       کالاها: e.items
//         ?.map((e) => `${e.course?.title} ${e.count} عدد`)
//         .join(" - "),
//       "نوع پرداخت": paymentMethods.find((mt) => mt.id === e.paymentMethod)
//         ?.name,
//       وضعیت: purchaseStatus.find((it) => it.id === e.status)?.name,
//     }));
//     jsonToXlsx({ json, title });
//   };
//   const formControls = [
//     {
//       label: "تاریخ",
//       state: "date",
//       type: "date",
//       cancelBtn: true,
//       needTime: false
//     },
//     {
//       label: "نام",
//       state: "firstName",
//       type: "text",
//       clear: true,
//     },
//     {
//       label: "نام خانوادگی",
//       state: "lastName",
//       type: "text",
//       clear: true,
//     },
//     // {
//     //   label: "شماره پیگیری",
//     //   state: "refId",
//     //   type: "number",
//     //   clear: true,
//     // },
//     {
//       label: "شماره تلفن",
//       state: "phone",
//       type: "number",
//       clear: true,
//     },
//     {
//       tag: Select,
//       label: "محصول",
//       state: "product",
//       cancelBtn: true,
//       as: AdminInput,
//       items: course?.map(e => ({ name: e.title, id: e._id })),
//     },
//     // {
//     //   tag: Select,
//     //   label: "وضعیت",
//     //   state: "status",
//     //   cancelBtn: true,
//     //   items: purchaseStatus.filter((e) => e.filterable),
//     // },
//   ];
//   // const handleVerify = (status, reason = "") => {
//   //   const url = "/admins/shop/purchases/cardverifyall";
//   //   const body = {
//   //     _id: id.toString(),
//   //     status,
//   //     reason
//   //   };
//   //   axios.post(url, body).then(({ data }) => {
//   //     setUpdate(p => p + 1)
//   //     toast({});
//   //     setId([])
//   //     setReason(null)

//   //   });
//   // }
//   // const handleRej = () => { setReason("") }
//   const handlePrint = () => {
//     const url = "/admins/shop/purchases/cardreportall";
//     const body = { _id: id?.map(e => e._id)?.toString() };
//     axios.post(url, body).then(({ data }) => {
//       const address = data.address
//       const url = `https://api.academymarvel.com${address}`
//       const link = document.createElement('a');
//       link.href = url;
//       link.target = "_blank"
//       link.setAttribute('download', 'file.pdf');
//       document.body.appendChild(link);
//       setTimeout(() => {
//         link.click();
//       }, 3000)
//       // setUpdate(p => p + 1)
//       getPurchases()
//       toast({});
//       setId([])

//     });
//   }
//   // const handleSend = (status) => {
//   //   const url = "/admins/shop/purchases/cardupdate-purchaseall";
//   //   const body = {
//   //     _id: id.toString(),
//   //     status
//   //   };
//   //   axios.post(url, body).then(({ data }) => {
//   //     setUpdate(p => p + 1)
//   //     toast({});

//   //   });
//   // }

//   const handleBtn = [
//     // {
//     //   id: "acc",
//     //   label: "تایید پرداخت ها",
//     //   status: "accepted",
//     //   func: handleVerify,
//     //   variant: "success"
//     // },
//     // {
//     //   id: "rej",
//     //   label: "رد پرداخت ها",
//     //   status: "rejected",
//     //   func: handleRej,
//     //   variant: "danger"
//     // },
//     // {
//     //   id: "print",
//     //   label: "پرینت پرداخت ها",
//     //   status: "",
//     //   func: ()=>{
//     //     handlePrint()
//     //     // window.print()
//     //   },
//     //   vavariantrient: "info"
//     // },
//     // {
//     //   id: "send",
//     //   label: "ارسال شده",
//     //   status: "shipping",
//     //   func: handleSend,
//     //   vavariantrient: "primary"
//     // },
//   ]

//   const showStatus = (id = 0) => {
//     const { color, name } = purchaseStatus.find((e) => e.id === id) ?? {};
//     return <span className={`text-${color} fw-bold`}>{name}</span>;
//   };
//   const handleChecked = (event) => {
//     let checkbox = document.getElementById(event._id).checked;
//     let data = id
//     setId([])
//     if (checkbox) {
//       data.push(event)
//     } else {
//       if (data.length > 0) {
//         const a = data.indexOf(event);
//         data.splice(a, 1)
//       }
//     }
//     setId([...data])
//   }
//   const selectAll = () => {
//     if (id?.length == 0) {
//       setId(purchases)
//     } else {
//       setId([])
//     }
//   }
//   const clickHandle = useCallback((info) => {
//     let a = []
//     a.push(info)
//     setId([...a])
//     setModalInfo(info)
//   }, [id, modalInfo])
//   const closeModal = useCallback(() => {
//     setModalInfo(null)
//     setId([])
//   }, [id, modalInfo])
//   const closeInstallment = () => {
//     setInstallment(null)
//   }
//   const getInstallment = (id) => {
//     const url = "/admins/shop/newinstallments"
//     const body = {
//       firstPurchase: id
//     }
//     axios.post(url, body).then(({ data }) => {
//       setInstallment(data)
//     })
//   }
//   useEffect(getPurchases, [params]);
//   useEffect(getCourses, [])
//   return (
//     <React.Fragment>
//       <PrintModal className="d-print-none" show={modalInfo != null} onHide={() => closeModal()} size="lg">
//         <CardPurchase data={modalInfo} onUpdate={getPurchases} />
//       </PrintModal>
//       <PrintModal show={installment != null} onHide={() => closeInstallment()} size="xl">
//         <NewInstallment data={installment} />
//       </PrintModal>
//       <Print info={id} />
//       {/* <PrintModal show={reason != null} onHide={() => setReason(null)} size="lg">
//         <Form className="d-block d-print-none row"  >
//           <Col xs="12">
//             <AdminInput
//               label="توضیحات"
//               as="textarea"
//               value={reason}
//               setValue={setReason}
//             />
//           </Col>
//           <Col xs="12">
//             <Button onClick={() => handleVerify("rejected", reason)} type="button" className="w-100">
//               ثبت دلیل و رد پرداخت
//             </Button>
//           </Col>
//         </Form>
//       </PrintModal> */}
//       <div className="w-100 d-print-none d-flex justify-content-between align-items-center">
//         <button
//           className="bi bi-filetype-xlsx fs-3 text-success"
//           onClick={downloadXlsx}
//         />
//         <div className="w-100  d-flex justify-content-end">
//           <Link
//             to="new"

//             className="bi bi-plus-lg d-flex flex-center fs-4 text-success"
//           />
//         </div>
//       </div>
//       <div className="my-2 d-print-none">
//         <Link className="btn btn-success py-1 px-4 rounded-4 text-white" to="archive">بایگانی</Link>
//       </div>
//       <Row className="d-print-none">
//         {formControls.map((e) => (
//           <Col key={e.state} xs="12" md="6" lg="4">
//             {React.createElement(e.tag ?? AdminInput, {
//               ...e,
//               value: params[e.state],
//               setValue: (val) => {
//                 setPurchases([]);
//                 setParams((p) => ({ ...p, page: 1, [e.state]: val }));
//               },
//             })}
//           </Col>
//         ))}
//       </Row>
//       <Row className="d-print-none my-4">
//         {handleBtn.map((e) => (
//           <Col key={e.id} xs="12" md="6" lg="3">
//             <Button className="w-100" disabled={id.length < 1} onClick={() => e.func(e.status)} variant={e.variant}>{e.label}</Button>
//           </Col>
//         ))}

//         <Col className="d-flex justify-content-end align-items-center" xs="12" md="12" lg="12">
//           <Button variant="info" onClick={selectAll}>{id?.length == 0 ? "انتخاب کل" : "حذف کل"}</Button>
//         </Col>
//       </Row>

//       <Table className="d-print-none">
//         <thead>
//           <tr>
//             <th>شماره پیگیری</th>
//             <th>شماره ارجاع</th>
//             <th>نام خریدار</th>
//             <th>شماره تلفن</th>
//             <th>تاریخ</th>
//             <th>مبلغ نهایی</th>
//             <th>نام دوره</th>
//             {/* <th>تخفیف</th> */}
//             <th>اقساط</th>
//             <th>وضعیت</th>
//             <th></th>
//           </tr>
//         </thead>
//         <tbody>
//           {purchases?.map((e, i) => (
//             <tr onClick={() => clickHandle(e)} key={e._id + i}  >
//               <td onClick={(i) => i.stopPropagation()}>{e?.refId ? e.refId : ""}</td>
//               <td onClick={(i) => i.stopPropagation()}>{e?.authority ? e.authority : ""}</td>
//               <td>
//                 {e.shipping?.firstName ? e.shipping?.firstName : e.user?.firstName} {e.shipping?.lastName ? e.shipping?.lastName : e.user?.lastName}
//               </td>
//               <td onClick={(i) => i.stopPropagation()}>{convertPhone(e.shipping?.phone)}</td>
//               <td>
//                 <span dir="ltr">
//                   {moment.miladiToShamsi({
//                     date: e.createdAt,
//                     format: "jYYYY/jMM/jDD - HH:mm:ss",
//                   })}
//                 </span>
//               </td>
//               <td className="text-success">{`${e.totalPrice?.toLocaleString()} تومان -  ${e.totalPriceUsd} تتر`}</td>
//               <td><p style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" , overflow : "hidden" , width : "200px" }}>{e?.items?.map((i) => i.course?.title).join(" - ")}</p></td>            {/* <td className="text-danger">
//               {!!e.voucher
//                 ? `${e.offPrice} تومن -  ${e.offPriceUsd} تتر`
//                 : "بدون تخفیف"}
//             </td> */}
//               <td className="" onClick={(i) => i.stopPropagation()}><button onClick={() => { setInstallment({}); getInstallment(e?._id) }} className="btn btn-outline-primary btn-sm">نمایش اقساط</button></td>
//               <td>{showStatus(e.status)}</td>
//               <td onClick={(i) => i.stopPropagation()}>
//                 <Form>
//                   <Form.Check
//                     onClick={(i) => i.stopPropagation()}
//                     type="checkbox"
//                     value={e._id}
//                     id={e._id}
//                     checked={id.find(p => p._id == e._id) ? true : false}
//                     onChange={() => { handleChecked(e) }}
//                   />
//                 </Form>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//       <Pagination
//         totalPages={pages.totalPages}
//         activePage={params.page}
//         setActivePage={(page) => setParams((p) => ({ ...p, page }))}
//       />
//       {/* {params.page < pages.totalPages && (
//         <button
//           className="d-block mx-auto w-fit text-primary"
//           onClick={() => {
//             setParams((p) => ({ ...p, page: p.page + 1 }));
//           }}
//         >
//           نمایش بیشتر...
//         </button>
//       )} */}
//     </React.Fragment>
//   );
// }
export default function CardPurchasesCourse(){
  return <Normal status="paid" paymentMethod="Card" />
}