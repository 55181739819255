import React, { useEffect, useState } from 'react'
import { Modal } from '../../../components'
import AddAddress from './AddAddress'
import AddressCard from '../../../components/AddressCard'
import {axios} from '../../../boot'
const MyAddress = () => {
    const [ show , setShow ] = useState(null)
    const [ data , setData ] = useState([])
    const getAddress = () => {
        const url = "/address"
        axios.get(url).then(({data})=>{
            setData(data?.data)
        })
    }
    useEffect(getAddress,[])
    return (
        <div className="MYADDRESS bg-raisin py-4 px-2 shadow-sm">
            <Modal bg="black" size='lg' title={typeof show == "boolean" ? "اضافه کردن آدرس جدید" : "ویرایش آدرس"} show={show} onHide={()=>setShow(null)} >
                <AddAddress getAddress={getAddress} setShow={setShow} info={show}/>
            </Modal>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center px-2 my-2 ">
                <div className="d-flex align-items-center my-1">
                    <p className="fs-14 fw-400 text-white mx-1">آدرس های من</p>
                </div>
                <button onClick={()=>setShow(true)} className=" btn bg-transparent text-primary fs-14 my-1"><i className="bi bi-plus mx-1"></i>ثبت آدرس جدید</button>
            </div>
            {data?.map((e,index)=>
                <AddressCard getAddress={getAddress} key={index} data={e} setShow={()=>setShow(e)} />
            )}
        </div>
    )
}

export default MyAddress