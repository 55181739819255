import { useState, useEffect } from 'react'

import { Container, Row, Col } from 'react-bootstrap'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { axios, moment } from '../../../boot'
import { pass } from '../../../constants'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'نمودار آمارها',
        },
    },
};



const Dashboard = () => {
    const [dataa, setData] = useState({})
    const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
    const [params, setParams] = useState({...basicParams });


    const saveParams = () => {
        const value = JSON.stringify(params);
        sessionStorage.setItem("params", value);
    };
    const getData = (enter) => {
        saveParams();
        const url = "https://marveltradeconsult.com/api/payments/getPaymentsDailyCount";
        const config = {
            params: {
                userName: enter,
                pass,
            },
        };
        axios.get(url, config).then(({ data }) => {
            setData(data);
        });
    }

    const countData = [
        {
            id: "all",
            title: "تعداد کل مشترکین",
            value: dataa?.count?.all,
            icon: "people"
        },
        {
            id: "crypto",
            title: "تعداد کریپتو ",
            value: dataa?.count?.crypto,
            icon: "currency-bitcoin"
        },
        {
            id: "forex",
            title: "تعداد فارکس ",
            value: dataa?.count?.forex,
            icon: "currency-exchange"
        },
        {
            id: "cryptoForex",
            title: "تعداد کریپتوفارکس ",
            value: dataa?.count?.cryptoForex,
            icon: "people"
        },
    ]
    // const a = dataa?.data?.map((item) => `${moment.miladiToShamsi({date : item._id})}`);
    // console.log(a)
    

    const data = {
        labels : dataa?.data?.map((item) => `${moment.miladiToShamsi({date : item._id})}`)?.reverse(),
        datasets: [
            {
              label: 'تعداد',
              data: dataa?.data?.map((item) => item.Count)?.reverse(),
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            
          ],
    };

    useEffect(() => {
        const getPhone = JSON.parse(localStorage.getItem('phone'))
        getData(getPhone)
    }, []);

    return (
        <Container>
            <Row className="my-2">
                {countData.map((item) =>
                    <Col key={item.id} className="bg-white rounded shadow p-2 m-1 d-flex flex-column align-items-center justify-content-center" xs="12" md="6" lg="2">
                        <span style={{ width: "25px", height: "25px" }} className="rounded-circle bg-primary flex-center"><i className={`bi bi-${item.icon} text-white`}></i></span>
                        <p className="fw-700 fs-20 my-2">{item.value}</p>
                        <p className="fs-10">{item.title}</p>
                    </Col>
                )}

            </Row>
            <Row>
                <Col className="" xs="12" lg="12">
                    <Bar options={options} data={data} />
                </Col>
            </Row>
        </Container>
    )
}

export default Dashboard