import { Navigate } from "react-router";
import Courses from "../pages/AdminPanel/Courses";
import Course from "../pages/AdminPanel/Course";
import Sessions from "../pages/AdminPanel/Sessions";
import Session from "../pages/AdminPanel/Session";
import Users from "../pages/AdminPanel/Users";
import User from "../pages/AdminPanel/User";
import Exams from "../pages/AdminPanel/Exams";
import Exam from "../pages/AdminPanel/Exam";
import Intervals from "../pages/AdminPanel/Intervals";
import Interval from "../pages/AdminPanel/Interval";
import Blogs from "../pages/AdminPanel/Blogs";
import Blog from "../pages/AdminPanel/Blog";
import FreeTutorials from "../pages/AdminPanel/FreeTutorials";
import FreeTutorial from "../pages/AdminPanel/FreeTutorial";
import Categories from "../pages/AdminPanel/Categories";
import Category from "../pages/AdminPanel/Category";
import Notifications from "../pages/AdminPanel/Notifications";
import Notification from "../pages/AdminPanel/Notification";
import Products from "../pages/AdminPanel/Products";
import Product from "../pages/AdminPanel/Product";
import SenderInfo from "../pages/AdminPanel/SenderInfo";
import Wallet from "../pages/AdminPanel/Wallet";
import Vouchers from "../pages/AdminPanel/Vouchers";
import Voucher from "../pages/AdminPanel/Voucher";
import Purchases from "../pages/AdminPanel/Book/Purchases";
import Purchase from "../pages/AdminPanel/Book/Purchase";
import ShippingInfo from "../pages/AdminPanel/ShippingInfo";
import RefillProduct from "../pages/AdminPanel/RefillProduct";
import CardPurchases from "../pages/AdminPanel/Book/CardPurchases";
import Tethers from "../pages/AdminPanel/Book/Tethers";
import Teachers from "../pages/AdminPanel/Teachers";
import Teacher from "../pages/AdminPanel/Teacher";
import Sliders from "../pages/AdminPanel/Sliders";
import Slider from "../pages/AdminPanel/Slider";
import Medias from "../pages/AdminPanel/Medias";
import Media from "../pages/AdminPanel/Media";
import AddCardPurchase from '../pages/AdminPanel/AddCardPurchase'
import SendSms from '../pages/AdminPanel/SendSms'
import ZarinArchive from "../pages/AdminPanel/Book/Purchases/ZarinArchive";
import TetherArchive from "../pages/AdminPanel/Book/Tethers/TetherArchive";
import CardArchive from "../pages/AdminPanel/Book/CardPurchases/CardArchive";
import TetherArchiveCourse from '../pages/AdminPanel/CourseManage/Tethers/TetherArchive'
import TethersCourse from "../pages/AdminPanel/CourseManage/Tethers";
import PurchasesCourse from "../pages/AdminPanel/CourseManage/Purchases";
import ZarinArchiveCourse from "../pages/AdminPanel/CourseManage/Purchases/ZarinArchive";
import CardArchiveCourse from "../pages/AdminPanel/CourseManage/CardPurchases/CardArchive";
import CardPurchasesCourse from "../pages/AdminPanel/CourseManage/CardPurchases";
import Groups from "../pages/AdminPanel/Groups";
import Group from "../pages/AdminPanel/Group";
import GroupMessage from "../pages/AdminPanel/GroupMessage";
// import InstallmentCourse from "../pages/AdminPanel/CourseManage/Installments";
// import InstallmentArchive from "../pages/AdminPanel/CourseManage/Installments/InstallmentArchive";
import Advices from "../pages/AdminPanel/Advices";
import Advice from "../pages/AdminPanel/Advice";
import Calendars from "../pages/AdminPanel/Calendars";
import Calendar from "../pages/AdminPanel/Calendar";
import AstrologyCharts from "../pages/AdminPanel/AstrologyCharts";
import AstrologyChart from "../pages/AdminPanel/AstrologyChart";
const adminRoutes = [
  { path: "", element: <Navigate to="purchases" replace /> },
  { path: "courses", element: <Courses /> },
  { path: "courses/:courseId", element: <Course /> },
  { path: "sessions", element: <Sessions /> },
  { path: "sessions/:sessionId", element: <Session /> },
  { path: "exams", element: <Exams /> },
  { path: "exams/:examId", element: <Exam /> },
  { path: "intervals", element: <Intervals /> },
  { path: "intervals/:intervalId", element: <Interval /> },
  { path: "blogs", element: <Blogs /> },
  { path: "categories", element: <Categories /> },
  { path: "categories/:id", element: <Category /> },
  { path: "free-tutorials", element: <FreeTutorials /> },
  { path: "free-tutorials/:id", element: <FreeTutorial /> },
  { path: "blogs/:blogId", element: <Blog /> },
  { path: "users", element: <Users /> },
  { path: "users/:userId", element: <User /> },
  { path: "notifications", element: <Notifications /> },
  { path: "notifications/:id", element: <Notification /> },
  { path: "products", element: <Products /> },
  { path: "products/:id", element: <Product /> },
  { path: "products/:id/refill", element: <RefillProduct /> },
  { path: "sender-info", element: <SenderInfo /> },
  { path: "wallet", element: <Wallet /> },
  { path: "vouchers", element: <Vouchers /> },
  { path: "vouchers/:id", element: <Voucher /> },
  { path: "purchases", element: <Purchases /> },
  { path: "purchases/:id", element: <ZarinArchive /> },
  { path: "tethers", element: <Tethers /> },
  { path: "tethers/:id", element: <TetherArchive /> },
  { path: "tethers", element: <Tethers /> },
  { path: "shipping-info", element: <ShippingInfo /> },
  { path: "card-purchases", element: <CardPurchases /> },
  { path: "card-purchases/:id", element: <CardArchive /> },
  { path: "card-purchases/new", element: <AddCardPurchase /> },
  { path: "purchases", element: <Purchases /> },
  { path: "purchases/:id", element: <ZarinArchive /> },
  { path: "tethers", element: <Tethers /> },
  { path: "tethers/:id", element: <TetherArchive /> },
  { path : "send-sms" , element : <SendSms />},
  { path: "teachers", element: <Teachers /> },
  { path: "teachers/:teacherId", element: <Teacher /> },
  { path: "sliders", element: <Sliders /> },
  { path: "sliders/:id", element: <Slider /> },
  { path: "medias", element: <Medias /> },
  { path: "medias/:id", element: <Media /> },
  { path: "card-purchases-course", element: <CardPurchasesCourse /> },
  { path: "card-purchases-course/:id", element: <CardArchiveCourse /> },
  { path: "purchases-course", element: <PurchasesCourse /> },
  { path: "purchases-course/:id", element: <ZarinArchiveCourse /> },
  { path: "tethers-course", element: <TethersCourse /> },
  { path: "tethers-course/:id", element: <TetherArchiveCourse /> },
  { path: "groups", element: <Groups /> },
  { path: "groups/:id", element: <Group /> },
  { path: "groups-message", element: <GroupMessage /> },
  // { path: "installment", element: <InstallmentCourse /> },
  // { path: "installment/:id", element: <InstallmentArchive /> },
  { path: "advice", element: <Advices /> },
  { path: "advice/:id", element: <Advice /> },
  { path: "calendars", element: <Calendars /> },
  { path: "calendars/:id", element: <Calendar /> },
  { path: "astrologyCharts", element: <AstrologyCharts /> },
  { path: "astrologyCharts/:id", element: <AstrologyChart /> },
];
export default adminRoutes;
