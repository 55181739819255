import React , { useState } from 'react'
import { toast } from '../../../methods'
import { axios , moment } from '../../../boot'
import { Col } from 'react-bootstrap'
import { AdminInput, Button, Form, Select } from '../../../components'
const DelaySms = ({ groups = [] }) => {
    const [data, setData] = useState({})
    const formControls = [
        {
            label: "گروه",
            state: "_id",
            tag: Select,
            as: AdminInput,
            items: groups?.map((e) => ({ name: e.name, id: e._id }))

        },
        {
            state: "link",
            label: "لینک کلاس"
        },
        {
            state: "date",
            label: "تاریخ",
            type: "date"
        },
        {
            state: "time",
            label: "زمان کلاس"
        },
    ]
    const delaySms = () => {
        const url = `/admins/groupClassSmsTakhir`
        const body = { ...data, ["date"]: moment.miladiToShamsi({ date: data?.date ? data?.date[0] : "" }) }
        axios.post(url, body).then(({ data }) => {
            const text = "پیامک با موفقیت ارسال شد"
            toast({ text })
            setData({})
        })
    }
    return (
        <>
            <Form className="row" onSubmit={delaySms}>
                <h5 className='text-center'>ارسال پیامک تاخیر</h5>
                {formControls.map((e) => (
                    <Col key={e.state} xs="12" md={"6"} lg={"6"}>
                        {React.createElement(e.tag ?? AdminInput, {
                            ...e,
                            value: data[e.state],
                            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                        })}
                    </Col>
                ))}
                <Col xs="12" className="d-flex flex-center">
                    <Button type="submit">ارسال پیامک تاخیر</Button>
                </Col>
            </Form>
            <div className="w-100 d-flex flex-center my-4">
                <div className="shadow rounded p-4">
                    <p>سلام کاربر عزیز</p>
                    <p>پیرو ثبت نام شما در کلاس آموزشی متاسفانه  کلاس امروز با تاخیر برگزار میشود</p>
                    <p>کلاس شما در تاریخ {moment.miladiToShamsi({ date: data?.data ? data?.date[0] : null})} ساعت {data?.time} <br /> برگزار میشود.</p>
                    <p>لینک ورودی اسکای روم : {data?.link}</p>
                    <p>نام کاربری و رمز عبور شما : شماره موبایل بدون صفر</p>
                </div>
            </div>
        </>
    )
}
export default DelaySms