import React from 'react'

import './index.scss'
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'

const NewTopicCard = ({
  image = "",
  title = "",
  courseTitle = "",
  lessons = "",
  _id = "",
  className = "",
  showTitle = true
}) => {
  return (
    <Link className="mx-2 " to={`/tutorials/${_id}`}>
      <div className={`NewTopicCard rounded-4 shadow ${className} `}>
        <img referrerPolicy="no-referrer" src={image} className='NewTopicCard-img ' />
        <div className="position-absolute top-0 left-0 m-2">
          <Badge pill bg="white" text="black" className="py-1" style={{ direction: "rtl" }}>{title}</Badge>
        </div>
        {showTitle &&
          <div style={{ zIndex: "5" }} className="position-absolute bottom-0 left-0 w-100 d-flex justify-content-between align-items-center">
            <p className="fw-700 fs-12 text-white m-2">{courseTitle}</p>
            <p className="fw-400 fs-12 text-white m-2">{lessons} جلسه</p>
          </div>
        }

      </div>
    </Link>
  )
}

export default NewTopicCard