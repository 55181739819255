import React, { useState, useEffect  } from 'react'
import './index.scss'
import { axios } from '../../boot'
import { Link } from 'react-router-dom'
const SpecialClass = ({tutorials = [] , products = []}) => {
    const [ data , setData ] = useState({})
    // const [tutorial, setTutorial] = useState([])
    // const getTutorial = () => {
    //     const url = "/pub/shop/courses"
    //     axios.get(url).then(({ data }) => {
    //         // console.log("data in special class : ",data)
    //         const res = data?.data?.filter(e => e.special != false)
    //         if(res?.length == 0) {
    //             return 
    //         }
    //         setTutorial(res[0])
    //         console.log(res)
    //     })
    // }
    // const getProducts = () => {

    // }
    // useEffect(getTutorial, [])
    const getData = () => {  
        let res = tutorials?.filter(e => e.special != false)?.map(item => ({title : item?.title, title_fa : item?.category?.title_fa , _id : item?._id , address : `/tutorials/${item?._id}`,fullScreenCover : item?.fullScreenCover , special : "دوره ویژه"}))
        if(res?.length == 0) {
            res = products?.filter(e => e.special != false)?.map(item => ({title : item?.title_fa , title_fa : "فروشگاه" , _id : item?._id , address : `/products/${item?._id}`,fullScreenCover : item?.fullScreenCover , special : "پک ویژه"}))
        }
        setData(res[0])
    }
    useEffect(getData , [tutorials , products])
    return (
        <div style={{ backgroundImage: `url(${data?.fullScreenCover})` }} className="SPECIAL-CLASS d-flex align-items-end align-items-lg-center justify-content-center justify-content-lg-end p-2 my-4">
            <div className="special-body d-flex flex-column align-items-center">
                <div className="special-category bg-white mx-auto p-1 flex-center">
                    <p className="text-black font-fa fs-10 fw-700">{data?.title_fa}</p>
                </div>
                <p className="fs-30 fw-700 text-white text-center my-4">{data?.special}</p>
                <p className="my-2 text-white">{data?.title}</p>
                <span style={{ width: "27px" }} className='border border-white border-2 my-4'></span>
                <Link style={{width : "200px"}} className="btn btn-white px-4 fs-12 fw-500 " to={data?.address ? data?.address : "/"}>مشاهده و خرید</Link>
            </div>
        </div>
    )
}

export default SpecialClass