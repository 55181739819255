import ZarinPal from "../pages/Accountant/Academy/Zarinpals";
import { Navigate } from "react-router";
import Tether from "../pages/Accountant/Academy/Tethers";
import CardPurchases from "../pages/Accountant/Academy/CardPurchases";
import TethersVps from "../pages/Accountant/MainMarvel/TethersVps";
import ZarinPalVps from "../pages/Accountant/MainMarvel/ZarinpalVps";
import TethersPardakht from "../pages/Accountant/Pardakht/TethersPardakht";
import ZarinPalPardakht from "../pages/Accountant/Pardakht/ZarinpalPardakht";
import Dashboard from "../pages/Accountant/Dashboard";


const accountantRoutes = [
  { path: "", element: <Navigate to="zarinpal" replace /> },
  {
    path: "zarinpal",
    element: <ZarinPal />,
  },
  {
    path: "tether",
    element: <Tether />,
  },
  {
    path: "card-purchases",
    element: <CardPurchases />,
  },
  {
    path: "tether-vps",
    element: <TethersVps />,
  },
  {
    path: "zarinpal-vps",
    element: <ZarinPalVps />,
  },
  {
    path: "tether-Pardakht",
    element: <TethersPardakht />,
  },
  {
    path: "zarinpal-Pardakht",
    element: <ZarinPalPardakht />,
  },
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  
];
export default accountantRoutes;
