import { Link } from "react-router-dom";
import { offCal, showActiveImg, source } from "../../methods";
import Badge from "../Badge";
import "./index.scss";
import CommingSoon from "../Ribbon";
export default function ProductCard({
  _id = "",
  isAdmin = false,
  available = true,
  title_fa = "",
  count = 0,
  img = [],
  category = [],
  price = 0,
  priceUsd = 0,
  off = 0,
  offUsd = 0,
}) {
  // const [activeImgIndex, setActiveImgIndex] = useState(0);
  // const isActiveImg = (index = 0) => {
  //   const isActive = activeImgIndex === index;
  //   return isActive;
  // };
  const activeImg = showActiveImg(img);
  const showPrice = () => {
    const prices = [
      {
        price: priceUsd,
        off: offUsd,
        curr: "USD",
      },
      {
        price,
        off,
        curr: "تومان",
      },
    ];
    return prices.map((e) => (
      <span key={e.curr} className={`fw-bold ${(e.curr == "USD" && e.price <= 0) ? "opacity-0" : ""}`}>
        {e.off !== 0 && (
          <sub className="text-decoration-line-through text-secondary">
            {e.price.toLocaleString()}
          </sub>
        )}
        {offCal(e.price, e.off).toLocaleString()}{" "}
        <sub className="fw-normal">{e.curr}</sub>
      </span>
    ));
  };
  return (
    <Link
      to={_id}
      className="ProductCard d-block w-100 rounded transition"
    >
      <div className="img-box w-100 border-bottom border-dark-gray position-relative">
        {!available && <CommingSoon label="به زودی" /> }
        
        {/* {img.map((e, i) => (
          <img
            key={i}
            src={source(e)}
            alt="defaultUrl"
            className={`w-100 h-100 object-fit-contain ${
              isActiveImg(i) && "active"
            }`}
          />
        ))} */}
        {activeImg && (
          <img
            src={activeImg}
            alt={activeImg}
            className="object-fit-cover"
            referrerPolicy="no-referrer"
          />
        )}
      </div>
      <div className="p-3 ProductCard-body">
        <h6 className="text-white fs-14 fw-500">{title_fa}</h6>
        {/* <h6 className="text-white fs-12 fw-400 text-gray-white">{title_fa}</h6> */}
        {/* <div className="land-books py-1 px-2 fs-12 fw-500 my-2 d-flex align-items-center justify-content-center">{count}</div> */}
        {/* <h6 className="text-secondary mt-1 text-truncate">تعداد: {count}</h6> */}
        {/* <div className="categories mt-2 w-100 text-truncate">
          {category.map((e) => (
            <Badge key={e._id} label={e.title_fa} />
          ))}
        </div> */}
        <div  className="d-flex align-items-center justify-content-between mt-4 text-white">
          {showPrice()}
        </div>
      </div>
    </Link>
  );
}
