import { useState, useEffect } from 'react'

import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { axios } from "../../../boot";
import { SearchBox, TeacherCard } from "../../../components";
import { toast } from "../../../methods";

const Teachers = () => {
    const [ teacher, setTeacher ] = useState([]);
    // const [params, setParams] = useState({});
    const getUsers = () => {
        const url = "/admins/pub/shop/teachers";
        axios.get(url).then(({ data }) => {
            console.log(data)
            setTeacher(data.data);
        });
    };
    // const handleSetParams = (search = "") => {
    //     if (search.length === 0) return setParams({});
    //     if (search.length <= 3) {
    //         const text = "جستجو باید از 3 حرف بزرگتر باشد.";
    //         toast({ text, type: "error" });
    //         return;
    //     }
    //     setParams({ search });
    // };
    useEffect(getUsers, []);
    return (
        <Row className="align-items-stretch">
            <div className="col-12 d-flex justify-content-end">
                <Link
                    to="new"
                    className="bi bi-plus-lg d-flex flex-center fs-4 text-success"
                />
            </div>
            {/* <div className="d-flex flex-center col-12">
                <Col xs="12" md="6" lg="4">
                    <SearchBox onSubmit={handleSetParams} />
                </Col>
            </div> */}
            {teacher.map((user) => (
                <Col key={user._id} xs="12" md="6" lg="4" xl="3">
                    <TeacherCard {...user} />
                </Col>
            ))}
        </Row>
    )
}

export default Teachers