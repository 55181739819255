import React ,  {useState , useEffect} from 'react'

import { useNavigate, useParams } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import { Col, ButtonGroup } from "react-bootstrap";
import { rules, roles , accept } from "../../../constants";
import { fileToBase64, toast } from "../../../methods";
import { Form, AdminInput, Select, Button } from "../../../components";
import { axios, moment } from "../../../boot";

const Advice = () => {
    const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});

  const isNewUser = params.id === "new";
  const fromControls = [
    {
      label: "زمان مشاوره",
      state: "time",
      needTime : false,
      type : "date",
      clear : true,
      needTime : true
    },
  ];
  const getData = () => {
    if (!isNewUser) {
      const url = `/admins/class/${params.id}`;
      axios.get(url).then(({ data }) => {
        // const body = cloneDeep(data);
        // body.phone = convertPhone(data.phone);
        console.log(data)
        // let body = {}
        // body.time = moment.miladiToShamsi({date : data.time})
        // console.log(body)
        setData(data);
      });
    }
  };
  const files = ["video", "faSubtitle", "thumbnail" , "image" , "file"];
  const addAdvice = async () => {
    const url = "/admins/class";
    axios.post(url, data).then(({data}) => {
      toast({});
      setData({})
    });
  };
  const changeAdvice = () => {
    const url = "/admins/class";
    
    axios.put(url, data).then(() => {
      toast({});
    });
  };
  const deleteAdvice = () => {
    const message = "آیا از درخواست خود اطمیمان دارید؟";
    if (window.confirm(message)) {
      const url = "/admins/class";
      const body = { _id: params.id };
      axios.delete(url, { data: body }).then(() => {
        toast({});
        navigate("/admin/advice", { replace: true });
      });
    }
  };
  useEffect(getData, []);
  return (
    <Form onSubmit={isNewUser ? addAdvice : changeAdvice} className="row">
      <h5 className="text-center col-12">
        {isNewUser ? "اضافه کردن زمان مشاوره جدید" : "اطلاعات زمان مشاوره"}
      </h5>
      {fromControls.map((props, index) => (
        <Col key={index} xs="12" md={index === 3 ? "12" : "6"}>
          {React.createElement(props.tag ?? AdminInput, {
            ...props,
            value: data[props.state],
            setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
          })}
        </Col>
      ))}
      <div className="col-12 d-flex flex-center">
        <ButtonGroup>
          {!isNewUser && (
            <Button variant="danger" onClick={()=>deleteAdvice()}>
              حذف
            </Button>
          )}
          <Button type="submit">ثبت</Button>
        </ButtonGroup>
      </div>
    </Form>
  )
}

export default Advice