import { Col, Row } from "react-bootstrap";
import { paymentMethods, purchaseStatus } from "../../../../constants";
import { convertPhone } from "../../../../methods";

export default function ShowInfo({ data = {} , purchaseType = "" }) {
  const showPaymentMethod = (id = 0) => {
    return paymentMethods.find((e) => e.id === id)?.name;
  };
  const showPurchaseStatus = (id = "") => {
    const { name, color } = purchaseStatus.find((e) => e.id === id) || {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };
  const formControls = [
    {
      label: "نام و نام خانوادگی گیرنده",
      value: `${data?.user[0]?.firstName} ${data?.user[0]?.lastName}`,
    },
    {
      label: "نام کشور سرور ",
      value: data?.content?.map((e)=> e.location).join(" - "),
    },
    {
      label: "شماره تماس گیرنده",
      value: convertPhone(data?.user[0]?.phone),
    },
    {
      label: "نام محصول(ها)",
      value: data?.content?.map((e) => e.name).join(" - "),
    },
    {
      label: "تعداد",
      value: data?.totalCount,
    },
    {
      label: "درصد تخفیف",
      color: "danger",
      value: !!data?.content[0]?.off
        ? `${data?.content[0]?.off} %`
        : "بدون تخفیف",
    },
    {
      label: "قیمت نهایی",
      color: "primary",
      value: data?.content?.map((item) => `${item.totalPrice} تومان -  ${item.totalPriceUsd} تتر`),
    },
    {
      label: "نوع پرداخت",
      value: showPaymentMethod(purchaseType),
    },
    {
      label: "وضعیت",
      value: showPurchaseStatus(data?.status),
    },

  ];
  return (
    <Row>
      {formControls.map((e) => (
        <Col key={e.label} xs="12" md="6" className={`text-${e.color}`}>
          <span className="text-secondary">{e.label}:</span> {e.value}
        </Col>
      ))}

      {data?.spotLisence?.length > 0 &&
        <Col  xs="12" md="12" className={`d-print-none`}>
          <span className="text-primary">لایسنس:</span> <br/> <span style={{wordBreak : "break-all"}}>{data?.spotLisence[0]}</span>
        </Col>
      }

    </Row>
  )


}
