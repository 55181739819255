import { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { axios } from "../../../boot";
import { source } from "../../../methods";

export default function Sliders() {
    const [slider, setSlider] = useState([]);
    const getVideos = () => {
        const url = `/admins/pub/sliders`;
        axios.get(url).then(({ data }) => {
            console.log("slider : ", data)
            setSlider(data.data);
        });
    };
    useEffect(getVideos, []);
    return (
        <Row>
            <div className="col-12 d-flex justify-content-end">
                <Link
                    to="new"
                    className="bi bi-plus-lg d-flex flex-center fs-4 text-success"
                />
            </div>
            {slider.map((e) => (
                <Col key={e._id} xs="12" md="6" lg="4">
                    <Card>
                        <Card.Img
                            variant="top"
                            src={e.image}
                            height="175"
                            className="object-fit-cover"
                        />
                        <Card.Body>
                            <Card.Title as="h5" className="text-truncate">
                                {e?.course?.title}
                            </Card.Title>
                            <Card.Title as="h6" className="text-truncate">
                                {e.title}
                            </Card.Title>
                            <Link to={e._id} className="btn btn-sm btn-primary w-100">
                                مشاهده جزئیات
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}
