import React , { useState , useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import AdminInput from '../../../components/AdminInput'
import { Table } from '../../../components';
import { axios, moment } from '../../../boot'
import { useNavigate } from 'react-router';
import { astrologyStatus } from '../../../constants';
const AstrologyCharts = () => {
    const navigate = useNavigate()
    const [ data , setData ] = useState({})
    const [ user , setUser ] = useState([])
    const findUser = () => {
        const url = "https://api.academymarvel.com/api/class/getAdminChart"
        axios.post(url , data).then(({data})=>{
            setUser(data)
        })
    }
    const formControls = [
        {
            label : "شماره همراه کاربر",
            state : "phone",
            type : "tel",
            append : <button className='btn btn-primary' onClick={()=>findUser()}><i className="bi bi-search"></i></button>
        }
    ]
    const showStatus = (id = 0) => {
        const { color, name } = astrologyStatus.find((e) => e.id === id) ?? {};
        return <span className={`text-${color} fw-bold`}>{name}</span>;
    };
    return (
        <React.Fragment>
            <Row className="d-print-none">
                {formControls.map((e) => (
                    <Col key={e.state} xs="12" md="6" lg="4">
                        {React.createElement(e.tag ?? AdminInput, {
                            ...e,
                            value: data[e.state],
                            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                        })}
                    </Col>
                ))}
            </Row>
            <Table className="d-print-none">
                <thead>
                    <tr>
                        <th>نام خریدار</th>
                        <th>شماره تلفن</th>
                        <th>ایمیل</th>
                        <th>تاریخ سفارش</th>
                        <th>آیدی تلگرام</th>
                        <th>وضعیت</th>
                    </tr>
                </thead>
                <tbody>
                    {user?.map((e)=>
                        <tr key={e._id} onClick={()=>navigate(e._id)}>
                            <td>{e.firstName} {e.lastName}</td>
                            <td>{e.phone}</td>
                            <td>{e.email}</td>
                            <td>{moment.miladiToShamsi({date : e.createdAt})}</td>
                            <td>{e.telegramid}</td>
                            <td>{showStatus(e.status)}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {/* <Pagination

        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      /> */}
        </React.Fragment>
    )
}

export default AstrologyCharts