import React, { useEffect, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Button, Form, Input, Modal } from "../../../../components";
import { rules, purchaseStatus , paymentMethods } from "../../../../constants";
import MailingLabel from "./MailingLabel";
import PayInfo from "./PayInfo";
import ShowInfo from "./ShowInfo";
import { convertPhone, jsonToXlsx, toast } from "../../../../methods";
import { axios, moment } from "../../../../boot";


export default function Purchase({ data = {}, onUpdate = () => {} }) {
  // const [info, setInfo] = useState(data);
  // const [address, setAddress] = useState("")
  // const getInfo = () => {
  //   const url = `/admins/shop/purchases/${params.id}`;
  //   axios.get(url).then(({ data }) => {
  //     setInfo(data);
  //   });
  // };
  // const updateStatus = (status = "") => {
  //   data = { ...data, status };
  // };
  // const verifyPurchase = (status = "") => {
  //   const url = "/admins/shop/purchases/verify";
  //   const body = {
  //     _id: data._id,
  //     status,
  //     reason,
  //   };
  //   axios.post(url, body).then(() => {
  //     toast({});
  //     updateStatus(status);
  //     setShowModal(false);
  //     setReason("")
  //     onUpdate(p => p+1)
  //   });
  // };
  // const updatePurchase = (status = "") => {
  //   const url = "/admins/shop/purchases/update-purchase";
  //   const body = { _id: data._id, status };
  //   axios.post(url, body).then(() => {
  //     toast({});
  //     updateStatus(status);
  //     onUpdate(p => p+1)
  //   });
  // };

  const showChangeStatus = !purchaseStatus
    .filter((e) => e.isUpdateStatus)
    .map((e) => e.id)
    .includes(data?.status);


  const downloadXlsx = async () => {
    const title = "report";
    const allData = [...Array(data)]
    const json = allData.flat().map((e , index) => ( {
      "ردیف" : `1` ,
      تاریخ: moment.miladiToShamsi({ date: data?.createdAt }),
      خدمات: data?.items && data?.items
      ?.map((e) =>  e?.product ? `${e.product?.title_fa} ${e.count} عدد` : `${e.course?.title} ${e.count} عدد`)
      .join(" - "),
      "نوع مشترک" : "حقیقی",
      "نام کاربر": `${e?.shipping?.firstName ? e?.shipping?.firstName : e.user?.firstName} ${e?.shipping?.lastName ? e?.shipping?.lastName : e.user?.lastName}`,
      "کد ملی"  : `${data?.codeMelli ? data?.codeMelli : (data.user?.codeMelli ? data.user?.codeMelli : "" ) }`,
      آدرس: `${data.shipping?.address ? data.shipping?.address : "" }`,
      کدپستی: `${data.shipping?.postCode ? data.shipping?.postCode : "" }`,
      "شماره موبایل": `${convertPhone(data?.shipping?.phone)}`,
      "نوع پرداخت": paymentMethods.find((mt) => mt.id === data?.paymentMethod)
        ?.name,
        تخفیف: !!e.voucher
        ? `${e.offPrice} تومن -  ${e.offPriceUsd} تتر`
        : "بدون تخفیف",      قیمت: `${data?.totalPrice} تومان -  ${data?.totalPriceUsd} تتر`,
      وضعیت: purchaseStatus.find((it) => it.id === data?.status)?.name,
    }));
    jsonToXlsx({ json, title });
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <Row className="row-gap-4 d-print-none PURCHASE">
        <ShowInfo data={data} />
        {data?.pay && <PayInfo pay={data?.pay} />}
        {/* {data?.status !== "rejected" && (
          <Col xs="12" className="d-print-none d-flex flex-center">
            <ButtonGroup>
              {showChangeStatus ? (
                <React.Fragment>
                  <Button
                    variant="success"
                    onClick={() => verifyPurchase("accepted")}
                  >
                    تایید پرداخت
                  </Button>
                  <Button variant="danger" onClick={() => setShowModal(p => p = !p)}>
                    رد پرداخت
                  </Button>
                </React.Fragment>
              ) : (
                purchaseStatus
                  .filter((e) => e.updatable)
                  .map((e) => (
                    <Button
                      key={e.id}
                      variant={e.color}
                      onClick={() => updatePurchase(e.id)}
                    >
                      {e.name}
                    </Button>
                  ))
              )}
            </ButtonGroup>
          </Col>
        )} */}
      </Row>
      <MailingLabel info={data} />
      <Button
      variant="success"
        className="d-print-none mt-5 d-block mx-auto"
        onClick={() => {downloadXlsx()}}
      >
        اکسل پرداخت
      </Button>
      
    </div>
  );
}
