import { useState, useEffect } from 'react'
import { Table } from '../../../components';
import { moment } from '../../../boot';
import { installmentStatus, paymentMethods } from '../../../constants';
import { axios } from '../../../boot'
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const UserInstallment = () => {
    const [installment, setInstallment] = useState([])
    const getInstallment = () => {
        const url = "/pub/shop/installments";
        axios.get(url).then(({ data }) => {
            const res = data?.data?.slice(0, 3)
            setInstallment(res)
        })
    };
    const showStatus = (id = 0) => {
        const { color, name } = installmentStatus.find((e) => e.id === id) ?? {};
        return <span className={`text-${color} fw-bold`}>{name}</span>;
    };
    useEffect(getInstallment, [])
    return (
        <div className="bg-dark-black rounded-4 my-3 py-2">
            <Container className="d-flex align-items-center justify-content-between my-2">
                <p>آخرین اقساط من</p>
                <Link to="/my-installments" className='text-primary fs-14 d-flex align-items-center'>نمایش همه <i className="bi bi-arrow-left-short"></i></Link>
            </Container>
            <Table className="d-none d-lg-table">
                <thead>
                    <tr>
                        <th>نام دوره</th>
                        <th>مبلغ قسط</th>
                        <th>تاریخ سررسید</th>
                        <th>وضعیت</th>
                        {/* <th></th> */}
                    </tr>
                </thead>
                <tbody>
                    {installment?.map((e, i) => (
                        <tr key={e._id + i} className='py-2'>
                            <td className="text-white fw-700">
                                {e.items?.map((e) => e.course?.title).join(" - ")}
                            </td>
                            <td className="text-white">{`${e.totalPrice} تومان -  ${e.totalPriceUsd} تتر`}</td>
                            <td className="text-white">
                                <span dir="ltr">
                                    {moment.miladiToShamsi({
                                        date: e.payDate,
                                        format: "jYYYY/jMM/jDD - HH:mm:ss",
                                    })}
                                </span>
                            </td>
                            <td>{showStatus(e.status)}</td>
                            {/* <td>
                                <button className='btn btn-primary' onClick={() => verifyPayment(e._id)}>پرداخت</button>
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default UserInstallment