import React, { useState, useEffect } from 'react'
import { Table, Pagination, Badge } from '../../../components'
import { convertPhone, jsonToXlsx, scrollToTop } from "../../../methods";
import { axios, moment } from "../../../boot";
import { Container } from "react-bootstrap";
import { paymentMethods, purchaseStatus } from "../../../constants";
import OrderCard from '../../../components/OrderCard';

const MyOrders = () => {
  // const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const [myOrder, setMyOrder] = useState([])
  // const [pages, setPages] = useState({});
  // const [params, setParams] = useState({ status: "", ...basicParams });
  const [ active , setActive ] = useState("paid")
  const getPurchases = () => {
    const url = "/pub/shop/purchases";
    // const config = {
    //   params: {

    //     ...params,
    //   },
    // };
    axios.get(url).then(({ data }) => {
      setMyOrder(data.data)
      scrollToTop();
    });
  };
  const calCount = (items) => {
    let sum = 0
    for (let e of items) {
      sum = sum + e?.count
    }
    return sum
  }
  useEffect(getPurchases, [])
  const buttonValues = [
    {
      label: "پرداخت موفق",
      value: "paid",
      length: myOrder?.filter(e => e.status == "paid")?.length,
      variant: "success"
    },
    {
      label: "در انتظار پرداخت",
      value: "pending",
      length: myOrder?.filter(e => e.status == "pending")?.length,
      variant: "primary"
    },
    {
      label: "ارسال شده ها",
      value: "shipping",
      length: myOrder?.filter(e => e.status == "shipping")?.length,
      variant: "success"
    },
    {
      label: "لغو شده ها",
      value: "reject",
      length: myOrder?.filter(e => e.status == "reject")?.length,
      variant: "warning"
    },
  ]
  const showStatus = (id = 0) => {
    const { color, name } = purchaseStatus.find((e) => e.id === id) ?? {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };
  // if(myOrder?.length == 0)
  //   return <h4 className="text-primary text-center">شما تا به حال سفارشی نداشتید</h4>;
  return (
    <div className="bg-dark-black p-2">
      <div className="d-inline-block rounded-4 bg-black py-2 px-1">
        {buttonValues?.map((e) =>
          <button key={e.value} onClick={() => setActive(e.value)} className={`btn ${active === e.value ? "btn-white" : "text-white"} rounded-4 m-1`}>{e.label} <Badge variant={e.variant} pill={true} label={e.length} /> </button>
        )}
      </div>
      <div className="d-lg-none">
        <p className="text-white my-4 fs-20 text-center fw-500">سفارشات من</p>
        { myOrder?.filter(item => item.status == active)?.map((e) =>
          <OrderCard {...e} />
        )}
      </div>
      <Table className="d-print-none d-none d-lg-table">
        <thead>
          <tr>
            <th>شماره پیگیری سفارش</th>
            <th>محصولات</th>
            <th>تعداد</th>
            <th>تاریخ</th>
            <th>قیمت</th>
            {/* <th>تخفیف</th> */}
            {/* <th>وضعیت</th> */}
          </tr>
        </thead>
        <tbody>
          {myOrder?.length > 0 
            &&
            myOrder?.filter(item => item.status == active)?.map((e, i) => (
              <tr key={e._id + i} className="">
                <td className="text-white">
                  {e.refId}
                </td>

                <td className="text-primary">{e.items?.map((e) => e.course?.title).join(" - ")}  {e.items?.map((e) => e.product?.title_fa).join(" - ")}</td>
                <td className="text-white">{calCount(e?.items)}</td>
                <td className="text-white">
                  <span dir="ltr">
                    {moment.miladiToShamsi({
                      date: e.createdAt,
                      format: "jYYYY/jMM/jDD - HH:mm:ss",
                    })}
                  </span>
                </td>
                <td className="text-success">{e.totalPrice}  {paymentMethods.find((mt) => mt.id === e.paymentMethod)?.unit}</td>
                {/* <td className="text-danger">{e.voucher}</td> */}
                {/* <td>{showStatus(e.status)}</td> */}
              </tr>
            ))
          }
        </tbody>
      </Table>
      {/* <Pagination
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      /> */}
      {/* {params.page < pages.totalPages && (
        <button
          className="d-block mx-auto w-fit text-primary"
          onClick={() => {
            setParams((p) => ({ ...p, page: p.page + 1 }));
          }}
        >
          نمایش بیشتر...
        </button>
      )} */}
    </div>
  )
}

export default MyOrders