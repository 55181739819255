import { useContext } from "react";
import { axios } from "../boot";
import { CartCountContext } from "../contexts/CartCount";
import { toast } from "../methods";
export default function useAddToCart() {
  const [count, setCount] = useContext(CartCountContext);
  const addToCart = async (id = "", count = 1, type = "book", installment = false) => {
    const url = "/purchases/cart";
    let body = {};
    if (type == "course") {
      body = {
        courseId: id,
        count,
        installment: installment
      }

    } else {
      body = {
        productId: id,
        count,
      };
    }
    const response = await axios.post(url, body);

    if (response.status === 200) {
      let text = ""
      if (type == "book") {
        setCount((p) => p + 1);
        text = "محصول به سبد خرید شما اضافه شد"
        toast({ text });
      }
      
    }
    return response;
  };
  return addToCart;
}
