import React, { useContext, useState } from 'react'
import { Button, Modal, Table } from '../../../components'
import Context from './_context';
import { toast } from '../../../methods';
import Edit from './Edit';
import { astrologyStatus } from '../../../constants';

const Charts = () => {
    const [info, setInfo] = useState(null)
    // const [ show , setShow ] = useState(false)
    const [page, setPage, chartData, setChartData] = useContext(Context);
    const downloadChart = (info) => {
        if (info?.status === "exported") {
            let text = "چارت شما تا 20 روز آینده آماده خواهد شد."
            return toast({ text })
        } else {
            const url = info?.file
            const link = document.createElement('a');
            link.href = url;
            link.target = "_blank"
            link.setAttribute('download', 'chart.zip');
            document.body.appendChild(link);
            link.click()
        }

    }
    const showStatus = (id = 0) => {
        const { color, name } = astrologyStatus.find((e) => e.id === id) ?? {};
        return <span className={`text-${color} fw-bold`}>{name}</span>;
    };
    return (
        <div className="w-full">
            <Modal size='xl' title="ویرایش اطلاعات چارت" show={info !== null} onHide={() => setInfo(null)}>
                <Edit info={info} onClose={() => setInfo(null)} />
            </Modal>
            {
                chartData?.length > 0
                    ?
                    <>
                        <div className="w-full d-flex align-items-center justify-content-start my-2">
                            <Button onClick={() => setPage("add")} className="d-flex align-items-center"> <i className="bi bi-plus-lg mx-1"></i>افزودن چارت جدید</Button>
                        </div>

                        <Table>
                            <thead>
                                <tr>
                                    <th>نام و نام خانوادگی</th>
                                    <th>شماره تماس</th>
                                    <th>آیدی تلگرام</th>
                                    <th>ایمیل</th>
                                    <th>محل تولد</th>
                                    <th>سال تولد</th>
                                    <th>ماه تولد</th>
                                    <th>روز تولد</th>
                                    <th>ساعت تولد</th>
                                    <th>دقبقه تولد</th>
                                    <th>وضعیت</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {chartData?.map((e) =>
                                    <tr className="text-white">
                                        <td>{e.firstName} {e.lastName}</td>
                                        <td>{e.phone}</td>
                                        <td>{e.telegramid}</td>
                                        <td>{e.email}</td>
                                        <td>{e.placeofbirth}</td>
                                        <td>{e.birthYear}</td>
                                        <td>{e.birthMonth}</td>
                                        <td>{e.birthday}</td>
                                        <td>{e.birthHour}</td>
                                        <td>{e.birthMinute}</td>
                                        <td>{showStatus(e.status)}</td>
                                        <td><Button onClick={() => downloadChart(e)} variant='success'>دانلود چارت <i className="bi bi-download"></i></Button> <Button disabled={e?.status === "exported"} onClick={() => setInfo(e)}>ویرایش <i className="bi bi-pencil-square"></i></Button></td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </>
                    :
                    <div className="w-full d-flex flex-column align-items-center justify-content-center my-2">
                        <p className="text-center my-4 text-white fw-500 fs-20">برای شما چارتی ثبت نشده است</p>
                        <Button onClick={() => setPage("add")} className="d-flex align-items-center"> <i className="bi bi-plus-lg mx-1"></i>افزودن چارت جدید</Button>
                    </div>
            }

        </div>
    )
}

export default Charts