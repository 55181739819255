import React , {useState} from 'react'

import { Col } from 'react-bootstrap'
import { AdminInput , Button , Form } from '../../../components'
import { axios } from '../../../boot'
import { toast } from '../../../methods'

const SendSms = () => {
    const [ data , setData ] = useState({})
    const formControls = [
        {
            as : "textarea",
            label : "شماره موبایل",
            state : "phone"
        },
        {
            label : "لینک کلاس",
            state : "link"
        }
    ]

    const submitData = () => {
        const url = "/admins/sendclasssms"
        axios.post(url , data).then(({data})=>{
            if(data=="ok"){
                const text = "پیام با موفقیت ارسال شد"
                toast({text})
            }
            setData({})
        })
    }
  return (
    <Form className="row" onSubmit={submitData}>
      {formControls.map((e) => (
        <Col key={e.state} xs="12" md="12" lg="12">
          {React.createElement(e.tag ?? AdminInput, {
            ...e,
            value: data[e.state],
            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
          })}
        </Col>
      ))}
      <Col xs="12" className="d-flex flex-center">
        <Button type="submit">ثبت</Button>
      </Col>
    </Form>
  )
}

export default SendSms