import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import { Input , AdminInput } from "..";
import "./index.scss";
export default function Select({
  as = Input,
  dir = null,
  label = "",
  value = "",
  placeholder = null,
  border = false,
  labelColorr = "",
  items = [],
  filter = false,
  drop = "down",
  align = "right",
  setValue = () => {},
  rules = undefined,
  cancelBtn = false,
}) {
  const [filterText, setFilterText] = useState("");
  const filterItem = () => {
    if (filter) {
      return items.filter((e) => e.name.search(filterText) !== -1);
    }
    return items;
  };
  const showValue = () => {
    return items.find((e) => `${e.id}` === `${value}`)?.name;
  };
  const showCancelBtn = cancelBtn && !!showValue();
  return (
    <Dropdown  className={` w-100  SelectInput text-${labelColorr} `} onSelect={setValue} drop={drop}>
      
      <Dropdown.Toggle
        as={as}
        className={`w-100 ${border ? "border " : "border-0"}  `}
        dir={dir}
        variant={labelColorr}
        label={label}
        placeholder={placeholder}
        rules={rules}
        value={showValue()}
        readOnly
        append={
          showCancelBtn && (
            <button
              type="button"
              onClick={() => setValue(undefined)}
              className={`bi bi-x-lg text-danger input-group-text border-light-gray`}
            />
          )
        }
      />

      <Dropdown.Menu align={align} className={`DropDownMenu w-100`}>
        
        {filter && (
          <div className={`dropdown-item`}>
            <FormControl
              autoFocus
              value={filterText}
              
              onChange={({ target }) => {
                setFilterText(target.value);
              }}
            />
          </div>
        )}
        {filterItem().map((item, index) => (
          <Dropdown.Item key={index} className={`text-center`} eventKey={item.id}>
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
