import { useState, useEffect } from 'react'
import { Table } from '../../../components';
import { moment } from '../../../boot';
import { paymentMethods } from '../../../constants';
import { axios } from '../../../boot'
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
const UserOrder = () => {
    const [order, setOrder] = useState([])
    const getPurchases = () => {
        const url = "/pub/shop/purchases";
        axios.get(url).then(({ data }) => {
            const res = data?.data?.slice(0, 3)
            setOrder(res)
        });
    };
    useEffect(getPurchases, [])
    return (
        <div  className="bg-dark-black rounded-4 my-3 py-2">
            <Container className="d-flex align-items-center justify-content-between my-2">
                <p>آخرین سفارشات</p>
                <Link to="/my-order" className='text-primary fs-14 d-flex align-items-center'>نمایش همه <i className="bi bi-arrow-left-short"></i></Link>
            </Container>
            <Table style={{height : "auto"}} className="">
                <thead>
                    <tr>
                        <th>شماره پیگیری سفارش</th>
                        <th>محصولات</th>
                        <th>تعداد</th>
                        <th>تاریخ</th>
                        <th>قیمت</th>
                        {/* <th>تخفیف</th> */}
                        {/* <th>وضعیت</th> */}
                    </tr>
                </thead>
                <tbody>
                    {order?.length > 0
                        &&
                        order?.map((e, i) => (
                            <tr key={e._id + i} className="">
                                <td className="text-white">
                                    {e.refId}
                                </td>

                                <td className="text-primary">{e.items?.map((e) => e.course?.title).join(" - ")}  {e.items?.map((e) => e.product?.title_fa).join(" - ")}</td>
                                <td className="text-white">{e.items?.map((e) => e.count)}</td>
                                <td className="text-white">
                                    <span dir="ltr">
                                        {moment.miladiToShamsi({
                                            date: e.createdAt,
                                            format: "jYYYY/jMM/jDD - HH:mm:ss",
                                        })}
                                    </span>
                                </td>
                                <td className="text-white">{e.totalPrice}  {paymentMethods.find((mt) => mt.id === e.paymentMethod)?.unit}</td>
                                {/* <td className="text-danger">{e.voucher}</td> */}
                                {/* <td>{showStatus(e.status)}</td> */}
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </div>
    )
}

export default UserOrder