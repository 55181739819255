import Home from "../pages/Landing/Home";
import Blog from "../pages/Landing/Blog";
import BlogDetail from "../pages/Landing/BlogDetail";
import FreeTutorials from "../pages/Landing/FreeTutorials";
import FreeTutorialsVideos from "../pages/Landing/FreeTutorials/Videos";
import FreeTutorial from "../pages/Landing/FreeTutorial";
import Products from "../pages/UsersPanel/Products";
import Product from "../pages/UsersPanel/Product";
import Cart from "../pages/UsersPanel/Cart";
import { Container } from "react-bootstrap";
import Tutorials from "../pages/Landing/Tutorials";
import Tutorial from "../pages/Landing/Tutorials/Tutorial";
import BuyConsultation from "../pages/Landing/BuyConsultation";
import Receipt from "../pages/UsersPanel/Receipt";
import NewReceipt from "../pages/Landing/NewReceipt";
import BookReading from "../pages/Landing/BookReading";
import CooperativeReceipt from "../pages/Landing/CooperativeReceipt";
import ZeroPoint from "../pages/Landing/ZeroPoint";
import ZeroReceipt from "../pages/Landing/ZeroReceipt";
const landingRoutes = (isLogged = false) =>
  [
    { path: "", element: <Home /> },
    { path: "blog", element: <Blog /> },
    { path: "blog/:id", element: <BlogDetail /> },
    { path: "free-tutorials", element: <FreeTutorials /> },
    { path: "free-tutorials/:categoryId", element: <FreeTutorialsVideos /> },
    { path: "free-tutorials/:categoryId/:id", element: <FreeTutorial /> },
    {
      path: "private-trade",
      element: <BuyConsultation />,
    },
    {
      path: "zero-point",
      element: <ZeroPoint />,
    },
    {
      path: "zeropointreceipt",
      element: <ZeroReceipt />,
    },
    {
      path: "cooperativereceipt",
      element: <CooperativeReceipt />,
    },
    {
      path: "book-reading",
      element: <BookReading />,
    },
    {
      path: "tutorials",
      element: <Tutorials />,
    },
    {
      path: "tutorials/:categoryId",
      element: <Tutorial />,
    },

    {
      path: "products",
      element: (
        <Container>
          <Products />
        </Container>
      ),
    },
    {
      path: "products/:id",
      element: (
        <Container>
          <Product />
        </Container>
      ),
    },
    {
      path: "receipt",
      element: <Receipt />,
    },
    {
      path: "new-receipt",
      element: <NewReceipt />,
    },
    isLogged && {
      path: "cart",
      element: (
        <Container>
          <Cart />
        </Container>
      ),
    },
  ].filter(Boolean);
export default landingRoutes;
