import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Select, AdminInput } from '../../../components';
import { Col } from 'react-bootstrap';
import { rules } from '../../../constants';
import { useGetCities, useGetProvinces } from '../../../hooks';
import { toast } from '../../../methods';
import { axios } from '../../../boot'
const AddAddress = ({ info = {}, setShow = () => { }, getAddress = () => { } }) => {
    const isNew = typeof info == "boolean"
    const [data, setData] = useState({
        shippingMethod: ""
    })
    const [provinces] = useGetProvinces();
    const [cities, getCities] = useGetCities();
    const formControls = [
        {
            label: "نام",
            state: "firstName",
            placeholder: "نام خود را وارد کنید",
            labelColor: "white",
            borderColor: "jet",
            lg: 6
        },
        {
            label: "نام خانوادگی",
            state: "lastName",
            placeholder: "نام خانوادگی خود را وارد کنید",
            labelColor: "white",
            borderColor: "jet",
            lg: 6
        },
        {
            dir: "ltr",
            label: "شماره تلفن همراه",
            state: "phone",
            type: "number",
            placeholder: "شماره تلفن دریافت کننده بسته",
            labelColor: "white",
            borderColor: "jet",
            lg: 5
        },
        {
            dir: "ltr",
            label: "شماره ثابت",
            state: "landlinePhone",
            type: "number",
            placeholder: "شماره ثابت دریافت کننده بسته ",
            labelColor: "white",
            borderColor: "jet",
            lg: 5
        },
        {
            label: "پیش شماره",
            state: "areaCode",
            labelColor: "white",
            borderColor: "jet",
            lg: 2
        },
        {
            label: "کد پستی",
            state: "postCode",
            labelColor: "white",
            borderColor: "jet",
            rules: rules.postCode,
            lg: 6
        },
        {
            tag: Select,
            label: "ارسال به",
            state: "shippingMethod",
            items: [
                { name: "خارج از ایران", id: "toOtherCountries" },
                { name: "داخل ایران", id: "toIran" },
            ],
            labelColorr: "white",
            borderColor: "jet",
            cancelBtn: true,
            lg: 6
        },
        data?.shippingMethod == "toIran" && {
            tag: Select,
            label: "استان",
            state: "province",
            filter: true,
            items: provinces,
            labelColorr: "white",
            borderColor: "jet",
            cancelBtn: true,
            lg: 6
        },
        data?.shippingMethod == "toIran" && {
            tag: Select,
            label: "شهر",
            state: "city",
            filter: true,
            items: cities,
            labelColorr: "white",
            borderColor: "danger",
            cancelBtn: true,
            lg: 6
        },
        {
            label: "آدرس ",
            state: "address",
            labelColor: "white",
            borderColor: "jet",
            rules: rules.description,
            lg: 12
        },
    ].filter(Boolean);
    const addAddress = () => {
        let body = { ...data }
        if (data?.shippingMethod == "toIran") {
            body.address = `${data.province} - ${data.city} - ${data.address}`;
            body.province === "تهران" && (body.shippingMethod = "toTehran");
        }
        delete body.province
        delete body.city
        const url = "/address"
        axios.post(url, body).then(({ data }) => {
            let text = "آدرس با موفقیت افزوده شد"
            toast({ text })
            setData({})
            setShow(null)
            getAddress()
        })
    }
    const editAddress = () => {
        let body = { ...data }
        if (data?.shippingMethod == "toIran") {
            body.address = `${data.province} - ${data.city} - ${data.address}`;
            body.province === "تهران" && (body.shippingMethod = "toTehran");
        }
        delete body.province
        delete body.city
        const url = "/address"
        axios.put(url, body).then(({ data }) => {
            let text = "آدرس با موفقیت افزوده شد"
            toast({ text })
            setData({})
            setShow(null)
            getAddress()
        })
    }
    const getAddressInfo = () => {
        if(isNew) return setData({})
        delete info?.updatedAt
        delete info?.user
        delete info?.createdAt
        let body = { ...info }
        let controlAddress = body?.address?.split(" - ")
        if (controlAddress?.length > 2) {
            body.shippingMethod = "toIran"
            body.address = controlAddress[2]
            body.province = controlAddress[0]
            body.city = controlAddress[1]
        } else {
            body.shippingMethod = "toOtherCountries"
        }
        setData(body)
    }
    useEffect(() => getCities(data?.province), [data?.province]);
    useEffect(getAddressInfo, [info])
    return (
        <div className="p-2">
            <Form className="row" onSubmit={isNew ? addAddress : editAddress}>
                {formControls.map((e) => (
                    <Col
                        key={e.state}
                        xs="12"
                        lg={e.lg}
                    >
                        {React.createElement(e.tag ?? AdminInput, {
                            ...e,
                            value: data && data[e.state],
                            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                        })}
                    </Col>
                ))}
                <Col xs="12">
                    <Button outline={true} type="submit" className="w-100">
                        <i className="bi bi-pen-fill ms-1" />
                        {isNew ? "ثبت آدرس" : "ویرایش آدرس"}
                    </Button>
                </Col>
            </Form>
        </div>
    )
}

export default AddAddress