import { useState } from "react";
import { axios } from "../boot";

export default function useGetFiles() {
  const [files, setFiles] = useState([]);
  const getFiles = () => {
    const url = "/admins/pub/files";
    axios.get(url).then((res) => {
      
      const data = res.data.data.map((e) => ({ ...e, isAdmin: true }));
      
      setFiles(data);
    });
  };
  return [files, getFiles];
}
