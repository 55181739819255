import { Link } from "react-router-dom";
import "./index.scss";

const TeacherCard = ({
    name = "",
    _id = "",
    post = "",
    image = ""
}) => {
  return (
    <div className="UserCard h-100 position-relative w-100 d-flex flex-column flex-center text-justify text-last-center rounded shadow-sm border border-light overflow-hidden space-y p-2">
      <img src={image} alt="teacher" width="60" height="60" className="rounded-circle object-fit-cover" />
      <h5>{name}</h5>
      <h6>
        <span className="text-secondary">دوره</span>
        {": "}
        <span dir="ltr">{post}</span>
      </h6>
      <Link to={_id} className="btn btn-primary btn-sm">
        مشاهده جزئیات
      </Link>
    </div>
  )
}


export default TeacherCard