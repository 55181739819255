import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "react-bootstrap";
import './index.scss'
import userProfile from '../../../assets/icons/userProfile.svg'
import { Link } from "react-router-dom";
export default function Profile() {
  const dispatch = useDispatch();
  const profile = useSelector((s) => s.profile);
  const [ data , setData ] = useState({})
  const formControls2 = [
    {
      label: "نام",
      state: "firstName",
    },
    {
      label: "نام خانوادگی",
      state: "lastName",
    },
    {
      label: "ایمیل",
      state: "email",
    },
    {
      label: "کدملی",
      state: "codeMelli",
    },
    {
      label: "شماره موبایل",
      state: "phone",
    },
  ];
  useEffect(() => setData(profile), [profile]);
  return (
    <div className="Profile">
      <div className="bg-raisin py-4 px-2 shadow-sm">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center px-2 my-2">
          <div className="d-flex align-items-center my-1">
            <img src={userProfile} alt="userProfile" />
            <p className="fs-14 fw-400 text-white mx-1">اطلاعات کاربری</p>
          </div>
          <Link state={profile} to="/profile/edit" className="bg-transparent text-primary fs-14 my-1"><i className="bi bi-pencil mx-1"></i>ویرایش اطلاعات حساب کاربری</Link>
        </div>
        <Row className="d-flex align-items-start justify-content-start my-4">
          {formControls2.map(e => 
              <Col key={e.state} className="py-1" xs="12" sm="6" >
                <p className="text-white fs-16">{e.label} :</p>
                <p className="fs-14 my-2">{data[e.state] ? data[e.state] : "-"}</p>
              </Col>
          )}
        </Row>
      </div>
    </div>
  );
}
