import React, { useState, useEffect } from 'react'
import { useGetCategory } from '../../../../hooks'
import PopularClass from './PopularClass'
import { useSelector } from 'react-redux'
import { axios } from '../../../../boot'
import SubjectClass from './SubjectClass'
const Subjects = () => {
    const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
    const [ tutorials , setTutorials ] = useState([])
    const [params, setParams] = useState({ page: 1,category : "", ...basicParams });
    const isLogged = useSelector((s) => s.isLogged)
    const [categories, getCategories] = useGetCategory()
    const getTutorials = () => {
        const config = {
            params: {
                sort: "category",
                perPage: 20,
                ...params,
            },
        };
        let url = ""
        if (isLogged) {
            url = "/pub/shop/usercourses"
        } else {
            url = "/pub/shop/courses";
        }
        axios.get(url, config).then((res) => {
            const data = res.data?.data?.filter(i => i.status !== "disable")?.map((e) => ({
                teacher: e.teacher,
                available: e.available,
                title: e.title,
                _id: e._id,
                cover: e.cover,
                category: e.category,
                time: e.time,
            }));
            setTutorials(data);
        });
    };
    useEffect(getCategories, [])
    useEffect(getTutorials, [params]);
    return (
        <div className="SUBJECTS w-100">
            <p className='text-center text-white fw-700 fs-25'>هر موضوعی را که به آن علاقه <br /> دارید انتخاب کنید</p>
            <div className="w-100 d-flex align-items-center justify-content-center my-4">
                <button onClick={()=>setParams((p) => ({ ...p, page: 1, "category": "" }))} className={`SubjectBtn ${params?.category == "" && "Active"} px-2 m-1`}>پرفروش ها</button>
                {categories?.map((e) =>
                    <button className={`SubjectBtn ${params?.category == e.title_fa && "Active"} px-2 m-1`} key={e._id} onClick={() => {
                        if (params.category) {
                          setParams((p) => ({ ...p, page: 1, "category": "" }));
                        } else {
                          setParams((p) => ({ ...p, page: 1, "category": e.title_fa }));
                        }
                    }}>{e.title_fa}</button>
                )}
            </div>
            {params?.category == "" && <PopularClass/>}
            {params?.category != "" && <SubjectClass data={tutorials}/>}
        </div>
    )
}

export default Subjects