import { useState, useEffect } from 'react'

import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { axios } from "../../../boot";
import { Table } from "../../../components";
import { toast } from "../../../methods";
import { useNavigate } from 'react-router-dom';

const Medias = () => {
    const [medias, setMedias] = useState([]);
    const navigate = useNavigate()
    const getUsers = () => {
        const url = "/admins/pub/files";
        axios.get(url).then(({ data }) => {
            console.log(data)
            setMedias(data.data);
        });
    };

    useEffect(getUsers, []);
    return (
        <Row className="align-items-stretch">
            <div className="col-12 d-flex justify-content-end">
                <Link
                    to="new"
                    className="bi bi-plus-lg d-flex flex-center fs-4 text-success"
                />
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>موضوع</th>
                        <th>لینک</th>
                    </tr>
                </thead>
                <tbody>
                    {medias.map((e) => (
                        <tr key={e._id} onClick={() => navigate(e._id)}>
                            <td>{e.title}</td>
                            <td>{e.file}</td>
                            
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>
    )
}

export default Medias