import React , { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row , Col } from 'react-bootstrap'
import { Table , Pagination , AdminInput , Select } from '../../../components'
import { axios, moment } from "../../../boot"
import { adviceStatus } from '../../../constants'

const Calendars = () => {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [calendars, setCalendars] = useState([])
  const [pages, setPages] = useState({});
  const getCalendars = () => {
    const url = "/admins/calendar"
    console.log(params)
    const config = {
      params: {
        sort: "createdAt:-1",
        perPage: 20,
        ...params,
      },
    };
    axios.get(url,config).then(({ data }) => {
      setCalendars(data.data)
      setPages(data.pages);
      console.log(data)
    })
  }
  useEffect(getCalendars, [params])
  return (
    <Row className="align-items-stretch">
      <div className="col-12 d-flex justify-content-end">
        <Link
          to="new"
          className="bi bi-plus-lg d-flex flex-center fs-4 text-success"
        />
      </div>
      
      <Table>
        <thead>
          <tr>
            <th>نام دوره </th>
            <th>نام استاد</th>
            <th>زمان شروع</th>
            <th>زمان کلاس</th>
          </tr>
        </thead>
        <tbody>
          {calendars?.map((e) => (
            <tr key={e._id} onClick={() => navigate(e._id)}>
              <td>{e.title}</td>
              <td>{e.extendedProps?.teacher}</td>
              <td>{moment.miladiToShamsi({date : e.start})}</td>
              <td className="text-success">{e.extendedProps?.time}</td>
              
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
    </Row>
  )
}

export default Calendars