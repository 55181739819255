import React, { useState } from "react";
import Select from "react-select";
import './index.scss'

export default function MultiSelect({
    options = [],
    selectedOptions = [],
    setSelectedOptions = () => {},
    label = "",
}) {
    // const [selectedOptions, setSelectedOptions] = useState();
    function handleSelect(data) {
        
        setSelectedOptions(data);
        
    }
    return (
        <div className="app">
            <span className="text-start d-block w-100 fs-6 text-dark-blue me-2 mb-2">
                {label}
            </span>
            <div className="border border-light-gray">
                <Select
                    
                    options={options}
                    placeholder={`${label} را انتخاب کنید`}
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                />
            </div>
        </div>
    )
}
