let days = []
for(let i=1;i<32;i++){
    days.push({name : i , id : i})
}

let years = []
for(let j=1950;j<2025;j++){
    years.push({name : j , id : j})
}

let months = []
for(let e=1;e<13;e++){
    months.push({name : e , id : e})
}

const dateBirth = {days,months,years}
export default dateBirth

