import { useState } from 'react'
import { axios } from '../../../boot'
import { AdminInput, Form } from '../../../components'
import { Col } from 'react-bootstrap'
import './index.scss'
import { convertPhone, toast } from '../../../methods'
// import logoLogin from '../../../assets/logos/logoLogin.png'
import logo from '../../../assets/logos/Marvel academy.svg'
const Dental = () => {
    const [data, setData] = useState({})
    const formControls = [
        {
            label: "شماره تماس",
            labelColor: "black",
            state: "phone"
        },
        {
            label: "نام",
            labelColor: "black",
            state: "firstName"
        },
        {
            label: "نام خانوادگی",
            labelColor: "black",
            state: "lastName"
        },
        {
            as: "textarea",
            labelColor: "black",
            label: "علت مراجعه به دندان پزشکی",
            state: "problem",
            placeholder : "علت مراجعه به دندان پزشکی را بنویسید"
        },
    ]
    const sendData = () => {
        const url = "https://api.academymarvel.com/api/Dental"
        const body = {...data , phone : convertPhone(data?.phone)}
        axios.post(url , body).then(({data})=>{
            let text = "اطلاعات ب موفقیت ثبت شد"  
            toast({type : "success",text})
            setData({})
        })
    }
    return (
        <div className=" DENTAL row">
            <Col xs="12" lg="6">
                <Form onSubmit={sendData} className=" DENTAL-FORM row w-100 bg-white px-2 py-4 rounded shadow-lg my-2">
                    <img src={logo} alt='logo'  />
                    {formControls.map((item) =>
                        <Col key={item.state} xs="12">
                            <AdminInput
                                className=" text-black rounded-4 border-dark-block fs-14 fw-400"
                                {...item}
                                value={data[item.state]}
                                setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
                            />
                        </Col>
                    )}
                    <Col>
                        <button className='btn btn-primary text-white w-100 rounded'>ثبت</button>
                    </Col>

                </Form>
            </Col>
        </div>
    )
}

export default Dental