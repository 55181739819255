const categoryTypes = [
  {
    name: "دوره ها",
    url: "",
    endUrl : "coursecats"
  },
  {
    name: "فروشگاه",
    url: "/shop",
    endUrl : "categories"
  },
  {
    name: "دوره‌های رایگان",
    url: "",
    endUrl : "categories"
  },
].map((e) => ({ ...e, url: e.url , endUrl : e.endUrl  }));
export default categoryTypes;
