import React, { useEffect, useState } from 'react';

import './index.scss'
import { Col, Container, Fade, Row } from "react-bootstrap"
import Cash from './Cash';
import Installments from './Installments';
import { useSelector } from 'react-redux';
import { axios, moment } from '../../boot'
import Table from '../Table';
import { offCal } from '../../methods';
const ShowPrice = ({
    data = {},
}) => {
    const [open, setOpen] = useState(false)
    const profile = useSelector((s) => s.profile);
    const numberInstallment = data?.numberOfInstallments
    const totalPrice = offCal(data?.priceInstallment, data?.off)?.toLocaleString();
    const totalPriceUsd = offCal(data?.priceInstallmentUsd, data?.offUsd);
    const handleDate = (enter) => {
        let a = Date.now() + (enter * (30 * 24 * 3600 * 1000))
        let b = new Date(a)
        return moment.miladiToShamsi({
            date: b
        })
    }
    return (
        <Container className="w-100 my-4 py-2 SHOWPRICE">
            <Row style={{ borderRadius: "24px" }} className='border border-primary p-4'>
                <Col className="" xs="12" lg="8">
                    <p className='text-white fw-700 fs-25'>{data?.title}</p>
                </Col>
                <Col xs="12" lg="4">
                    <Cash info={profile} data={data} />
                    {data?.installment &&
                        <Installments info={profile} data={data} />
                    }
                </Col>
                {data?.installment &&
                    <Col xs="12">
                        <div>
                            <button style={{ color: "#17b90f" }} onClick={() => setOpen(p => !p)} className="bg-transparent border-0 fs-15 fw-500" > <i className="bi bi-plus m-1"></i> نمایش اقساط ...</button>
                        </div>
                        <Fade className={open ? "d-block" : 'd-none'} timeout={500} in={open}>
                            <div style={{ borderRadius: "24px" }} className={"h-auto w-100 "} id="example-fade-text" >
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>قسط</th>
                                            <th> پرداخت</th>
                                            <th>مبلغ</th>
                                            <th>میزان دسترسی</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[...Array(Number(numberInstallment))]?.map((item, index) =>
                                            <tr className="text-white" key={index}>
                                                <td>قسط {index + 1}</td>
                                                <td>{handleDate(index)}</td>
                                                <td>{`${totalPrice} تومان -  ${totalPriceUsd} تتر`}</td>
                                                <td className="text-success">{`${Math.floor(100 / numberInstallment)}%`}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </Fade>
                    </Col>
                }

            </Row>
        </Container>
    )
}

export default ShowPrice