import { useState } from 'react'
import { Badge, Modal, Ribbon2, Video } from '../../../components'
import { Col, Container, Row } from 'react-bootstrap'
import ShowPrice from '../../../components/ShowPrice'
import Breadcrumbs from './Breadcrumbs'

const Introduction = ({ data = {} }) => {
    return (
        <div className="Introduction-video ">
            <Container>
                <Breadcrumbs title={data?.title} />
            </Container>
            <Row className='d-flex flex-column-reverse flex-lg-row'>
                <Col xs={12} lg={6} className='AboutCourse d-flex flex-column justify-content-center align-items-center p-0'>
                    <p className='fs-36 fw-900 text-center text-white my-1'>{data?.teacher?.name}</p>
                    <span style={{ width: "27px" }} className='border border-white border-2 my-4'></span>
                    <p className='fs-20 fw-300 text-center text-white'>{data?.title}</p>
                    {/* <p className="fs-12 fw-400 text-white lh-md my-2 px-4">
                        {data?.teacher?.description}
                    </p> */}
                    <button onClick={() => {
                        window.scroll({
                            top: document.body.offsetHeight,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }} className="btn btn-primary text-white my-4 px-4 py-2 rounded-4">خرید دوره</button>
                </Col>
                <Col xs={12} lg={6} className='TeacherImage p-0'>
                    <img loading='lazy' src={data?.fullScreenCover} alt={data?.teacher?.name} />
                </Col>
            </Row>
            {/* <Modal type='black' show={show} size='lg' onHide={setShow} title='هزینه دوره'>
                <ShowPrice data={data} />
            </Modal> */}
        </div>
    )
}

export default Introduction