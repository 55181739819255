import React, { useEffect, useState } from "react";
import { ButtonGroup, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { axios } from "../../../boot";
import { Button, Form, AdminInput, Select } from "../../../components";
import { blogStatus, rules } from "../../../constants";
import { useGetCourse } from "../../../hooks";
import { objectSelect, toast , fileToBase64 } from "../../../methods";

export default function Slider() {
  const navigate = useNavigate();
  const [ courses, getCourses ] = useGetCourse();
  const params = useParams();
  const isNew = params.id === "new";
  const [data, setData] = useState({});
  const getData = () => {
    if (isNew) return;
    const url = `/admins/pub/sliders/${params.id}`;
    axios.get(url).then((res) => {
      console.log(res)
      const body = {}
      body.course = res.data?.course?._id
      body.image = res.data?.image
      body._id = res.data?._id
      body.title = res.data?.title
      
      setData(body);
    });
  };
  const AddSlider = async () => {
    const url = "/admins/pub/sliders";
    const body = { ...data };
    body.image && (body.image = await fileToBase64(body.image[0]));
    formControls
      .filter((e) => e.type === "number")
      .map((e) => e.state)
      .forEach((key) => {
        body[key] = Number(body[key]);
      });
      console.log("body : " , body)
    axios.post(url, body).then(() => {
      toast({});
      navigate(-1, { replace: true });
    });
  };
  const updateSlider = () => {
    const keys = formControls.map((e) => e.state);
    const url = "/admins/pub/sliders";
    const body = {...data}
    formControls
      .filter((e) => e.type === "number")
      .map((e) => e.state)
      .forEach((key) => {
        body[key] = Number(body[key]);
      });
    body._id = params.id;

    axios.put(url, body).then(() => {
      toast({});
    });
  };
  const removeSlider = () => {
    const url = "/admins/pub/sliders";
    const data = { _id: params.id };
    axios.delete(url, { data }).then(() => {
      toast({});
      navigate(-1, { replace: true });
    });
  };
  const formControls = [
    {
      label: "موضوع",
      state: "title",
    },
    {
      tag: Select,
      as : AdminInput,
      label: "محصول",
      state: "course",
      items: courses?.map((e) => ({ name: e?.title, id: e._id })),
    },
    {
      label: "عکس",
      state: "image",
      type: "file",
    },
  ];
  useEffect(getCourses, []);
  useEffect(getData, []);
  return (
    <Form onSubmit={isNew ? AddSlider : updateSlider} className="row">
      {formControls.map((e) => (
        <Col key={e.state} xs="12" md="6" lg="6">
          {React.createElement(e.tag ?? AdminInput, {
            ...e,
            value: data[e.state],
            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
          })}
        </Col>
      ))}
      <Col xs="12" className="d-flex flex-center">
        <ButtonGroup>
          {!isNew && (
            <Button variant="danger" onClick={removeSlider}>
              حذف
            </Button>
          )}
          <Button type="submit">ثبت</Button>
        </ButtonGroup>
      </Col>
    </Form>
  );
}
