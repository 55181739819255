const comments = [
  "آرشه عزیز ممنونم ازت بابت این آموزشای خوبت یک چیزی که بسیار جذابه تو ویدیوهات اینه که آدم میخکوب میشه وقتی صحبت میکنید یعنی آموزش پنج ساعته رو مثلا تو یه ساعت شمرده شمرده میدید جوری که قشنگ برای ادم جا میوفته...",
  "سپاس ازآموزش ارزنده تون .عالی عالی .دمتون گرم که دانش و تجربه گرانبها تون رو با ما به اشتراک گذاشتین . تو مسیرتون پرتوان و قدرتمند پیش برید 23/ بلاخره بعد مدتها یکی پیدا شد عالی ترین حالت ممکن بر اساس تجربه و علمش اموزش بده عالییی ترین هستی خواهش میکنم خواهشش میکنم ادامه بدید",
  "تکی .گلی .عزیزی .هم خودتون هم گروه بینظیرتون....ویدیو مدیریت مالی بذارید....علمی .اقتصادی ...واقعا هیچ کلمه ای برای تشکر ازشما وگروه خوبتون پیدانکردم......همچنان ارش بمون",
  "خدا قوت بهت میگم آرش عزیز،تشکر ازت به خاطر اینکه تمام تجربیاتتو در بازار بصورت مجانی در اختیار ما قرار میدی",
  "مینازیم بهت آقا آرش عزیز همیشه و همیشه منتظر اطلاعاتتون بودیم و با دل و جون نشستیم و گوش دادیم و عمل کردیم و همیشه موفق بودیم همیشه دیدمون رو باز تر کردی تو هر زمینه ای و ماهیگیری رو یاد دادی بهمون داداش ایشالله خدا هر چی میخوای بهت بده اصالت خریدنی نیست و تو همیشه با اصالت بودی و افتخار مایی بتازون",
  " چقدر این آموزش ها بار علمی زیادی داره خیلی خوبه دم از تیم وزین مارول گرم آقا ارش ترکوندی همینطوری برو جلو که فقط امیدمون به شماست",
  " واقعا خیلی ممنونم استاد آرش که تجارب غنیتون رو که سالیان سال زحمت کشیدین رو در اختیار ما قرار میدین از بخت خوب ماست با شما همراه بودن",
  " سلام ویدیو مدیریت مالی بذارید .وعلمی ..خیلی ممنون از شما وتیم بینظیرتون.دست تک تکتونو میبوسم که در راه اگاهی اونم در چنین محیطی قدم گذاشتید واقعا جای تشکر داره..همچنان ارش بمون",
  " خداقوت بهت بده آرش جان.....دمت گرم که آگاهی میدی و اطلاعات رایگان...امیدوارم به عهدی که با خودت بستی تو اون شب سخت برسی...امیدوارم دیگه غم نبینی و دلت شاد باشه",
  "رودستت نیست نبود نخواهد اومد ،عشق ،سلطان،دلسوز،تریدر برتر،مدرس فوق عالی،دانشمند،مهربون خیلی ازت ممنونیم که با اون همه مشغله واسه ماها وقت گذاشتید بی منت و بااخلاص،خدا بهت خیری عطا کنه که قبل و بعدت به کسی نداده باشه خورشید ایران",
].map((e) => ({ comment: e }));
export default comments;
