import React, { useState, useEffect } from 'react'
import { Table, InstallmentCard } from '../../../components'
import { scrollToTop } from "../../../methods";
import { axios, moment } from "../../../boot";
import { Col } from "react-bootstrap";
import { installmentStatus } from "../../../constants";
import { TbMoodSadDizzy } from "react-icons/tb";
import empty from '../../../assets/images/empty.png'
const MyInstallments = () => {
  const [data, setData] = useState([])
  const [myInstallments, setMyInstallment] = useState([])
  const [id, setId] = useState("")
  const getInstallments = () => {
    const url = "/pub/shop/purchasesinstallment";
    
    axios.get(url).then(({ data }) => {
      const res = data?.data?.map((e) => ({ _id: e?._id, title: e?.items[0]?.course?.title }))
      if (res?.length > 0) {
        getUserInstallments(res[0]?._id)
        setId(res[0]?._id)
      }
      setMyInstallment(res)
      scrollToTop();
    });
  };
  const showStatus = (id = 0) => {
    const { color, name } = installmentStatus.find((e) => e.id === id) ?? {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };
  const verifyPayment = () => {
    const url = "/purchases/newpayinstallment"
    const body = {
      _id: data?._id
    }
    axios.post(url, body).then(({ data }) => {
      const link = data.url;
      window.location.href = link;
    })
  }
  const getUserInstallments = (id) => {
    setData([])
    const url = "/shop/newinstallments"
    const body = {
      firstPurchase: id
    }
    axios.post(url, body).then(({ data }) => {
      setData(data)
    })
  }
  useEffect(getInstallments, [])

  if(myInstallments?.length == 0) {
    return (
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <img src={empty} alt='empty' width={200} height={200} /> 
        <h2 className="text-white my-4">برای شما اقساطی ثبت نشده </h2>
      </div>
    )
  }
  return (
    <div className='p-2'>
      <div style={{ minHeight: "80px" }} className="row my-4 flex justify-content-start">
        <Col xs="12" lg="12"><p>دوره مورد نظر را انتخاب کنید : </p></Col>
        {myInstallments?.map((e, i) =>
          <Col xs="12" md="6" xl="4" key={`key-installment-${i}`}>
            <button onClick={() => {  setId(e?._id);getUserInstallments(e?._id) }} className={`btn ${e?._id == id ? "btn-white" : "btn-outline-white"} w-100`}>{e?.title}</button>
          </Col>
        )}
        <Col className='d-flex justify-content-end my-2' xs="12">
          <button onClick={() => verifyPayment()} className="btn btn-primary">پرداخت قسط بعدی</button>
        </Col>
      </div>
      <div className="d-lg-none">
        <p className="text-white my-4 fs-20 text-center fw-500">اقساط من</p>
        {data?.installments?.map((e, index) =>
          <InstallmentCard key={index} myInstallments={data?.installments} index={index} {...e} />
        )}
      </div>
      <Table className="d-none d-lg-table">
        <thead>
          <tr>
            <th>شماره قسط</th>
            <th>مبلغ قسط</th>
            <th>تاریخ سررسید</th>
            <th>وضعیت</th>
            {/* <th><button onClick={()=>verifyPayment(myInstallments?._id)} className="btn btn-primary">پرداخت قسط</button></th> */}
          </tr>
        </thead>
        <tbody>
          {data?.installments?.map((e, i) => (
            <tr key={`table-installment-${e._id}`} className='py-2'>
              <td className="text-white fw-700">
                {e?.installmentNumber}
              </td>
              <td className="text-white">{`${e?.amount?.toLocaleString()} تومان`}</td>
              <td className="text-white">
                <span dir="ltr">
                  {moment.miladiToShamsi({
                    date: e?.dueDate,
                    format: "jYYYY/jMM/jDD",
                  })}
                </span>
              </td>
              <td>{showStatus(e?.status)}</td>
              {/* <td></td> */}
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <Pagination
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      /> */}
      {/* {params.page < pages.totalPages && (
          <button
            className="d-block mx-auto w-fit text-primary"
            onClick={() => {
              setParams((p) => ({ ...p, page: p.page + 1 }));
            }}
          >
            نمایش بیشتر...
          </button>
        )} */}
    </div>
  )
}

export default MyInstallments