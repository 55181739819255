import React, { useState, useEffect } from 'react'

import { useNavigate, useParams } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import { Col, ButtonGroup, Row } from "react-bootstrap";
import { rules, roles, accept } from "../../../constants";
import { fileToBase64, toast } from "../../../methods";
import { Form, AdminInput, Select, Button } from "../../../components";
import { axios } from "../../../boot";
import UserDetails from './UserDetails';

const Group = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});

    const isNewGroup = params.id === "new";
    const addGroup = () => {
        const url = "/admins/classgroup"
        axios.post(url, data).then(({ data }) => {
            const text = "گروه با موفقیت ایجاد شد"
            toast({ text })
            navigate(-1)
        })
    }
    const changeGroup = () => { }
    const deleteGroup = () => {
        const message = "آیا از درخواست خود اطمیمان دارید؟";
        if (window.confirm(message)) {
            const url = "/admins/classgroup";
            const body = { _id: params.id };
            axios.delete(url, { data: body }).then(() => {
                toast({});
                navigate(-1);
            });
        }
    }
    const getGroup = () => {
        if (!isNewGroup) {
            const url = `/admins/classgroup/${params.id}`;
            axios.get(url).then(({ data }) => {
                setData(data);
            });
        }

    }
    const formControls = [
        {
            state: "name",
            label: "اسم گروه"
        }
    ]

    /////////////////////// One User

    const formControls2 = [
        {
            state: "firstName",
            label: "نام"
        },
        {
            state: "lastName",
            label: "نام خانوادگی"
        },
        {
            state: "phone",
            label: "شماره تماس"
        },
    ]

    const addOneUser = () => {
        const url = "/admins/adduserclassgroup"
        const body = {}
        body.firstName = data?.firstName
        body.lastName = data?.lastName
        body.phone = data?.phone
        body._id = params.id
        axios.post(url, body).then(({ data }) => {
           
            let text = "کاربر با موفقیت اضافه شد"
            toast({ text })
            getGroup()
            setData((p) => ({ ...p, ["firstName"]: "", ["lastName"]: "", ["phone"]: "" }))
        })
    }

    /////////////////////// one User

    ////////////////////// group of people
    const formControls3 = [
        {
            state: "file",
            type: "file",
            label : "فایل اکسل کاربران",
            placeholder : "فایل اکسل را آپلود کنید",
            accept: accept.every,
        }
    ]
    const files = ["xlsx","xls"];
    const addExcel = async () => {
        const url = "/admins/addexceluserGroup"
        const dataa = {}
        dataa.file = data?.file
        const body = new FormData();
        body.append("file",data["file"][0]);
        body.append("_id",data?._id)
        axios.post(url, body).then(() => {
            toast({});
            getGroup()
          });
    }
    

    ///////////////////// group of peaple


    useEffect(getGroup, [])
    return (
        <>
            <h5 className="text-center col-12">
                {isNewGroup ? "اضافه کردن گروه جدید" : `اطلاعات گروه ${data?.name}`}
            </h5>
            {isNewGroup &&
                <Form onSubmit={addGroup} className="row">

                    {formControls.map((props, index) => (
                        <Col key={index} xs="12" md={index === 3 ? "12" : "6"}>
                            {React.createElement(props.tag ?? AdminInput, {
                                ...props,
                                value: data[props.state],
                                setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
                            })}
                        </Col>
                    ))}
                    <div className="col-12 d-flex flex-center">
                        <ButtonGroup>
                            <Button type="submit">ثبت</Button>
                        </ButtonGroup>
                    </div>
                </Form>
            }

            {!isNewGroup &&
                <>
                    <h5 className="text-center my-4">اضافه کردن کاربر به صورت تکی</h5>
                    <Form className="row my-4" onSubmit={addOneUser}>
                        {formControls2.map((props, index) => (
                            <Col key={index} xs="12" md="6">
                                {React.createElement(props.tag ?? AdminInput, {
                                    ...props,
                                    value: data[props.state],
                                    setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
                                })}
                            </Col>
                        ))}
                        <div className="col-12 d-flex flex-center">

                            <Button type="submit">اضافه کردن کاربر جدید</Button>

                        </div>
                    </Form>
                    <hr />

                    <h5 className="text-center my-4">اضافه کردن گروهی کاربران</h5>
                    <Form className="row my-4" onSubmit={addExcel}>
                        {formControls3.map((props, index) => (
                            <Col key={index} xs="12" md="6">
                                {React.createElement(props.tag ?? AdminInput, {
                                    ...props,
                                    value: data[props.state],
                                    setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
                                })}
                            </Col>
                        ))}

                        <div className="col-12 d-flex flex-center">

                            <Button type="submit">اضافه کردن کاربر جدید</Button>

                        </div>
                    </Form>
                    <hr />

                    <UserDetails data={data?.users} _id={params.id} updatefunc={getGroup} />

                    <div className="W-100 flex-center my-4 ">
                        <Button variant="danger" onClick={() => deleteGroup()}>
                            حذف کلی گروه
                        </Button>
                    </div>

                </>
            }



        </>
    )
}

export default Group