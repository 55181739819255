import React from 'react'
import { BiSupport } from "react-icons/bi";
import './index.scss'
const SupportBtn = () => {
  return (
    <a title="پشتیبانی تلگرام" target='_blank' href='https://t.me/academymarvel' className='SupportBtn d-flex justify-content-center align-items-center p-2'>
        <BiSupport color='blue' size="20" />
    </a>
  )
}

export default SupportBtn