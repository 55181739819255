import { useState } from 'react'

import { Accordion, Col, Container, Row } from "react-bootstrap";
import { questions } from "../../../../constants";
import StudentComments from '../StudentComments';

const Question = ({ data = [], comments = [] }) => {
  const [show, setShow] = useState("")
  return (
    <Container id="question" className="QuestionFreeTutorial">
      {comments?.length > 0 && <StudentComments data={comments} />}
      {data?.length > 0 &&
        <>
          <p className='text-white fw-700 fs-20 my-1 mx-4'>سوالات متداول</p>
          <hr className='' />
          <Row>
            <Col xs={12} lg={12}>
              <Accordion className={`w-100 d-flex flex-column Accordion flex-center row-gap-4 my-4`}>
                {data.map((item, index) => (
                  <Accordion.Item
                    key={index}
                    eventKey={index}
                    className={`rounded-10 overflow-hidden shadow  w-100 ${show == item._id ? "AccordionBody" : "bg-raisin"}`}
                  >
                    {/* <BAccordion.Header className="bg-info">{title}</BAccordion.Header> */}
                    <Accordion.Button onClick={() => {
                      if (show.length == 0) {
                        setShow(item._id)
                      } else {
                        setShow("")
                      }
                    }} className={` text-start border-0   fw-700 fs-14 ${show == item._id ? "AccordionTitle bg-transparent text-primary" : "bg-dark text-white"}  `}>
                      {item.title}
                    </Accordion.Button>

                    <Accordion.Body className="transition   ">
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </>
      }
    </Container>
  )
}

export default Question