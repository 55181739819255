const priorityValues = [
  {
    name: "خیلی پایین",
    id: -2,
  },
  {
    name: "پایین",
    id: -1,
  },
  {
    name: "متوسط",
    id: 0,
  },
  {
    name: "بالا",
    id: 1,
  },
  {
    name: "خیلی بالا",
    id: 2,
  },
];
export default priorityValues;
