const notificationsPriority = [
  {
    name: "پایین",
    id: "low",
    color: "success",
  },
  {
    name: "متوسط",
    id: "medium",
    color: "warning",
  },
  {
    name: "بالا",
    id: "high",
    color: "danger",
  },
];
export default notificationsPriority;
