import React, { useCallback, useEffect, useState } from 'react'

import { ButtonGroup, Col } from 'react-bootstrap'
import { AdminInput, Button, Form, Select } from '../../../components'
import { axios, moment } from '../../../boot'
import { toast } from '../../../methods'
import { useGetGroups } from '../../../hooks'
import LiveSms from './LiveSms'
import DelaySms from './DelaySms'
import CancelSms from './CancelSms'
import SendSms from './SendSms'

const GroupMessage = () => {
    const [activeTab, setActiveTab] = useState("sendSms")
    const [groups, getGroups] = useGetGroups()
    useEffect(getGroups, [])
    let types = [
        {
            name: "ارسال پیامک",
            id: "sendSms"
        },
        {
            name: "ارسال پیامک تاخیر",
            id: "delaySms"
        },
        {
            name: "پیامک فوری لایو",
            id: "liveSms"
        },
        {
            name: "ارسال پیامک کنسلی",
            id: "cancel"
        },
    ]
    const handleTitle = {
        cancel: "ارسال پیامک کنسلی",
        sendSms: "ارسال پیامک",
        delaySms: "ارسال پیامک تاخیر",
        liveSms: "ارسال پیامک لایو"
    }
    return (
        <div>
            <div className="d-flex flex-center my-4">
                <ButtonGroup>
                    {types.map((e) => (
                        <Button
                            key={e.id}
                            outline={e.id !== activeTab}
                            onClick={() => { setActiveTab(e.id)}}
                        >
                            {e.name}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>
            {activeTab === "liveSms" && <LiveSms groups={groups} />}
            {activeTab === "delaySms" && <DelaySms groups={groups} />}
            {activeTab === "cancel" && <CancelSms groups={groups} />}
            {activeTab === "sendSms" && <SendSms groups={groups} />}
        </div>
    )
}

export default GroupMessage