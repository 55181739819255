import React from "react";
import { Col, Row } from "react-bootstrap";
import { useGetSenderInfo } from "../../hooks";
import { convertPhone } from "../../methods";
import { moment } from "../../boot";
import { paymentMethods } from "../../constants";
import './index.scss'
const Print = (info = []) => {

    // const [sendere] = useGetSenderInfo();
    const reciver = (item) => [
        {
            label: "نام و نام خانوادگی",
            value: `${item?.shipping?.firstName ? item?.shipping?.firstName : item?.user?.firstName} ${item?.shipping?.lastName ? item?.shipping?.lastName : item?.user?.lastName}`,
        },
        {
            label: "نشانی",
            value: `${item?.shipping?.address ? item?.shipping?.address : ""}`,
        },
        {
            label: "کد پستی",
            value: item?.shipping?.postCode ? item?.shipping?.postCode : "",
        },
        {
            label: "تلفن",
            value: item?.shipping?.phone ? item?.shipping?.phone : item?.user?.phone,
        },
    ];
    const showPaymentMethod = (id = 0) => {
        return paymentMethods.find((e) => e.id === id)?.name;
    };
    return (
        <div style={{ height: "auto" }} className="d-none d-print-block w-100 PRINT">
            <p className="my-4 fs-20 fw-500">برچسب پستی و فاکتور</p>
            <div className="PRINT-body">
                {info?.info?.map((e, index) =>
                    <div className="w-100" style={{marginBottom :"90%"}} key={index}>
                        <table bordered={true} className="border w-100 mainTable">
                            <thead>
                                <tr>
                                    <th>گیرنده</th>
                                    <th>فرستنده</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>شناسه ثابت : 9363693 </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div>
                                            {reciver(e)?.map((i, index) =>
                                                <Row key={index}>
                                                    <Col>{i.label}</Col>
                                                    <Col>{i.value}</Col>
                                                </Row>
                                            )}
                                        </div>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                        <table className="border w-100 my-4">
                            <thead>
                                <tr>
                                    <th>شرح کالا یا خدمات</th>
                                    <th>تعداد</th>
                                    <th>مبلغ کل</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{e?.items?.map((e) => e.course?.title ? e.course?.title : e.product?.title_fa).join(" + ")}</td>
                                    <td>{e?.items?.length}</td>
                                    <td>{e?.totalPrice}</td>
                                </tr>
                                <tr>
                                    <td> روش پرداخت : </td>
                                    <td colSpan="2">{showPaymentMethod(e?.paymentMethod)}</td>
                                </tr>
                                <tr>
                                    <td>مجموع مبلغ نهایی</td>
                                    <td colSpan="2">{`${(
                                        e?.totalPrice - (e?.offPrice ?? 0)
                                    ).toLocaleString()} تومان - ${e?.totalPriceUsd - (e?.offPriceUsd ?? 0)
                                        } تتر`}</td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Print