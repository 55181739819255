import React from 'react'
import { Link } from "react-router-dom";
import './index.scss'
const FreeTutorialsCard = ({
    id,
    img,
    name
}) => {
    return (
        <Link to={id} className='w-100 FreeTutorialsCard '>
            {img ? (
                <img
                    
                    className="FreeTutorialsCard-img"
                    src={img}
                    alt={name}
                />
            ) : (
                <i
                    style={{
                        width: "100%",
                        height: "178px",
                    }}
                    className="bi bi-journal-bookmark-fill text-white fs-2 bg-info bg-opacity-75 rounded-circle d-flex flex-center"
                />
            )}
            <div className="FreeTutorialsCard-title d-flex flex-center">
                <p className="text-center text-white fs-12 fw-700">{name}</p>
            </div>
        </Link>
    )
}

export default FreeTutorialsCard