import React, { useContext, useState } from 'react'
import { Button, Form, Input } from '../../../components'
import { Col } from 'react-bootstrap'
import { convertPhone, toast } from '../../../methods'
import { axios } from '../../../boot'
import logo from '../../../assets/logos/Marvel academy.svg'
import Context from '../_context'
import { rules } from '../../../constants'
const ForgotPass = () => {
    const [page, setPage] = useContext(Context)
    const [data, setData] = useState({})
    const [activePage, setActivePage] = useState("phone")
    const phoneControl = [
        {
            label: "شماره تلفن یا ایمیل",
            state: "phone",
            labelColor: "light-gray",
            border: false,
            // onKeyDown: (e) => {return (e.keyCode === 13 ? handleOnSubmit() : null) }

        }
    ]
    const passwordControl = [
        {
            label: "کد تایید",
            state: "code",
            labelColor: "light-gray",
            border: false,
        },
        {
            label: "رمزعبور جدید",
            state: "password",
            type: "password",
            labelColor: "light-gray",
            border: false,
            rules : rules.password
        },
        {
            label: "تکرار رمزعبور جدید",
            state: "repeatPass",
            type: "password",
            labelColor: "light-gray",
            border: false,
            rules : rules.password,
            // onKeyDown: (e) => {return (e.keyCode === 13 ? handleOnSubmit() : null) }
        },
    ]
    const formControls = {
        phone: phoneControl,
        pass: passwordControl
    }
    const sendCode = () => {
        let url = ""
        let text = ""
        let reg = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        if (reg.test(data.phone)) {
            url = `/users/${data.phone}/resetPasswordEmail`
            text = `کد به ایمیل ${data?.phone} ارسال شد`
        } else {
            url = `/users/${convertPhone(data.phone)}/resetPasswordSms`
            text = `کد به شماره ${data?.phone} ارسال شد`
        }
        axios.get(url).then(() => {
            toast({ text })
            setActivePage("pass")
        })
    }
    const changePass = () => {
        let body = {}
        let reg = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
        if (reg.test(data.phone)) {
            body.phone = data?.phone
            body.password = data?.password
        } else {
            body.phone = convertPhone(data?.phone)
            body.password = data?.password
        }
        const url = `/users/${data.code}/changePassword`
        if (data?.password != data?.repeatPass) {
            let text = "رمزعبور را درست وارد کنید"
            return toast({ type: "error", text })
        }

        axios.post(url, body).then(({ data }) => {
            let text = "رمز عبور با موفقیت تغییر کرد"
            toast({ text })
            setPage("signIn")
        })
    }
    const handleOnSubmit = () => {
        if(activePage == "phone"){
            return sendCode()
        }else{
            return changePass()
        }
    }

    return (
        <Form
            onSubmit={handleOnSubmit}
            className="SIGNIN row py-2 d-flex flex-column align-items-center justify-content-center ">

            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <img style={{ width: "115px", height: "93px" }} className="" src={logo} alt="logo" />
                <p className=" my-2 col-12 text-primary text-center fs-25 fw-700 my-1">فراموشی رمزعبور</p>
            </div>
            {formControls[activePage].map(item =>
                <Col key={item.state} xs="12">
                    <Input
                        className="bg-dark-black text-white rounded-4 border-dark-block fs-14 fw-400"
                        {...item}
                        value={data[item.state]}
                        setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
                    />
                </Col>
            )}
            <Col>
                <Button className="w-100 mt-4 rounded-0 py-2" type="submit">
                    {activePage === "phone" ? "ارسال کد" : "تغییر رمز عبور"}
                </Button>
                <button type='button' className="text-white bg-transparents fs-14 my-4" onClick={() => setPage("signIn")}>باز گشت به صفحه ورود</button>
            </Col>
        </Form>
    )
}

export default ForgotPass