import React, { useEffect, useState } from "react";

import { Col } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, Button, AdminInput } from "../../../components";
import { rules, rolesPath } from "../../../constants";
import { axios } from "../../../boot";
import { useQuery, useSetToken } from "../../../hooks";
import { convertPhone, toast } from "../../../methods";
import Profile from "../../UsersPanel/Profile";
import { PrintModal } from "../../../components";
import TermsConditions from "../../../components/TermsCondition";

const AccountantLogin = () => {
  const navigate = useNavigate();
  const setToken = useSetToken();
  const [params] = useSearchParams();
  const phone = params.get("phone");
  const [data, setData] = useState({ phone });

  const formControls = [
    {
      label: "شماره تماس",
      state: "phone",

    },
    {
      label: "کلمه عبور",
      state: "password",
      type: "password",
      rules: rules.password,
    },
  ];
  const loginHandler = () => {
    const url = "/users/signInWithPhone"
    const body = {}
    body.phone = convertPhone(data.phone)
    body.password = data.password
    axios.post(url, body).then(({ data }) => {
      if (data?.role === "6e5KXjhLJ") {
        const token = data?.token
        const role = data?.role;
        // const phone = body.phone.slice(1)
        // console.log(phone)
        localStorage.setItem("phone" , body.phone.slice(1))
        setToken({ token, role });
        navigate(rolesPath[role] ?? "/", { replace: true });
      }else{
        const text = "حساب شما دارای دسترسی نمیباشد"
        return toast({ text, type: "error" })
      }
    })
  }
  return (
    <Form onSubmit={loginHandler} className="row">
      <h1 className="h4 lh-normal m-0 col-12 text-primary">خوش آمدید</h1>
      {formControls.map((item) => (
        <Col key={item.state} xs="12">
          <AdminInput
            {...item}
            value={data[item.state]}
            setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
          />
        </Col>
      ))}
      <Col xs="12">
        <Button type="submit" className="w-100">
          ورود
        </Button>
      </Col>

    </Form>
  )
}

export default AccountantLogin