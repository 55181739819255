import React, { useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap'
import { offCal, toast } from '../../methods';
import Badge from '../Badge'
import Button from '../Button';
import { useNavigate, useParams } from "react-router";
import { useSelector } from 'react-redux';
// import { useAddToCart, useProductStorage } from '../../hooks';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Cash = ({
    data = {},
    info = {}
}) => {
    const navigate = useNavigate()
    const params = useParams()
    // const addToCart = useAddToCart();
    const isLogged = useSelector((s) => s.isLogged);
    const hasOff = data?.off !== 0;
    const hasOffUds = data?.offUsd !== 0;
    const totalPrice = offCal(data?.price, data?.off).toLocaleString();
    const totalPriceUsd = offCal(data?.priceUsd, data?.offUsd);
    // const productStorage = useProductStorage();
    const { firstName, lastName, codeMelli } = info
    const addCourseToCart = () => {
        let text = ""
        // if(data?.totalSold == 3000){
        //     text = "ظرفیت خرید دوره به اتمام رسیده است." 
        //     toast({text , type : "error"})
        //     return
        // }
        if (isLogged) {
            let body = {...data}
            body.buyInstallment = false
            navigate("/order" , {state : body})
        } else {
            text = "برای ثبت خرید لطفا ابتدا وارد سایت شوید"
            return toast({ type: "error", text })
        }
        // productStorage.set(data);
    };
    return (
        // <AnimationOnScroll animateIn='animate__fadeInUp animate__faster '>
        //     <Container>
                <button disabled={!data?.available} onClick={addCourseToCart} className="CASH btn btn-primary my-2 d-flex align-items-center justify-content-between shadow-lg px-2">
                    <p className='m-2 fw-700 fs-20 text-white'>نقدی</p>
                    {totalPriceUsd !== 0 &&
                        <div className="d-flex flex-column flex-center">
                            {hasOffUds && (
                                <h6 className="text-secondary">
                                    <span className="text-decoration-line-through">
                                        {data?.priceUsd} تتر
                                    </span>{" "}
                                    <Badge variant="danger" label={`${data?.offUsd}%`} />
                                </h6>
                            )}
                            <h5 className="text-white fw-700">{totalPriceUsd} تتر</h5>
                        </div>
                    }
                    <div className="d-flex flex-column flex-center">
                        {hasOff && (
                            <h6 className="text-secondary">
                                <span className="text-decoration-line-through">
                                    {data?.price?.toLocaleString()} تومان
                                </span>{" "}
                                <Badge variant="danger" label={`${data?.off}%`} />
                            </h6>
                        )}
                        <h5 className="text-white fw-700">{totalPrice} تومان</h5>
                    </div>
                    <i className="bi bi-caret-left-fill"></i>
                    {/* <div className="flex-center p-1">
                        <Button disabled={!data?.available} onClick={addCourseToCart} size='sm' variant='primary' label="افزودن به سبد خرید" />
                    </div> */}

                </button>

    )
}

export default Cash