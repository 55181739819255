import { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { axios } from '../../../boot'
import { Table, Button } from '../../../components'
import { convertPhone, toast } from '../../../methods'
const UserDetails = ({ data , _id , updatefunc = () => {}}) => {
    const [id, setId] = useState([])
    const selectAll = () => {
        if (id.length < data?.length) {
            setId((p) => data?.map(val => val._id))
        } else {
            setId([])
        }

    }
    const handleChecked = (event) => {
        let checkbox = document.getElementById(event).checked;
        let data = id
        setId([])
        if (checkbox) {
            data.push(event)
        } else {
            if (data.length > 0) {
                const a = data.indexOf(event);
                data.splice(a, 1)
            }
        }
        setId([...data])
    }

    const deleteUser = () => {
        const url = "/admins/userclassgroup"
        const body = { _id: _id , userid : id.toString() };
        
        axios.delete(url , {data : body}).then(({data})=>{
            const text = "کاربر با موفقیت حذف شد"
            toast((text))
            updatefunc()
            setId([])
        })
    }
    return (
        <>
            <h5 className="text-center my-4">لیست کاربران</h5>
            <Row className="d-print-none my-4">
                <Col><Button variant="info" onClick={selectAll}>{id?.length == 0 ? "انتخاب کل" : "حذف کل"}</Button></Col>
                <Col className="d-flex justify-content-end"><Button variant="danger" onClick={deleteUser}>{`حذف ${id?.length > 0 ? id?.length : ""} کاربر`}</Button></Col>
                
                
            </Row>

            <Table className="d-print-none">
                <thead>
                    <tr>
                        <th>نام و نام خانوادگی</th>
                        <th>شماره تلفن</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((e, i) => (
                        <tr key={e._id}  >
                            <td>
                                {e.firstName} {e.lastName}
                            </td>
                            <td>{e.phone}</td>
                            <td>
                                <Form>
                                    <Form.Check
                                        type="checkbox"
                                        value={e._id}
                                        id={e._id}
                                        checked={id.find(p => p == e._id) ? true : false}
                                        onChange={() => { handleChecked(e._id) }}
                                    />
                                </Form>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <hr />
        </>
    )
}

export default UserDetails