const adviceStatus = [
    // {
    //   name: "همه",
    //   id: "",
    //   color: "warning",
    //   filterable: true,
    // },
    {
      name: "رزرو شده",
      id: "saled" ,
      color: "success",
      isUpdateStatus: true,
      filterable: true,
    },
    {
      name: "در حال رزرو",
      id: "selling" ,
      color: "warning",
      isUpdateStatus: true,
      filterable: true,
    },
    {
      name: "قابل رزرو",
      id: "salable",
      color: "primary",
      isUpdateStatus: true,
      filterable: true,
    },
  ];
  export default adviceStatus;
  