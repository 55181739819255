import { useState, useEffect, useRef } from 'react'
import { axios } from '../../../../boot'
import NewTopicCard from '../../../../components/NewVersion/NewTopicCard'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles.css";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
const AnimateCource = () => {
  const [data, setData] = useState([])
  const getData = () => {
    const url = "/pub/sliders"
    axios.get(url).then(({ data }) => {
      const body = data.data.map((e) => ({ _id: e?.course?._id, title: e.title, image: e.image, lessons: e?.course?.numberOfSessions, courseTitle: e?.course?.title }))
      setData([...body])
    })
  }
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5500,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const settings2 = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  useEffect(getData, [])
  return (

    <div className="w-100 AnimationCourse relative">
      <div className="AnimationCourse-header p-4">
        <p className="text-white fw-900 fs-40">از بهترین ها بیاموزید <br/> بهترین خود باشید</p>
        <p className="text-white my-4">دسترسی به ده ها دوره ی آموزشی</p>
      </div>
      <Swiper
        className="swiper sample-slider w-100"
        breakpoints={{
          0:{
            slidesPerView:1,
          },
          639: {
            slidesPerView: 2,
          },
          865:{
            slidesPerView:2
          },
          1000:{
            slidesPerView:3
          },
          1500:{
            slidesPerView:3
          },
          1700:{
            slidesPerView:4
          }
        }}
        spaceBetween={0}
        slidesPerView={3}
        modules={[Autoplay]}
        autoplay={{
          delay: 0, pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        loop={true}
        speed={6000}
      >
        {data.map((e) =>
          <SwiperSlide key={`first-slider-${e._id}`}>
            <NewTopicCard showTitle={false} className=""   {...e} />
          </SwiperSlide>
        )}
      </Swiper>

      <Swiper
        className="sample-slider w-100"
        breakpoints={{
          0:{
            slidesPerView:1,
          },
          639: {
            slidesPerView: 2,
          },
          865:{
            slidesPerView:2
          },
          1000:{
            slidesPerView:3
          },
          1500:{
            slidesPerView:3
          },
          1700:{
            slidesPerView:4
          }
        }}
        spaceBetween={0}
        slidesPerView={3}
        modules={[Autoplay]}
        autoplay={{
          delay: 0, pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        loop={true}
        speed={6500}
      >
        {data.map((e,index) =>
          <SwiperSlide key={`second-slider-${e._id}`}>
            <NewTopicCard showTitle={false} className=""   {...e} />
          </SwiperSlide>
        )}
      </Swiper>
    </div >
  )
}

export default AnimateCource