import { Outlet } from "react-router";
import Header from "./Header";
import Footer from "./Footer";
import "./index.scss";
import CartCountProvider from "../../contexts/CartCount";
import SupportBtn from "../../components/SupportBtn.js";
export default function Landing() {
  return (
    <CartCountProvider>
      <div className="Landing w-100 ">
        <Header />
        <main className="py-2 position-relative">
          <Outlet />
          <SupportBtn/>
        </main>
        <Footer />
      </div>
    </CartCountProvider>
  );
}
