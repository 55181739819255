import { useState } from 'react'

import { Accordion, Col, Container, Row } from "react-bootstrap";
import { questions } from "../../../constants";
import { Link } from "react-router-dom";
import MarvelLogo from '../../../assets/images/Home/marvelLogo.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Collapse from '../../../components/NewVersion/Collapse';

export default function Services() {
  const [show, setShow] = useState("")

  return (
    <Container className="Services d-flex flex-column flex-center text-center row-gap-4 position-relative my-2">
      <div className="services-logo">
        <img src={MarvelLogo} alt="marvel-logo" />
      </div>

      {/* <h4 className="text-info">ما حرفه‌ای این زمینه هستیم</h4>
      <p className="white-space-pre-wrap lh-md">
        مهم نیست که دنبال چه چیزی هستید، ما میتوانیم قابل اعتمادترین
        {"\n"} روش‌های کسب درآمد را به شما آموزش دهیم
      </p>
      <Row>
        {services.map((item, index) => (
          <Col key={index} xs="9" sm="6" lg="3">
            <ServiceCard {...item} />
          </Col>
        ))}
      </Row> */}
      <Row className="align-items-start mt-4">
        <Col
          xs="12"
          lg="6"
        >
          <AnimationOnScroll className="d-flex flex-column align-items-start " animateIn='animate__zoomIn animate__faster '>
            <p className="text-primary fs-14 fw-700">خدمات ما</p>
            <h4 className="text-white fs-24 fw-700 my-2">سوالات متداول</h4>
            <p className="text-start fs-14 fw-400 text-white lh-md my-2">
             در مدرسه مارول با هم یاد می گیریم، چگونه بااعتماد بیشتری سرمایه گذاری کنیم تا سود بیشتری کسب کنیم. علاقۀ شما و آموزش های ما منجر به سودآوری بیشتر شما در بازارهای  مالی خواهد شد. اگر سؤالی در زمینۀ آموزش های ما دارید می توانید به راحتی پاسخ آن را در سایت بیابید، در غیر این صورت می توانید مستقیماً با ما در ارتباط باشید تا پاسخگوی سؤالات شما عزیزان باشیم.
            </p>
            <Link style={{ width: "200px", height: "39px" }} to="/tutorials" className="btn btn-primary Button font-sans fs-14 my-2  fw-500">
              شروع یادگیری
            </Link>
          </AnimationOnScroll>
        </Col>
        <Col xs="12" lg="6">

          <Accordion className={`w-100  Accordion   d-flex flex-column flex-center row-gap-4`}>
            {questions.map((item, index) => (
              <Accordion.Item
                key={index}
                eventKey={index}
                className={`rounded-10 overflow-hidden border-0 w-100  ${show == item.id ? "AccordionBody" : "bg-dark"}`}
              >
                {/* <Accordion.Header  onClick={() => setShow(p => p = !p)} className={` text-start border-0 w-100  text-white fw-700 fs-14 bg-dark `}>{item.title}</Accordion.Header> */}
                <Accordion.Button onClick={() => {
                  if (show.length == 0) {
                    setShow(item.id)
                  } else {
                    setShow("")
                  }

                }} className={`btn text-start border-0  text-white fw-700 fs-14 ${show == item.id ? "bg-transparent" : "bg-dark"}  `}>
                  {item.title}
                </Accordion.Button>

                <Accordion.Body className="transition text-primary  ">
                  {item.text}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}
