import React from "react";
import { NavLink } from "react-router-dom";
// import logo3 from "../../assets/logos/logo3.png";
// import logoText from "../../assets/logos/logo text2.png";
import logo from '../../assets/logos/Marvel academy.svg'

import { navItems } from "../../constants";
import { logout } from "../../methods";
import SupportSection from "./SupportSection";

export default function MenuContent() {
  return (
    <React.Fragment>
      <img width={80} src={logo} alt="logo" />
      <ul className="w-100 px-2 my-4">
        {navItems.user.map((item, index) => (
          <li key={index} className="w-100 my-2">
            <NavLink
              to={item.path}
              className="d-flex align-items-center rounded p-2 col-gap-3 w-100 text-truncate fs-18"
            >
              {/* <i
                className={`d-flex flex-center rounded fs-5 bi bi-${item.icon}`}
              /> */}
              <span>{item.label}</span>
            </NavLink>
          </li>
        ))}
       

      </ul>

      <ul className=" w-100 px-2 my-4 logoutControl">
        <li>
          <NavLink
            to='/'
            className="d-flex align-items-center rounded p-2 col-gap-3 w-100 text-truncate fs-18"
          >
            <span>بازگشت به وبسایت</span>
          </NavLink>
        </li>
        <li>
          <button
            onClick={() => logout()}
            className="d-flex align-items-center rounded p-2 col-gap-3 w-100 fs-18"
          >
            <span className="text-danger">خروج از حساب</span>
          </button>
        </li>
      </ul>
      {/* <SupportSection /> */}
    </React.Fragment>
  );
}
