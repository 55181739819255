import { useState } from "react";
import { axios } from "../boot";

export default function useGetTeacher() {
  const [teachers, setTeachers] = useState([]);
  const getTeachers = () => {
    const url = "/admins/pub/shop/teachers";
    axios.get(url).then((res) => {
      const data = res.data.data.map((e) => ({ ...e, isAdmin: true }));
      
      setTeachers(data);
    });
  };
  return [teachers, getTeachers];
}
