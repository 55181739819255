import React from 'react'
import { moment } from '../../boot'
import { paymentMethods, purchaseStatus } from '../../constants'
import { Col, Row } from 'react-bootstrap';
const OrderCard = ({
    refId,
    items = [],
    createdAt,
    totalPrice,
    paymentMethod,
    voucher,
    status
}) => {
    const calCount = (items) => {
        let sum = 0
        for (let e of items) {
            sum = sum + e?.count
        }
        return sum
    }
    const showStatus = (id = 0) => {
        const { color, name } = purchaseStatus.find((e) => e.id === id) ?? {};
        return <span className={`text-${color} fw-bold`}>{name}</span>;
    };
    const data = [
        {
            label: "شماره پیگیری",
            value: refId
        },
        {
            label: "محصولات",
            value: items?.map((e) => e.course ? e?.course?.title : e?.product?.title_fa).join(" - ")
        },
        {
            label: "تعداد",
            value: calCount(items)
        },
        {
            label: "تاریخ",
            value: moment.miladiToShamsi({ date: createdAt, format: "jYYYY/jMM/jDD - HH:mm:ss" })
        },
        {
            label: "قیمت",
            value: `${totalPrice}  ${paymentMethods.find((mt) => mt.id === paymentMethod)?.unit}`
        },
        // {
        //     label: "تخفیف",
        //     value: voucher ? voucher : <p className='text-danger'>ندارد</p>
        // },
        // {
        //     label: "وضعیت",
        //     value: showStatus(status)
        // },
    ]
    return (
        <div className="w-100 rounded py-2 px-2 bg-jet my-4">
            {data?.map((e) =>
                <Row key={e.label} className='my-2'>
                    <Col xs="6" className='text-white'>{e.label}</Col>
                    <Col xs="6" className='text-white'>{e.value}</Col>
                </Row>
            )}
        </div>
    )
}

export default OrderCard