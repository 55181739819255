import React from "react";
import { useLocation } from "react-router";
import { navItems } from "../../../constants";

export default function Breadcrumbs({title}) {
  const location = useLocation();
  const paths = navItems.landing.map((e) => [e.path.replace("/", ""), e.label]);
  const pathsObj = {
    ...Object.fromEntries(paths),
  };
  const direction = location.pathname
    .split("/")
    .map((path) => pathsObj[path])
    .filter((e) => e);
  return (
    <div className="Breadcrumbs d-flex flex-column lh-lg mx-4 my-2">
      <p className="fs-18 text-white fw-500 d-flex align-items-center">
        {direction.map((item, index) => (
          <React.Fragment key={index}>
            {index !== 0 && " > "}
            <span style={{borderRadius : "40px"}} className="text-white fs-12 mx-1 bg-dark-black px-1">{item}</span>
            
          </React.Fragment>
        ))}
        {" > "}
        <span className="text-white fs-12 mx-1">{title}</span>
      </p>
    </div>
  );
}
