import React, { useState, useEffect, useContext } from 'react'
import { axios } from '../../../boot'
import { AdminInput, Form, Input, Select } from '../../../components'
import { Col } from 'react-bootstrap'
// import './index.scss'
import { toast } from '../../../methods'
import { dateBirth, rules } from '../../../constants'
import { AiFillInfoCircle } from "react-icons/ai";
import Context from './_context'

const Edit = ({ info = {} , onClose = () => {} }) => {
    const [page, setPage, chartData, setChartData] = useContext(Context)
    const [data, setData] = useState({})
    useEffect(() => {
        setData(info)
    }, [info])
    const formControls = [
        {
            label: "نام",
            labelColor: "black",
            state: "firstName",
            value: data?.firstName
        },
        {
            label: "نام خانوادگی",
            labelColor: "black",
            state: "lastName",
            value: data?.lastName
        },
        {
            label: "شماره تماس",
            type: "number",
            labelColor: "black",
            state: "phone",
            value: data?.phone,
            readOnly : true
        },
        {
            label: "آیدی تلگرام",
            labelColor: "black",
            state: "telegramid",
            value: data?.telegramid
        },
        {
            label: "ایمیل",
            type: "email",
            labelColor: "black",
            state: "email",
            value: data?.email
        },
        {
            label: "محل تولد",
            labelColor: "black",
            state: "placeofbirth",
            value: data?.placeofbirth
        },
        {
            tag: Select,
            as: AdminInput,
            label: "سال تولد",
            labelColorr: "black",
            state: "birthYear",
            placeholder: "سال تولد به میلادی",
            items: dateBirth?.years,
            value: data?.birthYear
        },
        {
            tag: Select,
            as: AdminInput,
            label: "ماه تولد",
            labelColorr: "black",
            state: "birthMonth",
            placeholder: "ماه تولد به میلادی",
            items: dateBirth?.months,
            value: data?.birthMonth
        },
        {
            tag: Select,
            as: AdminInput,
            label: "روز تولد",
            labelColorr: "black",
            state: "birthday",
            placeholder: "روز تولد به میلادی",
            items: dateBirth?.days,
            value: data?.birthday
        },
        {
            label: "ساعت تولد",
            labelColor: "black",
            state: "birthHour",
            placeholder: "ساعت تولد (اختیاری) را وارد کنید",
            type: "number",
            rules: rules.optional,
            value: data?.birthHour
        },
        {
            label: "دقیقه تولد",
            labelColor: "black",
            state: "birthMinute",
            placeholder: "دقیقه تولد (اختیاری) را وارد کنید",
            type: "number",
            rules: rules.optional,
            value: data?.birthMinute
        },
        {
            as: "textarea",
            label: "توضیحات",
            placeholder: " توضیحات (اختیاری) را وارد کنید",
            labelColor: "black",
            state: "description",
            rules: rules.optional,
            value: data?.description
        },
    ]

    const sendData = () => {
        const url = "https://api.academymarvel.com/api/class/editChart"

        axios.post(url, data).then(({ data }) => {
            let all = chartData
            let index = all?.findIndex(e => e._id === info?._id)
            all[index] = data
            let text = "اطلاعات با موفقیت ویرایش شد"
            setChartData(all)
            toast({ type: "success", text })
            setData({})
            onClose()
        })
    }
    return (
        <div className="w-full py-4">
            <Form onSubmit={sendData} className="  row w-100">
                {formControls.map((item) =>
                    <Col key={item.state} xs="12" lg={item.state == "description" ? "12" : "6"}>
                        {React.createElement(item.tag ?? AdminInput, {
                            ...item,
                            value: item.value,
                            setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
                        })}
                        {item.state == "description" &&
                            <div>
                                <p className="text-danger fw-500 fs-12"><span className="mx-1"><AiFillInfoCircle size={14} /></span>در صورتی که تاریخ تولد را به صورت دقیق وارد نکرده اید و یا تاریخ تولد شخص دیگری را وارد کرده اید در قسمت توضیحات برای ما شرح دهید.</p>
                            </div>
                        }
                    </Col>
                )}
                {/* <div className="mt-4">
                    <p className="text-white text-center fs-14 fw-500">مدن زمان آماده شدن چارت ستاره شناسی : <span className="fs-14 fw-700"> 1 ماه</span></p>
                    <p className="text-center text-primary fs-500 fs-14 my-2">قیمت : <span className="fs-14 fw-700 text-primary ">30 دلار - یک میلیون پانصدهزارتومن</span></p>
                    <p className="text-center text-success fw-500 fs-14">هزینه پرداختی با تخفیف : <span className="fw-700 fs-14 text-success">1 میلیون تومان</span></p>
                </div> */}
                <Col>
                    <button type='submit' className='btn btn-primary text-white w-100 rounded-sm'>ویرایش اطلاعات</button>
                </Col>

            </Form>
        </div>
    )
}

export default Edit