import { useState } from 'react'

import { Container, Tabs, Tab } from 'react-bootstrap'
import AboutCourse from './AboutCourse'
import AboutTeacher from './AboutTeacher'
import Question from './Question'
import Topics from './Topics'
import TopicsTaught from '../TopicsTaught'

import './index.scss'


const AboutCourses = ({ data = {} }) => {
  const [active, setActive] = useState("aboutCourse")

  const buttonInfo = [
    {
      id: "aboutCourse",
      label: "توضیحات کلاس",
      state: "aboutCourse"
    },
    {
      id: "question",
      label: "سوالات متداول",
      state: "question"
    },
  ]
  return (
    <div className="AboutCourses w-100 ">
      <div className="AboutCourses-sort  sort-items-section col-12 flex-center col-gap-5 overflow-auto p-3">
        {buttonInfo.map((item) => (

            <button
            key={item.id}
              onClick={() => setActive(item.id)}
              className={`position-relative white-space-nowrap px-1 py-2 border-0 transition ${active === item.state ? "active text-primary" : "text-white"
                }`}
            >
              {item.label}
            </button>
        ))}
      </div>
      <div className="w-100">
        {active == "aboutCourse" && <AboutCourse data={data} />}
        {active == "question" &&  <Question comments={data?.comments} data={data?.FrequentlyAskedQuestions} />}
      </div>
    </div>
  )
}

export default AboutCourses