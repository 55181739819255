import React from "react";
import { Col } from "react-bootstrap";
import { useQuery } from "../../../hooks";
import okIcon from "../../../assets/icons/receipt ok.svg";
import nokIcon from "../../../assets/icons/receipt nok.svg";
import { FaApple } from "react-icons/fa";
import { IoLogoAndroid } from "react-icons/io";

const ZeroReceipt = () => {
    const { status, refId } = useQuery();
    const isOk = status === "OK";
    return (
        <div className="py-5 row">
            <Col xs="12" md="10" lg="8">
                <div className="wrapper d-flex flex-column flex-center text-center gap-3 p-3">
                    {isOk ? (
                        <React.Fragment>
                            <img
                                width="75"
                                className="d-block mx-auto"
                                src={okIcon}
                                alt="status-ok"
                            />
                            <h4 className="text-primary">پرداخت شما با موفقیت ثبت شد</h4>
                            <p className="text-success">
                                <i className={`bi bi-ticket-detailed ms-1`} />
                                شماره سفارش : {refId}
                            </p>
                            <p>هم اکنون می توانید با نصب اپلیکیشن مارول وارد کانال نقطه صفر شوید.</p>
                            <div className="row">
                                <Col xs="12" lg="4">
                                    <a className="d-flex justify-content-center align-items-center btn btn-outline-primary" href="https://marveltrade.org/ecomarvel.apk">
                                        <span  className="mx-1"><IoLogoAndroid size={22} /></span>
                                        دریافت از Android
                                    </a>
                                </Col>
                                <Col xs="12" lg="4">
                                    <a className="d-flex justify-content-center align-items-center btn btn-outline-primary" href="https://apps.apple.com/us/app/ecot1/id1560742037">
                                        <span className="mx-1"><FaApple size={20} /></span>
                                        دریافت از App Store
                                    </a>
                                </Col>

                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <img
                                width="100"
                                className="d-block mx-auto"
                                src={nokIcon}
                                alt="status-ok"
                            />
                            <h4 className="text-danger">متاسفانه پرداخت شما ناموفق بود!</h4>
                            <p className="text-warning">
                                <i className="bi bi-exclamation-triangle-fill ms-1" />
                                درصورت عدم پرداخت تا 1 ساعت دیگر تمام این سفارش به صورت خودکار
                                لغو خواهد شد.
                            </p>
                        </React.Fragment>
                    )}
                </div>
            </Col>
        </div>
    );
}

export default ZeroReceipt