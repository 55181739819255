import { useContext } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { showActiveImg, source , toast } from "../../../methods";
import { axios } from "../../../boot";
import { useProductStorage } from "../../../hooks";
import { CartCountContext } from "../../../contexts/CartCount";

export default function Products({ cartItems = [], setCartInfo = () => {} }) {
  const productStorage = useProductStorage();
  const isLogged = useSelector((s) => s.isLogged);
  const [count , setCount ] = useContext(CartCountContext);
  const cartCount = cartItems?.map((e) => e.count).reduce((a, b) => a + b);
  const handleCount = (productId, newCount , type = "book") => {
    if (!isLogged) {
      const product = { _id: productId };
      const type = newCount < 0 ? "remove" : "set";
      productStorage[type](product);
      setCartInfo(productStorage.getCartInfo());
      return;
    }
    const url = "/purchases/cart";
    let body = {};
    if(type == "course"){
      body = { courseId : productId , count : newCount  }
    }else{
      body = { productId, count : newCount }
    }
    axios.post(url, body).then((data) => {
      let text = ""
      if(newCount > 0){
        text = "محصول به سبد خرید اضافه شد"
      }else{
        text = "محصول از سبد خرید حذف شد"
      }
      toast({text})
      setCount((p) => p + newCount);
      setCartInfo(data?.data);
    });
  };
  return (
    <div className="wrapper">
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="text-white">مرسولات انتخابی شما</h6>
        <h6 className="text-white">{cartCount} کالا</h6>
      </div>
      {/* <p className="my-2">
        <i className="bi bi-box-seam ms-1 text-primary" />
        ارسال توسط انتشارات جهاد دانشگاهی
      </p> */}
      <div className="d-flex align-items-start gap-4 overflow-auto w-full p-2">
      {/* {cartItems?.map((e, i) => ( */}
        {cartItems?.map((e, i) => (
          <div
            key={i}
            className="cart-item border border-light-gray shadow-sm rounded"
          >
            <img
              height="135"
              className="object-fit-cover w-100 d-block rounded"
              src={e.course ? e.course?.fullScreenCover : showActiveImg(e.product?.img)}
              alt={`cart-item-${i}`}
            />
            <ButtonGroup className="w-100">
              <button
                className="bi bi-dash-lg text-danger flex-1"
                onClick={() => e?.course ? handleCount(e._id, -1 , "course") : handleCount(e._id, -1) }
              />
              <button className="btn flex-1 text-white">{e.count}</button>
              <button
                onClick={() =>  e?.course ? handleCount(e._id, 1 , "course") : handleCount(e._id, 1)}
                className="bi bi-plus-lg text-success flex-1"
              />
            </ButtonGroup>
          </div>
        ))}
      </div>
    </div>
  );
}
