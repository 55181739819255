import React , { useState } from 'react'
import { toast } from '../../../methods'
import { axios } from '../../../boot'
import { Col } from 'react-bootstrap'
import { AdminInput, Button, Form, Select } from '../../../components'
const LiveSms = ({groups = []}) => {
    const [data, setData] = useState({})
    const formControls = [
        {
            label: "گروه",
            state: "_id",
            tag: Select,
            as: AdminInput,
            items: groups?.map((e) => ({ name: e.name, id: e._id }))

        },
        {
            as: "textarea",
            state: "text",
            label: "متن پیام"
        }
    ]
    const sendLiveSms = () => {
        const url = `/admins/groupClassSmslive?text=${data?.text}`
        const body = {
            _id: data?._id
        }
        axios.post(url, body).then(() => {
            const text = "پیامک با موفقیت ارسال شد"
            toast({ text })
            setData({})
        })
    }
    return (
        <Form className="row" onSubmit={sendLiveSms}>
            <h5 className='text-center'>ارسال پیامک لایو</h5>
            {formControls.map((e) => (
                <Col key={e.state} xs="12" md={12} lg={12}>
                    {React.createElement(e.tag ?? AdminInput, {
                        ...e,
                        value: data[e.state],
                        setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                    })}
                </Col>
            ))}
            <Col xs="12" className="d-flex flex-center">
                <Button type="submit">ارسال پیامک لایو</Button>
            </Col>
        </Form>
    )
}
export default LiveSms