import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { axios } from "../../../boot";
import UserInfo from "./UserInfo";
import UserPurchases from "./UserPurchases";
export default function Dashboard() {
  
  return (
    <div className="w-100 px-4">
      <UserInfo />
      <UserPurchases />
    </div>
  );
}
