import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { axios } from "../../../boot";
import { ProductCard } from "../../../components";

export default function Products() {
  const [products, setProducts] = useState([]);
  const getProducts = () => {
    const url = "/pub/shop/products";
    axios.get(url).then(({ data }) => {
      let res = data?.data?.filter(e => e.status !== "disable")
      setProducts(res);
    });
  };
  useEffect(getProducts, []);
  return (
    <div className="p-4">
      <Row className="">
        {products.map((e, index) => (
          <Col key={index} xs="12" md="6" lg="4" xl="3">
            <ProductCard {...e} />
          </Col>
        ))}
      </Row>
    </div>

  );
}
