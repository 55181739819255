import { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ProductCard } from "../../../components";
import { axios } from "../../../boot";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./index.scss";

// import required modules
import SwiperCore, { Pagination, Navigation, Autoplay, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";


export default function Products({ products = [] }) {
  const [data, setData] = useState([]);
  const getProducts = () => {
    const res = products?.filter(i => i.status !== "disable")?.map((e) => ({
      ...e,
      _id: `/products/${e._id}`,
    }));
    setData(res);
  };
  const swiperRef = useRef();
  SwiperCore.use([Navigation, Autoplay])
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  useEffect(getProducts, [products]);
  return (
    <Container className="PRODUCT d-flex flex-column flex-center row-gap-4 my-4">
      <div className="w-100 d-flex align-items-center justify-content-start">
        <p className="text-white fs-18 fw-700">فروشگاه کتاب مارول</p>
        <Link className="text-primary fs-14 fw-400 mx-2 d-lg-inline-block " to='/products'>مشاهده همه</Link>
      </div>
      <Row>
        {/* {products.map((e) => (
          <Col key={e._id} xs="12" md="6" lg="4" xl="3">
            <ProductCard {...e} />
          </Col>
        ))} */}
        <div className="w-100 product-swiper d-flex align-items-center justify-content-between my-4">
          <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-gray1 text-white  mx-1 rounded-circle p-4" onClick={() => { swiperRef.current?.slidePrev(); swiperRef.current?.autoplay.stop() }}> <i className="bi bi-arrow-right"></i> </button>
          <Swiper
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {

              swiperRef.current = swiper;
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}

            pagination={{ clickable: true }}
            modules={[Navigation, Scrollbar, Pagination]}
            className="mySwiper w-100 mx-2 "
            breakpoints={{
              540: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
          >
            {data?.map((item, index) => (
              item?.count > 0 &&
              <SwiperSlide key={index} className="h-auto">
                <ProductCard {...item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-gray1 text-white  mx-1 rounded-circle p-4" onClick={() => { swiperRef.current?.slideNext(); swiperRef.current?.autoplay.stop() }}> <i className="bi bi-arrow-left"></i> </button>
        </div>
        {/* <Col xs="12" className="d-flex flex-center mt-2 mb-4">
          <Link to="/products" className="Button btn btn-primary">
            مشاهده بیشتر
          </Link>
        </Col> */}
      </Row>
    </Container>
  );
}
