import {useState , useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { Table } from '../../../components'
import {axios, moment} from "../../../boot" 
const Groups = () => {
    const navigate = useNavigate()
    const [ groups , setGroups ] = useState([])
    const getGroups = () =>{
        const url = "/admins/classgroup"
        axios.get(url).then(({data})=>{
            setGroups(data.data)
        })
    }

    useEffect(getGroups,[])
  return (
    <Row className="align-items-stretch">
            <div className="col-12 d-flex justify-content-end">
                <Link
                    to="new"
                    className="bi bi-plus-lg d-flex flex-center fs-4 text-success"
                />
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>نام گروه</th>
                        <th>تعداد کاربران</th>
                        <th>تاریخ</th>
                    </tr>
                </thead>
                <tbody>
                    {groups?.map((e) => (
                        <tr key={e._id} onClick={() => navigate(e._id)}>
                            <td>{e.name}</td>
                            <td>{e.users?.length}</td>
                            <td>{moment.miladiToShamsi({date : e?.createdAt})}</td>
                            
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Row>
  )
}

export default Groups