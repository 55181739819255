import React from 'react'

import { Card } from 'react-bootstrap'

import './index.scss'
import { source } from '../../../methods'

const CardFreeTutorial = ({ name = "", content = "" , avatar= "" }) => {
    console.log(name)
    return (
        <div className='Students-comments p-2'>
            <div className='d-flex align-items-center justify-content-start'>
                <img src={avatar} alt="user image" width="59" height="59" className='rounded-circle object-fit-cover' />
                <p className='fw-700 fs-14 mx-2 text-white'>{name}</p>
            </div>
            <p className="fs-14 fw-400 m-1">
                {content}
            </p>
        </div>
    )
}

export default CardFreeTutorial