import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { axios } from "../../../boot";
import { FreeTutorialsCard, Pagination } from '../../../components'
import "./index.scss"
export default function FreeTutorials() {
  const [categories, setCategories] = useState([]);
  const [pages, setPages] = useState({})
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const saveParams = () => {
    const value = JSON.stringify(params);
    sessionStorage.setItem("params", value);
  };
  const getCategories = () => {
    saveParams()
    const url = "/pub/categories";
    axios.get(url).then((res) => {
      const data = res.data.data.map((e) => ({
        name: e.title_fa,
        id: e.title,
        img: e.img,
      }));
      setPages(res?.data?.pages);

      setCategories(data);
    });
  };
  useEffect(getCategories, []);
  return (
    <Container className="AllFreeTutorials">
      <Row>
        <p className="fs-24 fw-700 text-white text-center my-4">دوره‌های رایگان مارول ترید</p>
        {categories.map((item) => (
          <Col className="AllFreeTutorials-col" key={item.id} xs="12" md="6" lg="4">
            <FreeTutorialsCard {...item} />
            {/* <Link
              key={item.id}
              to={item.id}
              className="d-flex flex-column flex-center gap-4 w-100 bg-primary rounded p-2 shadow-sm"
            >
              {item.img ? (
                <img
                  width="50"
                  height="50"
                  className="rounded-circle bg-info object-fit-contain"
                  src={item.img}
                  alt={item.name}
                />
              ) : (
                <i
                  style={{
                    width: "50px",
                    height: "50px",
                  }}
                  className="bi bi-journal-bookmark-fill text-white fs-2 bg-info bg-opacity-75 rounded-circle d-flex flex-center"
                />
              )}
              <h5 className="text-white">{item.name}</h5>
            </Link> */}
          </Col>
        ))}
        {/* <Col xs="12" lg="12">
          
          <Pagination
            totalPages={pages.totalPages}
            activePage={params.page}
            setActivePage={(page) => setParams((p) => ({ ...p, page }))}
          />
        </Col> */}
      </Row>
    </Container>
  );
}
