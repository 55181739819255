import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, Input, Button, ToggleSwitch } from "../../../components";
import { rules, rolesPath } from "../../../constants";
import { axios } from "../../../boot";
import { useQuery, useSetToken } from "../../../hooks";
import { convertPhone, toast } from "../../../methods";
import Context from "../_context";
// import LogoText from '../../../assets/logos/logoLogin.png'
import logo from '../../../assets/logos/Marvel academy.svg'

import Timer from "../../../components/NewVersion/Timer";

export default function SignIn({ show = false, onHide = () => { } }) {
  const [page, setPage] = useContext(Context);
  const navigate = useNavigate();
  const setToken = useSetToken();
  const { hasCart } = useQuery();
  const [params] = useSearchParams();
  const phone = params.get("phone");
  const [data, setData] = useState({ phone });
  const [phonee, setPhone] = useState("")
  const tabs = {
    sms: "شماره موبایل",
    email: "ایمیل",
  };
  const [activeTab, setActiveTab] = useState("sms");
  const smsFormControls = [
    {
      label: "شماره موبایل",
      labelColor: "light-gray",
      border: false,
      state: "phone",
      type: "number",
      rules: rules.phoneNumber,
      // onKeyDown: (e) => { return (e.keyCode === 13 ? handleOnSubmit() : null) }
      // append: <Button onClick={() => sendCode()}>ارسال کد</Button>,
    },
  ];

  const verifyCodeControls = [

    {
      label: "کد ارسال شده",
      labelColor: "light-gray",
      border: false,
      state: "code",
      type: "number",
      // onKeyDown: (e) => {return (e.keyCode === 13 ? handleOnSubmit() : null) }
    },
  ];

  const emailFormControls = [
    {
      label: "ایمیل",
      labelColor: "light-gray",
      border: false,
      state: "email",
      type: "email",
      rules: rules.email,
    },
    {
      label: "رمز ورود",
      labelColor: "light-gray",
      border: false,
      state: "password",
      type: "password",
      rules: rules.password,
      // onKeyDown: (e) => {return (e.keyCode === 13 ? handleOnSubmit() : null) }
    },
  ];
  const formControls = { sms: smsFormControls, email: emailFormControls, verify: verifyCodeControls };
  const sendCode = (e) => {
    const isValid = rules.phoneNumber.every(
      (rule) => rule(data.phone) === true
    );
    setData((p) => ({ ...p, code: "" }));
    if (!isValid)
      return toast({ text: "شماره موبایل نادرست است", type: "error" });
    const url = "/users/send-sms";
    setPhone(convertPhone(data.phone, true))
    const body = { phone: convertPhone(data.phone, true) };
    axios.post(url, body).then(() => {
      const text = "کد با موفقیت ارسال شد.";
      setActiveTab("verify")
      toast({ text });
    });
  };
  const verify = () => {
    const url = "/users/verify";

    // const body = cloneDeep(data);
    // body.phone = convertPhone(body.phone, true);
    const body = { phone: phonee, code: data.code };

    axios.post(url, body).then(handleSetToken);
  };
  const login = () => {
    const url = "/users/sign-in";
    const body = { ...data };
    axios.post(url, body).then(handleSetToken);
  };
  const handleSetToken = ({ data, headers }) => {
    const token = data.token
    const role = data.role;
    const text = "برای دسترسی به دوره‌ها ثبت‌نام کنید.";
    role === "unregistered" && toast({ text });
    setToken({ token, role });

    // if(data.firstName?.length == 0 || data.lastName?.length == 0) return navigate("/profile")
    if (hasCart) return navigate("/cart");
    navigate("/", { replace: true });
    onHide(false)
  };
  const handleOnSubmit = () => {
    if (activeTab === "verify") {
      return verify()
    } else if (activeTab === "sms") {
      return sendCode()
    } else {
      return login()
    }
  }
  useEffect(() => setData({}), [activeTab]);
  return (
    <Form onSubmit={handleOnSubmit} className="SIGNIN row py-2 d-flex flex-column align-items-center justify-content-center ">
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        <img style={{ width: "115px", height: "93px" }} className="" src={logo} alt="logo" />
        <p className=" my-2 col-12 text-primary text-center fs-25 fw-700 my-1">خوش آمدید</p>
        {activeTab == "email" &&

          <p className="my-1">
            ورود با{" "}
            <button
              className="text-primary text-decoration-underline fw-bold"
              type="button"
              onClick={() => setActiveTab("sms")}
            >
              شماره موبایل
            </button>{" "}
            مخصوص کاربران داخل کشور
          </p>

        }
        {activeTab == "sms" &&
          <p className="my-1">
            ورود با{" "}
            <button
              className="text-primary text-decoration-underline fw-bold"
              type="button"
              onClick={() => setActiveTab("email")}
            >
              ایمیل
            </button>{" "}
            مخصوص کاربران خارج از کشور
          </p>
        }
        {activeTab == "verify" &&
          <Timer onActive={setActiveTab} time={60} />
        }
      </div>
      {formControls[activeTab].map((item) => (
        <Col key={item.state} xs="12">
          <Input
            className="bg-dark-black text-white rounded-4 border-dark-block fs-14 fw-400"
            {...item}
            value={data[item.state]}
            setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
          />
        </Col>
      ))}
      <Col xs="12" className="w-full d-flex align-items-center justify-content-start">
        <button type="button" onClick={(e) => e.keyCode === 13 ? null : setPage("forgotPass")} className="text-primary bg-transparents border-0 fs-14">فراموشی رمز عبور</button>
      </Col>

      {/* {activeTab == "email" &&
        <Col xs="12" className="d-flex algn-items-center justify-content-between fs-12 fw-700">
          <ToggleSwitch
            value={data.remember}
            setValue={(val) => setData((p) => ({ ...p, remember: val }))}
            className="text-light-gray"
            label="مرا به خاطر بسپار"
          />
          <Link to="/forget-password" className="text-light-gray">
            فراموشی رمز ورود
          </Link>
        </Col>
      } */}

      <Col xs="12">
        {/* {activeTab == "sms" ?
          <Button onClick={handleOnSubmit} type="button" className="w-100">
            دریافت کد عبور
          </Button>
          :
          <Button onClick={handleOnSubmit} type="button" className="w-100">
            ورود
          </Button>

        } */}
        <Button onClick={handleOnSubmit} type="button" className="w-100">{activeTab == "sms" ? "دریافت کد عبور" : "ورود"}</Button>
      </Col>

      {
        activeTab === "email" &&
        <p className="col-12 text-center fs-14 fw-400">
          حساب کاربری ندارید؟{" "}
          {/* <Link to="/sign-up" replace className="text-primary">
            ثبت نام کنید
          </Link> */}
          <span onClick={() => setPage("signUp")} className="text-primary cursor-pointer">
            ثبت نام کنید
          </span>
        </p>
      }

    </Form>
  );
}
