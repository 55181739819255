import React , { useState, useEffect } from 'react'
import { axios} from '../../../boot'
import './index.scss'
import { Container, Col } from 'react-bootstrap'
import { Button , Form , Input, Select } from '../../../components'
// import { useGetConsultation } from '../../../hooks'
// import { wordifyRials, wordifyRialsInTomans, wordifyfa } from '../../../methods/_wordifyfa'
import { PiWarningCircle } from "react-icons/pi";
import { toast } from '../../../methods'

const BuyConsultation = () => {
  const [data, setData] = useState({})
  // const [times, getTimes] = useGetConsultation()
  // useEffect(getTimes, [])
  const formControls = [
    {
      label: "نام",
      state: "firstName",
      labelColor : "white",
    },
    {
      label: "نام خانوادگی",
      state: "lastName",
      labelColor : "white",
    },
    {
      label: "شماره همراه",
      state: "phone",
      labelColor : "white",
      type : "tel"
    },   
    {
      label: "آیدی تلگرام",
      state: "telegramid",
      labelColor : "white",
    },
    {
      label: "سرمایه دلاری",
      state: "capitalUSD",
      labelColor : "white",
      type : "number"
    },
  ]
  const Buyconsultation = () => {
    const url = "https://api.academymarvel.com/api/class/payCooperative"
    if(data?.capitalUSD < 40000){
      let text = "سرمایه شما کمتر از 40,000 دلار است"
      return toast({text , type : "error"})
    }
    // console.log(data)
    axios.post(url, data).then(({ data }) => {
      const link = data.url;
      window.location.href = link;
      setData({})
    })
  }
  // if (times.length === 0) return <p className='text-center fs-25 text-primary fw-700 my-4'>در حال حاضر تمامی زمان های مشاوره رزرو شده اند</p>
  return (
    <Container className="BuyConsultation py-4 ">
      <Form onSubmit={Buyconsultation} className="row flex justify-content-start my-4 ConsultationForm rounded p-4">
        <p className="text-white fs-25 fw-700 text-center my-4">ثبت نام ترید خصوصی</p>
        
        {formControls.map((e, index) =>
          <Col key={index} xs="12" md="12" lg="6">
            {React.createElement(e.tag ?? Input, {
              ...e,
              key: e.state,
              value: data[e.state],
              className: "bg-raisin2 text-white rounded-4 fs-14 fw-400",
              setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
            })}
            {/* {(e.state === "capital" && data[e.state]) && <p className='fs-12 text-success'>{wordifyfa(data[e.state])} تومان</p>} */}
          </Col>
        )}
        <p className="text-danger"><PiWarningCircle/> اگر سرمایه دلاری شما بیشتر از 40,000 دلار است میتوانید ثبت نام کنید</p>
        <p className="text-right fs-15 fw-500 text-primary" >هزینه ترید خصوصی : 20 میلیون تومان</p>
        <div className="col-12 d-flex flex-center">
          <Button type="submit">ثبت‌ نام و پرداخت</Button>
        </div>
      </Form>
    </Container>
  )
}

export default BuyConsultation