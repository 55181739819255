import { useRef } from 'react'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore, { Navigation, Autoplay } from 'swiper'
import CardFreeTutorial from '../../../components/NewVersion/CardFreeTutorial';

import { HiOutlineArrowSmRight, HiOutlineArrowSmLeft } from 'react-icons/hi'

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const StudentComments = ({
  data = []
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3
  };
  const swiperRef = useRef();
  SwiperCore.use([Navigation])
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const numberOfSlide = data?.length > 3 ? 3 : data?.length
  return (
    <div  className="w-100 bg-black  my-4">
      <div className="d-flex justify-content-center justify-content-lg-between align-items-center p-2"><button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-gray1 text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slidePrev()}> <i className="bi bi-arrow-right"></i> </button>

        <Swiper
          slidesPerView={1}
          breakpoints={{
            540: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: numberOfSlide,
              spaceBetween: 10,
            },
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          modules={[Autoplay]}
          

          autoplay={
            {
              delay: 5000,
              disableOnInteraction: false
            }
          }
        >
          {data?.map((item , index) =>
            <SwiperSlide key={index} className=" d-block d-md-flex justify-content-center align-items-center">
              <CardFreeTutorial {...item} />
            </SwiperSlide>
          )}
        </Swiper>
        <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-gray1 text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slideNext()}> <i className="bi bi-arrow-left"></i> </button>

        {/* <Slider {...settings}>
          {data?.comments?.map((item) =>
              <CardFreeTutorial {...item} />
          )}
        </Slider> */}
      </div>
    </div>
  )
}

export default StudentComments