import Dashboard from "../pages/UsersPanel/Dashboard";
import Exams from "../pages/UsersPanel/Exams";
import Exam from "../pages/UsersPanel/Exam";
import Classes from "../pages/UsersPanel/Classes";
import Class from "../pages/UsersPanel/Class";
// import UserPayments from "../pages/UsersPanel/Payments";
import LeaderBoard from "../pages/UsersPanel/LeaderBoard";
import Products from "../pages/UsersPanel/Products";
import Product from "../pages/UsersPanel/Product";
import Profile from "../pages/UsersPanel/Profile";
import Cart from "../pages/UsersPanel/Cart";

import Registers from "../pages/UsersPanel/Registers";
import Blog from "../pages/Landing/Blog";
import BlogDetail from "../pages/Landing/BlogDetail";
import Receipt from "../pages/UsersPanel/Receipt";
import MyOrders from "../pages/UsersPanel/MyOrders";
import License from "../pages/UsersPanel/License";
import Tutorials from "../pages/Landing/Tutorials";
import Tutorial from "../pages/Landing/Tutorials/Tutorial";
import MyInstallments from "../pages/UsersPanel/MyInstallments";
import ClassCalendar from "../pages/UsersPanel/ClassCalendar";
import EditProfile from "../pages/UsersPanel/Profile/EditProfile";
import MyAddress from "../pages/UsersPanel/MyAddress";
import ChangePassword from "../pages/UsersPanel/ChangePassword";
import RedirectInstallment from "../pages/UsersPanel/RedirectInstallments";
// import BuyConsultation from "../pages/UsersPanel/BuyConsultation";

const userRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "my-order",
    element: <MyOrders />,
  },
  {
    path: "license",
    element: <License/>,
  },
  {
    path: "registers",
    element: <Registers />,
  },
  {
    path: "my-installments",
    element: <RedirectInstallment />,
  },
  {
    path: "installments",
    element: <MyInstallments />,
  },
  
  // {
  //   path: "classes",
  //   element: <Classes />,
  // },
  // {
  //   path: "classes/:id",
  //   element: <Class />,
  // },
  // {
  //   path: "exams",
  //   element: <Exams />,
  // },
  // {
  //   path: "exams/:id",
  //   element: <Exam />,
  // },
  
  // {
  //   path: "products",
  //   element: <Products />,
  // },
  // {
  //   path: "products/:id",
  //   element: <Product />,
  // },
  {
    path: "blog-list",
    element: <Blog />,
  },
  {
    path: "blog-list/:id",
    element: <BlogDetail />,
  },
  // {
  //   path: "leader-board",
  //   element: <LeaderBoard />,
  // },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "profile/:id",
    element: <EditProfile />,
  },
  {
    path: "cart",
    element: <Cart />,
  },
  {
    path: "receipt",
    element: <Receipt />,
  },
  {
    path: "my-address",
    element: <MyAddress />,
  },
  {
    path: "change-password",
    element: <ChangePassword />,
  },
  // {
  //   path: "class-calendar",
  //   element: <ClassCalendar />,
  // },
];
export default userRoutes;
