import React, { useState } from 'react'
import { Button, Form, Input } from '../../../components'
import { axios } from '../../../boot'
import { Col } from 'react-bootstrap'
import { convertPhone, toast } from '../../../methods'
import { rules } from '../../../constants'
const ChangePassword = () => {
    const [ data , setData ] = useState({})
    const formControls2 = [
        {
            label: "شماره موبایل",
            labelColor: "white",
            border: true,
            borderColor: "jet",
            state: "phone",
            type : "tel"
        },
        {
            label: "رمز جدید",
            labelColor: "white",
            border: true,
            borderColor: "jet",
            state: "password",
            type : "password",
            rules: rules.password,
        },
        {
            label: "تکرار رمزعبور جدید",
            labelColor: "white",
            border: true,
            borderColor: "jet",
            state: "confirmPassword",
            type : "password",
            rules: rules.password,
        },
    ];
    const submit = () => {
        if(data?.password != data?.confirmPassword){
            let text = "پسورد مطابقت ندارد"
            return toast({text , type : "error"})
        }
        let body = {...data}
        delete body.confirmPassword
        body.phone = convertPhone(body?.phone)
        const url = "/users/changeUserPassword"
        axios.post(url,body).then(({data})=>{
            let text = "رمزعبور با موفقیت تغییر کرد"
            toast({text})
            setData({})
        })
    }
    return (
        <div className="w-100 bg-dark-black p-4">
            <p className="fs-14 fw-400 text-white mx-3 mb-4"> <i className="bi bi-key-fill text-primary fs-20"></i> تغییر رمز عبور</p>
            <Form onSubmit={submit} className="row justify-content-start">
                {formControls2.map((e, index) =>
                    <Col key={index} xs="12" md="6" lg="3">
                        {React.createElement(e.tag ?? Input, {
                            ...e,
                            key: e.state,
                            value: data[e.state],
                            className: "bg-raisin2 text-white rounded-4 fs-14 fw-400",
                            setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                        })}
                    </Col>
                )}
                <Col xs="12" md="6" lg="3" className="">
                    <Button className='mt-3 py-2' outline={true} type="submit">تغییر رمز</Button>
                </Col>
            </Form>
        </div>
    )
}

export default ChangePassword