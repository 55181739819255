import React , { useState } from 'react'
import { toast } from '../../../methods'
import { axios } from '../../../boot'
import { Col } from 'react-bootstrap'
import { AdminInput, Button, Form, Select } from '../../../components'
const CancelSms = ({groups = []}) => {
    const [ data , setData ] = useState({})
    const formControls = [
        {
            label: "گروه",
            state: "_id",
            tag: Select,
            as: AdminInput,
            items: groups?.map((e) => ({ name: e.name, id: e._id }))

        },
    ]
    const cancelSms = () => {
        console.log("cancel")
        const url = `/admins/groupClassSmsCancel`
        const body = { _id: data?._id }
        axios.post(url, body).then(({ data }) => {
            const text = "پیامک با موفقیت ارسال شد"
            toast({ text })
            setData({})
        })
    }
    return (
        <Form className="row" onSubmit={cancelSms}>
            <h5 className='text-center'>ارسال پیامک کنسلی</h5>
            {formControls.map((e) => (
                <Col key={e.state} xs="12" md={"6"} lg={"6"}>
                    {React.createElement(e.tag ?? AdminInput, {
                        ...e,
                        value: data[e.state],
                        setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
                    })}
                </Col>
            ))}
            <Col xs="12" className="d-flex flex-center">
                <Button type="submit">ارسال پیامک کنسلی</Button>
            </Col>
        </Form>
    )
}

export default CancelSms