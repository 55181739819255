import React, { useState } from 'react';

import { Col, Container, Row, Fade } from 'react-bootstrap'
import { offCal, toast } from '../../methods';
import Badge from '../Badge'
// import Button from '../Button';
import { useNavigate, useParams } from "react-router";
import { useSelector } from 'react-redux';
// import { useAddToCart, useProductStorage } from '../../hooks';
// import { AnimationOnScroll } from 'react-animation-on-scroll';
// import Table from '../Table';
// import { moment } from '../../boot';

const Installments = ({
    data = {},
    info = {}
}) => {
    const navigate = useNavigate()
    const numberInstallment = data?.numberOfInstallments
    // const params = useParams()
    // const addToCart = useAddToCart();
    const isLogged = useSelector((s) => s.isLogged);
    const hasOff = data?.off !== 0;
    const hasOffUds = data?.offUsd !== 0;
    const totalPrice = offCal(data?.priceInstallment, data?.off)?.toLocaleString();
    const totalPriceUsd = offCal(data?.priceInstallmentUsd, data?.offUsd);
    // const productStorage = useProductStorage();
    const { firstName, lastName, codeMelli } = info
    const addCourseToCart = () => {
        let text = ""
        if (isLogged) {
            if (!firstName || !lastName || !codeMelli) {
                text = "لطفا ابتدا اطلاعات خود را کامل کنید."
                toast({ type: "error", text })
                return navigate("/profile")
            }
            let body = { ...data }
            body.buyInstallment = true
            navigate("/order", { state: body })
        } else {
            text = "برای ثبت خرید لطفا ابتدا وارد سایت شوید"
            return toast({ type: "error", text })
        }
        // productStorage.set(data);
    };
    return (
        <button disabled={!data?.available} onClick={addCourseToCart} className="Installments btn btn-white my-2 d-flex justify-content-between align-items-center shadow-lg px-2">
            <p className='m-2  fw-700 fs-20 text-black d-inline-block'>اقساطی <span className="fs-15 d-block">در {numberInstallment} قسط</span></p>
            {totalPriceUsd !== 0 &&
                <div className="d-flex flex-column flex-center">
                    {hasOffUds && (
                        <h6 className="text-secondary">
                            <span className="text-decoration-line-through">
                                {data?.priceUsd} تتر
                            </span>{" "}
                            <Badge variant="danger" label={`${data?.offUsd}%`} />
                        </h6>
                    )}
                    <h5 className="text-black fw-700">{totalPriceUsd} تتر</h5>
                </div>
            }

            <div className="d-flex flex-column flex-center">
                {hasOff && (
                    <h6 className="text-secondary">
                        <span className="text-decoration-line-through">
                            {data?.price?.toLocaleString()} تومان
                        </span>{" "}
                        <Badge variant="danger" label={`${data?.off}%`} />
                    </h6>
                )}
                <h5 className="text-black fw-700">{totalPrice} تومان</h5>
            </div>
            <i className="bi bi-caret-left-fill"></i>

            
        </button>
    )
}

export default Installments