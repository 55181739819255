import React from 'react'
import Button from '../Button'
import { toast } from '../../methods'
import { axios } from '../../boot'
const AddressCard = ({ data = {}, setShow = () => { } , getAddress = () => { } }) => {
    const formControl = [
        {
            label: "آدرس",
            icon: "geo-alt",
            value: data?.address
        },
        {
            label: "تحویل گیرنده",
            icon: "person",
            value: `${data?.firstName} ${data?.lastName}`
        },
        {
            label: "شماره تماس",
            icon: "phone",
            value: data?.phone
        },
        {
            label: "شماره تلفن",
            icon: "telephone",
            value: `${data?.landlinePhone}-${data?.areaCode}`
        },
        {
            label: "کدپستی",
            icon: "envelope-fill",
            value: data?.postCode
        },
    ]
    const deleteHandler = (id) => {
        const url = "/address"
        const body = {
            _id : id
        }
        axios.delete(url , {data : body}).then(({data})=>{
            let text = "آدرس با موفقیت حذف شد"
            toast({text})
            getAddress()
        })
    }
    return (
        <div className="w-100 my-4 border-top ">
            {
                formControl.map((e, index) =>
                    <div className={`${index == 4 && "d-flex align-items-center justify-content-between w-100"} p-2`} key={e.icon}>
                        <p className=""> <i className={`bi bi-${e.icon} text-white fw-bold mx-1`}></i> <span className="text-white fw-bold">{e.label} : </span>{e.value}</p>
                        {index == 4 &&
                            <div>
                                <button onClick={() => setShow(data)} className="btn btn-white text-black mx-1"><i className="bi bi-pencil"></i></button>
                                <button onClick={() => deleteHandler(data?._id)} className="btn btn-white text-black mx-1"><i className="bi bi-trash"></i></button>
                            </div>
                        }

                    </div>
                )
            }
        </div>
    )
}

export default AddressCard