import React from 'react'

import { Link } from 'react-router-dom'
import Button from '../../Button'
import { Badge } from 'react-bootstrap'
import './index.scss'

import {  Ribbon2 } from '../..'

const PopularClassCard = ({ cover = "", title = "", _id = "", teacher = {}, post = "", time = "", category = {}, available = true }) => {
    return (
        <Link to={`/tutorials/${_id}`} className="PopularClassCard  position-relative d-block w-100 rounded  shadow-sm overflow-hidden m-2">
                <img referrerPolicy="no-referrer" className='PopularClassCard-img ' src={cover} alt={title} loading='lazy' />
                {!available && <Ribbon2 label='توقف فروش' />}
                <div className="position-absolute top-0 left-0 m-2">
                    {/* <p className="text-black font-fa fs-10 fw-700">{category?.title_fa}</p> */}
                    <Badge pill bg="white" text="black" className="py-1" style={{ direction: "rtl" }}>{category?.title_fa}</Badge>
                </div>

                <div className="popular-details d-flex flex-column align-items-center">
                    <p className="fw-700 fs-30 text-white text-center">{teacher?.name}</p>
                    {/* <p className="fw-400 fs-12 text-white text-center">{post}</p> */}
                    <span style={{ width: "27px" }} className='border border-white border-2 my-4'></span>
                    <p className="fw-700 fs-12 text-white text-center">{title}</p>
                    {/* <p className="fw-400 fs-12 text-white text-center">{time}  </p> */}

                    {/* <div style={{ zIndex: "3" }} className="w-100 popular-details-btn my-4">
                        <Button className="btn btn-gray1 Button font-sans fs-12 py-2 px-4 fw-500 mx-auto w-75">
                            <i className="bi bi-question-circle"></i> درباره دوره
                        </Button>

                    </div> */}
                </div>
        </Link>
    )
}

export default PopularClassCard