import { useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { axios } from "../../../boot";
import SearchBox from "../../../components/NewVersion/SearchBox";
import { PopularClassCard } from "../../../components/NewVersion";
import { Modal , Button } from "../../../components";
import { category } from "../../../constants/";
import Filter from "./Filter";
import { BsFilter } from 'react-icons/bs'
import { toast } from "../../../methods";
import './index.scss'
import { useGetCategory } from "../../../hooks";
import { useSelector } from "react-redux";
export default function Tutorials() {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const [tutorials, setTutorials] = useState([]);
  const [show, setShow] = useState(false)
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [categories, getCategories] = useGetCategory()
  const isLogged = useSelector((s) => s.isLogged)
  const navigate = useNavigate();
  const getTutorials = () => {
    const config = {
      params: {
        sort: "category",
        perPage: 20,
        ...params,
      },
    };
    let url = ""
    if(isLogged){
       url = "/pub/shop/usercourses"
    }else{
      url = "/pub/shop/courses";
    }
    
    axios.get(url, config).then((res) => {
      const data = res.data?.data?.filter(i => i.status !== "disable" && i.ketab !== true)?.map((e) => ({
        teacher: e.teacher,
        available : e.available,
        title: e.title,
        _id: e._id,
        cover: e.cover,
        category: e.category,
        time: e.time,
      }));
      setTutorials(data);
    });
  };

  const searchHandler = (title) => {
    let url = ""
    if(isLogged){
       url = "/pub/shop/usercourses"
    }else{
      url = "/pub/shop/courses";
    }
    let body = {...params}
    body.title = title
    const config = {
      params: {
        sort: "category",
        perPage: 20,
        ...body,
      },
    };
    axios.get(url, config).then((res) => {
  
      const data = res.data?.data?.filter(i => i.status !== "disable" && i.book == false)?.map((e) => ({
        teacher: e.teacher,
        available : e.available,
        title: e.title,
        _id: e._id,
        cover: e.cover,
        category: e.category,
        time: e.time,
      }));
      
      setTutorials(data);
      let text = "دوره ای یافت نشد"
      if(data?.length === 0 ) return toast({text , type : "error"})
    });
  }
  useEffect(getCategories, [])
  useEffect(getTutorials, [params]);
  // if(tutorials?.length === 0) return <h4 className="text-center text-primary my-4">دوره ای یافت نشد</h4>
  return (
    <Container className="FreeTuTorilas-Category position-relative my-2 p-4">

      <Row className="d-flex justify-content-center align-items-start">
        <Col className=" p-4 rounded-10 d-none d-lg-block shadow bg-dark-black" xs="12" lg="3">
          <div className="my-4">
            <p className="fs-14 fw-700 text-light-gray my-2">دسته‌بندی</p>

            {categories.map((item) =>
              <div key={item._id} className="d-flex my-2">
                <input type="checkbox" onClick={() => {
                  if (params.category) {
                    setParams((p) => ({ ...p, page: 1, "category": "" }));
                  } else {
                    setParams((p) => ({ ...p, page: 1, "category": item.title_fa }));
                  }
                }} />
                <p className="mx-1 fs-10 fw-400 text-white">{item.title_fa}</p>
              </div>
            )}
          </div>

          {/* <div className="my-4">
            <p className="fs-14 fw-700 text-light-gray my-2">سطح آموزش</p>

            {category.levelTeach.map((item, index) =>
              <div key={item.id} className="d-flex my-2">
                <input className="" type="checkbox" />
                <p className="mx-1 fs-10 fw-400 text-white">{item.label}</p>
              </div>
            )}
          </div> */}

          {/* <div className="my-4">
            <p className="fs-14 fw-700 text-light-gray my-2">مدت زمان</p>

            {category.timing.map((item, index) =>
              <div key={item.id} className="d-flex my-2">
                <input type="checkbox" />
                <p className="mx-1 fs-10 fw-400 text-white">{item.label}</p>
              </div>
            )}
          </div> */}

        </Col>


        <Col className="d-lg-none" xs={12}>
          <Button onClick={() => setShow(p => !p)} className="mx-3">
            <BsFilter />
            فیلتر
          </Button>
        </Col>

        <Col xs="12" lg="9">
          <div className="d-flex flex-column align-items-start flex-lg-row justify-content-center  justify-content-lg-between align-items-lg-center mb-4 px-2" xs="12" lg="12">
            <div>
              <SearchBox onSubmit={searchHandler}  color="text-white" className="shadow bg-dark-black text-white border-0 p-2 rounded-10px m-2" />
            </div>

            <p className="fs-12 fw-400 m-2">{tutorials?.length} مورد یافت شد</p>
          </div>
          <Row>
            {tutorials.map((item) => (
              <Col key={item._id} xs="11" md="6" lg="4">
                <PopularClassCard {...item} />
              </Col>
            ))}
            <Filter params={params} basicParams={basicParams} setParams={setParams} data={categories} show={show} onHide={setShow} />
          </Row>
        </Col>

      </Row>


    </Container>
  );
}
