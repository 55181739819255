import { Col, Row } from "react-bootstrap";
import { paymentMethods, purchaseStatus } from "../../../../constants";
import { convertPhone } from "../../../../methods";

export default function ShowInfo({ data = {}, purchaseType = "" }) {
  const showPaymentMethod = (id = 0) => {
    return paymentMethods.find((e) => e.id === id)?.name;
  };
  const showPurchaseStatus = (id = "") => {
    const { name, color } = purchaseStatus.find((e) => e.id === id) || {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };
  const formControls = [
    {
      label: "نام و نام خانوادگی گیرنده",
      value: `${data?.user?.firstName} ${data?.user?.lastName}`,
      show: true
    },
    {
      label: "شماره تماس گیرنده",
      value: data?.user?.phone,
      show: true
    },
    {
      label: "نام محصول(ها)",
      value: data?.plan?.name,
      show: true
    },

    {
      label: "درصد تخفیف",
      color: "danger",
      value: data?.discount
        ? `${data?.discount} %`
        : "بدون تخفیف",
      show: true
    },
    {
      label: "قیمت نهایی",
      color: "primary",
      value: purchaseType === "USD" ? data?.plan?.price : data?.toomanAmount,
      show: true
    },
    {
      label: "نوع پرداخت",
      value: showPaymentMethod(purchaseType),
      show: true
    },
    {
      label: "وضعیت",
      value: showPurchaseStatus(data?.status.toLowerCase()),
      show: true
    },
    {
      label: "textId",
      value: data?.textId,
      show: purchaseType === "USD" ? true : false
    },

  ];
  return (
    <Row>
      {formControls.map((e) => (
        e.show && 
        <Col key={e.label} xs="12" md={e.label === "textId" ? "12" : "6"} className={`text-${e.color}`}>
          <span className="text-secondary">{e.label}:</span>{e.label === "textId" ? <br/> : null} {e.value}
        </Col>
      ))}

      {data?.spotLisence?.length > 0 &&
        <Col xs="12" md="12" className={`d-print-none`}>
          <span className="text-primary">لایسنس:</span> <br /> <span style={{ wordBreak: "break-all" }}>{data?.spotLisence[0]}</span>
        </Col>
      }

    </Row>
  )


}
