import { useState } from 'react'
import { axios } from '../../../boot'
import { AdminInput, Form } from '../../../components'
import { Col } from 'react-bootstrap'
// import './index.scss'
import { convertPhone, toast } from '../../../methods'
import logo from '../../../assets/logos/Marvel academy.svg'
import { rules } from '../../../constants'

const Eghtesad = () => {
    const [data, setData] = useState({})
    const formControls = [
        {
            label: "نام",
            labelColor: "black",
            state: "firstName"
        },
        {
            label: "نام خانوادگی",
            labelColor: "black",
            state: "lastName"
        },
        {
            label: "شماره تماس",
            type: "number",
            labelColor: "black",
            state: "phone"
        },
        {
            label: "آیدی تلگرام",
            labelColor: "black",
            state: "telegramid"
        },
        {
            label: "کدپستی",
            type: "number",
            labelColor: "black",
            state: "postalCode",
            rules : rules.postCode
        },
        {
            as: "textarea",
            labelColor: "black",
            label: "آدرس",
            state: "address",
        },
    ]
    const sendData = () => {
        const url = "https://api.academymarvel.com/api/class/EghtesadBookRegister"
        axios.post(url, data).then(({ data }) => {
            // console.log(data)
            let text = "در حال انتقال به درگاه پرداخت"
            toast({ type: "success", text })
            const link = data.url;
            window.location.href = link;
            setData({})
        })
    }
    return (
        <div className=" DENTAL row">
            <Col xs="12" lg="6">
                <Form onSubmit={sendData} className=" DENTAL-FORM row w-100 bg-white px-2 py-4 rounded shadow-lg my-2">
                    <img src={logo} alt='logo' />
                    {formControls.map((item) =>
                        <Col key={item.state} xs="12">
                            <AdminInput
                                className=" text-white rounded-4 border-dark-block fs-14 fw-400"
                                {...item}
                                value={data[item.state]}
                                setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
                            />
                        </Col>
                    )}
                    <p className="text-center text-primary fw-500 fs-12">هزینه پرداختی : 3 میلیون تومان</p>
                    <Col>
                        <button className='btn btn-primary text-white w-100 rounded-sm'>ثبت و پرداخت</button>
                    </Col>

                </Form>
            </Col>
        </div>
    )
}

export default Eghtesad