import { useState } from 'react'

import { Row, Col, Container } from 'react-bootstrap'
import Free1 from '../../../../assets/images/FreeTutorial/free1.png'
import World from '../../../../assets/images/FreeTutorial/world.png'
import Clock from '../../../../assets/images/FreeTutorial/clock.png'
import Level from '../../../../assets/images/FreeTutorial/level.png'
import StudentComments from '../StudentComments'
import TopicsTaught from '../TopicsTaught'

const AboutCourse = ({ data = {} }) => {
    return (
        <div id='aboutCourse' className="w-100">
            <Container>
                <TopicsTaught data={data} />
                <Row className="my-4">
                    <p className="fs-16 fw-700 my-2 text-white ">{data?.title}</p>
                    <div className="d-flex align-items-center">
                        <span><i className="bi bi-clock text-primary"></i></span>
                        <p className="text-primary fs-14 mx-2">{data?.time}</p>
                    </div>
                    <p className="fs-14 my-1 fw-500">{data?.totalSold} نفر شرکت کرده‌اند</p>
                    <p style={{ whiteSpace: "pre-wrap" }} className="fs-16 lh-md my-1 text-white">
                        {data?.description}
                    </p>
                </Row>
            </Container>


        </div>
    )
}

export default AboutCourse