import React, { useContext, useEffect, useState } from 'react'
import { convertPhone, toast } from '../../../methods';
import { Button, Form, Input, Modal } from '../../../components';
import { rules } from '../../../constants';
import { Col } from 'react-bootstrap';
import Context from './_context';
import { axios } from '../../../boot'
import Timer from '../../../components/NewVersion/Timer';
// import LogoText from '../../../assets/logos/logoLogin.png'
import logo from '../../../assets/logos/Marvel academy.svg'

const Sms = () => {
    const [page, setPage, chartData, setChartData] = useContext(Context);
    const [data, setData] = useState({});
    const [activeTab, setActiveTab] = useState("sms");
    const [show, setShow] = useState(false)
    const [phonee, setPhonee] = useState("")
    const smsFormControls = [
        {
            label: "شماره موبایل",
            labelColor: "light-gray",
            border: false,
            state: "phone",
            type: "number",
        },
    ];

    const verifyCodeControls = [

        {
            label: "کد ارسال شده",
            labelColor: "light-gray",
            border: false,
            state: "code",
            type: "number",
        },
    ];
    const formControls = { sms: smsFormControls, verify: verifyCodeControls };
    const sendCode = () => {
        let reg = new RegExp("^(\\+98|0)?9\\d{9}$")
        if (!reg.test(data.phone)) {
            return setShow(true)
        }
        const url = "https://api.academymarvel.com/api/class/sendChartCode";
        const body = { phone: data.phone }
        setPhonee(data?.phone)
        axios.post(url, body).then(() => {
            const text = "کد با موفقیت ارسال شد.";
            setActiveTab("verify")
            toast({ text });
        });
    };
    const verify = () => {
        const url = "https://api.academymarvel.com/api/class/getChart";
        const body = { phone: phonee, code: data.code };
        axios.post(url, body).then(({ data }) => {
            setChartData(data)
            setPage("charts")
        });
    };
    const handleSubmit = () => {
        if (activeTab === "verify") return verify();
        return sendCode();
    };
    // useEffect(() => setData({}), [activeTab]);
    return (<>
        <Modal title="خوش آمدید" size="md" show={show} onHide={setShow} >
            <div className="p-2">
                <p className="text-black my-2">در صورتی که خارج از کشور هستین برای ویرایش اطلاعات و دریافت چارت خود به تلگرام با آیدی زیر پیام دهید</p>
                <a className="text-primary d-flex align-items-center justify-content-center my-2 " href='https://t.me/academymarvel'>academymarvel <i className="bi bi-telegram text-primary mx-1"></i></a>
            </div>
        </Modal>

        <Form onSubmit={() => { handleSubmit() }} className="SIGNIN row py-2 d-flex flex-column align-items-center justify-content-center ">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                <img style={{ width: "115px", height: "93px" }} className="" src={logo} alt="logo" />
                <p className=" my-4 col-12 text-primary text-center fs-20 fw-700 my-1">چارت ستاره شناسی</p>
                {activeTab === "verify" && <Timer onActive={setActiveTab} time={60} />}
            </div>
            {formControls[activeTab].map((item) => (
                <Col key={item.state} xs="12">
                    <Input
                        className="bg-dark-black text-white rounded-4 border-dark-block fs-14 fw-400"
                        {...item}
                        value={data[item.state]}
                        setValue={(val) => setData((p) => ({ ...p, [item.state]: val }))}
                    />
                </Col>
            ))}
            <Col xs="12">
                {activeTab == "sms" ?
                    <Button type="submit" className="w-100">
                        دریافت کد عبور
                    </Button>
                    :
                    <Button type="submit" className="w-100">
                        ارسال کد
                    </Button>
                }
            </Col>
        </Form>
    </>
    )
}

export default Sms