import React from 'react'
import './index.scss'

const Ribbon = ({label = ""}) => {
  return (
    <div className="CommingSoon p-2  bg-primary">
        <p className="fs-15 fw-500 text-white mx-2 mb-1">{label}</p>
    </div>
  )
}

export default Ribbon