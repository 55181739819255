import React , { useState , useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import AdminInput from '../../../components/AdminInput'
import { Button, Form, Table } from '../../../components';
import { axios, moment } from '../../../boot'
import { useNavigate, useParams } from 'react-router';
import { accept } from '../../../constants';
import { toast } from '../../../methods';

const AstrologyChart = () => {
    const params = useParams();
    const navigate = useNavigate()
    const [data, setData] = useState({});
    const fromControls = [
        {
            label: "فایل",
            state: "file",
            type: "file",
            accept: accept.every,
        },

    ];
    const uploadChart = async () => {
        const url = "https://api.academymarvel.com/api/class/addChart";
        const body = new FormData();
        body.append("file", data?.file[0])
        body.append("_id", params.id)
        axios.post(url, body).then(() => {
            let text = "چارت با موفقیت آپلود شد"
            toast({text});
            navigate("/admin/astrologyCharts", { replace: true });
        });
    };
    return (
        <Form onSubmit={()=>uploadChart()} className="row">
            {fromControls.map((props, index) => (
                <Col key={index} xs="12" md={6}>
                    {React.createElement(props.tag ?? AdminInput, {
                        ...props,
                        value: data[props.state],
                        setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
                    })}
                </Col>
            ))}
            <div className="col-12 d-flex flex-center">
                <Button variant='primary' type="submit">ثبت</Button>
            </div>
        </Form>
    )
}

export default AstrologyChart