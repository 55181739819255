import { useState, useEffect, useRef } from 'react'

import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

// import required modules
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import NewTopicCard from '../../../../components/NewVersion/NewTopicCard';
import { axios } from '../../../../boot'
import { Link } from 'react-router-dom';

const NewReadingBook = ({ tutorials = [] }) => {
  const [newtopic, setNewTopic] = useState([])
  const getTutorials = () => {
    let data = tutorials?.filter(i => i.status !== "disable" && i?.ketab === true)?.map((e) => ({
      _id: e._id,
      image: e.fullScreenCover,
      title: e.category?.title_fa,
      time: e.time,
      lessons: e.numberOfSessions,
      courseTitle: e.title
    }));
    setNewTopic([...data]);
  };
  const swiperRef = useRef();
  SwiperCore.use([Navigation])
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  useEffect(getTutorials, [tutorials])
  return (
    <Container className="land-newTopic  my-4">
      <div className='d-flex align-items-center'>
        <p className="text-white fs-18 fw-700">کتابخوانی های جدید در مارول</p>
        <Link className="text-primary fs-14 fw-400 mx-2 d-flex align-items-center " to='/book-reading'>مشاهده همه</Link>
      </div>
      <div className="w-100 Comments-swiper d-flex align-items-center justify-content-between my-4">
        {/* <Col className="p-0" xs="0" sm="1" lg="1"> */}
        <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-gray1 text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slidePrev()}> <i className="bi bi-arrow-right"></i> </button>
        {/* </Col> */}
        {/* <Col className="p-0" xs="12" sm="10" lg="10"> */}
        <Swiper
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[Navigation]}
          className="mySwiper w-100 mx-2"
          breakpoints={{
            540: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {newtopic.map((item, index) => (
            <SwiperSlide key={index} className="h-auto">
              <NewTopicCard  {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
        {/* </Col> */}
        {/* <Col xs="0" sm="1" lg="1"> */}
        <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-gray1 text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slideNext()}> <i className="bi bi-arrow-left"></i> </button>
        {/* </Col> */}


      </div>
    </Container>
  )
}

export default NewReadingBook