
import { Container } from "react-bootstrap"
import "animate.css/animate.min.css"
import SpecialClass from '../../../components/SpecialClass';

export default function MainSection({tutorials = [] , products = []}) {
  return (
    // <AnimationOnScroll animateIn='animate__zoomIn animate__faster '>
      <Container className="main-section position-relative my-4 py-4">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="text-white fs-28 fw-700 text-center my-2">با مارول بی وقفه مهارت جدید یاد بگیر</p>
          <p className="text-white fs-18 fw-500 text-center my-2 lh-md">آموزش نامحدود با هزاران ویدئو آموزشی در زمینه های مختلف</p>
        </div>
        <SpecialClass tutorials={tutorials} products={products} />
      </Container>
    // </AnimationOnScroll>
  );
}
