const paymentMethods = [
  {
    name: "پرداخت ریالی",
    unit : "تومان",
    id: "IRR",
  },
  {
    name: "پرداخت با تتر",
    unit : "تتر",
    id: "USD",
  },
  {
    name: "کارت به کارت",
    unit : "کارت",
    id: "Card",
  },
];
export default paymentMethods;
