import React, { useEffect, useState } from "react";
import { ButtonGroup, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Form, AdminInput, Button, Select } from "../../../components";
import { accept, rules, indexTitles , blogStatus } from "../../../constants";
import { axios } from "../../../boot";
import { toast, fileToBase64, isFile, source } from "../../../methods";
import { cloneDeep } from 'lodash'
import { useGetFiles, useGetCategory , useGetTeacher } from '../../../hooks'
export default function Course() {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [comments, setComments] = useState([])
  const [questions, setQuestions] = useState([])
  const [topic, setTopic] = useState([])
  const [files, getFiles] = useGetFiles()
  const [categories, getCategories] = useGetCategory()
  const [ teachers , getTeachers ] = useGetTeacher()

  const isNewCourse = params.courseId === "new";

  const handleType = [
    {
      name: "ویدیو",
      type: "video"
    },
    {
      name: "آزمون",
      type: "Quiz"
    },
    {
      name: "مقاله",
      type: "Article"
    },
  ]

  const fromControls = [
    {
      label: "موضوع",
      state: "title",
    },
    {
      label: "قیمت ریالی",
      state: "price",
      type: "number"
    },
    {
      label: "قیمت تتری",
      state: "priceUsd",
      type: "number"
    },
    {
      label: "تخفیف ریالی",
      state: "off",
      type: "number"
    },
    {
      label: "تخفیف تتری",
      state: "offUsd",
      type: "number"
    },
    {
      label: "تعداد جلسات",
      state: "numberOfSessions",
      type: "number"
    },
    {
      tag: Select,
      label: "پرداخت اقساطی",
      state: "installment",
      items: blogStatus,
      as : AdminInput
    },
    {
      label: "تعداد اقساط",
      state: "numberOfInstallments",
      type: "number"
    },
    {
      tag: Select,
      label: "عکس کاور ",
      state: "cover",
      as :AdminInput,
      items: files?.map((e) => ({ name: e.title, id: source(e.file?.slice(1)) })),
      required : rules.optional
    },
    {
      tag: Select,
      label: "(کامل)عکس کاور ",
      state: "fullScreenCover",
      as : AdminInput,
      items: files?.map((e) => ({ name: e.title, id: source(e.file?.slice(1)) })),
      required : rules.optional
    },
    {
      tag: Select,
      label: "پیش نمایش دوره",
      state: "preview",
      as : AdminInput,
      items: files?.map((e) => ({ name: e.title, id: source(e.file?.slice(1)) })),
      required : rules.optional
    },
    // {
    //   label: "شماره دوره",
    //   state: "index",
    //   type: "number",
    // },
    
    {
      label: "کلید اسپات پلیر",
      state: "spotkey",
    },
    {
      tag: Select,
      label: "نام مدرس",
      state: "teacher",
      as : AdminInput,
      items : teachers?.map((e) => ({name : e.name , id : e._id}))
    },
    {
      label: "مدت زمان دوره",
      state: "time",

    },
    {
      tag: Select,
      label: "دسته بندی",
      state: "category",
      as : AdminInput,
      items: categories?.map((e) => ({ name: e.title_fa, id: e._id })),
    },
    // {
    //   label: "حداکثر زمان مورد نیاز(ساعت)",
    //   state: "time",

    //   rules: [
    //     (val = "") => +val > +data.minTime || "مفدار باید بزرگتر از حداقل باشد",
    //   ],
    // },
    // {
    //   label: "ویدیو نمونه",
    //   state: "video",
    //   type: "file",
    //   accept: accept.video,
    // },
    {
      label: "توضیحات",
      state: "description",
      as: "textarea",
      rules: rules.description,
    },
  ];

  const commentsFormControls = [
    {
      label: "نام",
      state: "name",
    },
    {
      label: "عکس",
      state: "avatar",
      type: "file"
    },
    {
      label: "نظر کاربر",
      state: "content",
      as: "textarea",
    },
  ]

  const questionFormControl = [
    {
      label: "سوال",
      state: "title",
    },
    {
      label: "جواب",
      state: "content",
      as: "textarea",
    },
  ]

  const topicsFormControl = [
    {
      label: "نام",
      state: "name",
    },
    {
      label: "زمان",
      state: "time",
    },
    {
      label: "تعداد جلسات",
      state: "numberOfSessions",
    },
    {
      label: "توضیحات",
      state: "description",
    },
  ]

  const sessionsFormControl = [
    {
      label: "نام",
      state: "name",
    },
    {
      label: "زمان",
      state: "time",
    },
    {
      label: "نوع",
      state: "type",
      tag: Select,
      as : AdminInput,
      items: handleType.map((e) => ({ name: e.name, id: e.type }))

    },
  ]

  const getData = () => {
    if (!isNewCourse) {
      const url = `/admins/pub/shop/courses/${params.courseId}`;
      axios.get(url).then(({ data }) => {
        console.log(data)
        const body = {}
        body.time = data.time
        body.category = data.category?._id
        body.price = data.price
        body.priceUsd = data.priceUsd
        body.off = data.off
        body.offUsd = data.offUsd
        body.description = data.description
        body.numberOfSessions = data.numberOfSessions
        body.teacher = data?.teacher?._id
        body.spotkey = data.spotkey
        body.comments = data.comments
        body.FrequentlyAskedQuestions = data.FrequentlyAskedQuestions
        body.topic = data.topic
        body.title = data.title
        body.cover = data.cover
        body.fullScreenCover = data.fullScreenCover
        body.preview = data.preview
        body.installment = data.installment ? "enabled" : "disabled"
        body.numberOfInstallments = data.numberOfInstallments

        setComments(data?.comments)
        setQuestions(data?.FrequentlyAskedQuestions)
        setTopic(data?.topic)


        setData(body);
      });
    }
  };
  const addNewCourse = async () => {
    const url = "/admins/pub/shop/courses";
    const body = {...data}
    body.comments = comments ? await handleCommentsFile()  :  []
    body.topic = await handleTopicFile() ?? []
    body.FrequentlyAskedQuestions = await handleQuestionsFile() ?? []
    body.status = "enable"
    
    // const body = new FormData();
    // const files = ["video", "thumbnail"];
    // Object.keys(data).forEach((key) => {
    //   if (files.includes(key)) {
    //     body.append(key, data[key][0]);
    //   } else {
    //     body.append(key, data[key]);
    //   }
    // });
    axios.post(url, body).then(() => {
      toast({});
      navigate("/admin/courses", { replace: true });
    });
  };
  const changeCourse = async () => {
    const url = "/admins/pub/shop/courses";
    console.log("data in change : " , data)
    // const body = new FormData();
    const bodyData = { ...data };
    delete bodyData.createdAt;
    delete bodyData.modifiedAt;
    delete bodyData.index;
    bodyData._id = params.courseId
    bodyData.status = "enable"
    bodyData.comments = comments ? await handleCommentsFile()  :  []
    bodyData.topic = await handleTopicFile() ?? []
    bodyData.FrequentlyAskedQuestions = await handleQuestionsFile() ?? []


    // bodyData.video = bodyData.video[0];
    // if (body.thumbnail) {
    //   bodyData.thumbnail = bodyData.thumbnail[0];
    // }
    // Object.keys(bodyData).forEach((key) => {
    //   body.append(key, bodyData[key]);
    // });
    axios.put(url, bodyData).then(() => {
      toast({});
    });
  };
  const deleteCourse = () => {
    const message = "آیا از درخواست خود اطمیمان دارید؟";
    if (window.confirm(message)) {
      const url = "/admins/pub/shop/courses";
      const body = { _id: params.courseId };
      axios.delete(url, { data: body }).then(() => {
        toast({});
        navigate("/admin/courses", { replace: true });
      });
    }
  };

  ////////// کامنت ها

  const handleAddNewComments = () => {
    setComments((p) => [...p, { name: "", avatar: "", content: "" }]);
  };
  const handleDeleteComments = (index = 0) => {
    setComments((p) => {
      const cm = [...p];
      cm.splice(index, 1);
      return cm;
    });
  };

  const handleCommentsFile = async () => {
    const bodyComments = await Promise.all(
      comments.map(async (e) => {
        const avatar = e.avatar[0];
        console.log("avaataaar : " , avatar)
        
        return {
          ...e,
          avatar: isFile(avatar) ? await fileToBase64(avatar) : undefined,
        
        };
      })
    );
    return bodyComments;
  };

  ////////// کامنت ها


  ////////// سوالات متداول

  const handleAddNewQuestion = () => {
    setQuestions((p) => [...p, { title: "", content: "" }]);
  };
  const handleDeleteQuestion = (index = 0) => {
    setQuestions((p) => {
      const q = [...p];
      q.splice(index, 1);
      return q;
    });
  };

  const handleQuestionsFile = async () => {
    const bodyQuestion = await Promise.all(
      questions.map(async (e) => {
        const img = e.img?.at(0);
        const vid = e.vid?.at(0);
        return {
          ...e,
          img: isFile(img) ? await fileToBase64(img) : undefined,
          vid: isFile(vid) ? await fileToBase64(vid) : undefined,
        };
      })
    );
    return bodyQuestion;
  };

  //////////  سوالات متداول



  ////////// موضوعات 

  const handleAddNewTopic = () => {
    setTopic((p) => [...p, { name: "", time: "", numberOfSessions: "" }]);
  };
  const handleDeleteTopic = (index = 0) => {
    setTopic((p) => {
      const q = [...p];
      q.splice(index, 1);
      return q;
    });
  };

  const handleTopicFile = async () => {
    const bodyTopics = await Promise.all(
      topic.map(async (e) => {
        const img = e.img?.at(0);
        const vid = e.vid?.at(0);
        return {
          ...e,
          img: isFile(img) ? await fileToBase64(img) : undefined,
          vid: isFile(vid) ? await fileToBase64(vid) : undefined,
        };
      })
    );
    return bodyTopics;
  };

  //////////  موضوعات 



  
  ////////// جلسات 

  const handleAddNewSession = (index) => {
    setTopic((p) => {
      const top = [...p]
      console.log("topppppp : " , top[index])
      if(!top[index].sessions) top[index].sessions = []
      top[index].sessions?.push({name : "" , type : "" , time : ""})
      return top
    })
  };
  const handleDeleteSession = (fullIndex,index = 0) => {
    setTopic((p) => {
      const q = [...p];
      q[fullIndex].sessions?.splice(index, 1);
      return q;
    });
  };



  //////////  جلسات 

  


  useEffect(getTeachers, []);
  useEffect(getFiles, []);
  useEffect(getCategories, []);
  useEffect(getData, []);
  return (
    <Form onSubmit={isNewCourse ? addNewCourse : changeCourse} className="row">
      <h5 className="text-center col-12">
        {isNewCourse ? "اضافه کردن دوره جدید" : "اطلاعات دوره"}
      </h5>
      {fromControls.map((props, index) => (
        <Col key={index} xs="12" md={index === 15 ? "12" : "6"}>
          {React.createElement(props.tag ?? AdminInput, {
            ...props,
            value: data[props.state],
            setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
          })}
        </Col>
      ))}


      {/* افزودن کامنت جدید */}
      {comments.map((item, fullIndex) => (
        <React.Fragment key={fullIndex}>
          <div className="col-12 d-flex flex-center gap-2">
            <button
              onClick={() => handleDeleteComments(fullIndex)}
              className="bi bi-x-lg d-flex flex-center text-danger"
            />
            <h5>{`کامنت ${indexTitles[fullIndex + 1]}`}</h5>
          </div>
          {commentsFormControls.map((props, i) => (
            <Col key={i} xs="12" lg={i === 3 ? "12" : "6"}>
              <AdminInput
                {...props}
                value={item[props.state]}
                setValue={(val) =>
                  setComments((p) => {
                    const full = [...p];
                    full[fullIndex][props.state] = val;
                    return full;
                  })
                }
              />
            </Col>
          ))}
        </React.Fragment>
      ))}

      <div className="col-12">
        <button
          type="button"
          className="bi bi-plus-lg text-success"
          onClick={handleAddNewComments}
        >
          افزودن کامنت جدید
        </button>
      </div>

      {/* افزودن سوالات متداول جدید */}

      {questions.map((item, fullIndex) => (
        <React.Fragment key={fullIndex}>
          <div className="col-12 d-flex flex-center gap-2">
            <button
              onClick={() => handleDeleteQuestion(fullIndex)}
              className="bi bi-x-lg d-flex flex-center text-danger"
            />
            <h5>{`سوال ${indexTitles[fullIndex + 1]}`}</h5>
          </div>
          {questionFormControl.map((props, i) => (
            <Col key={i} xs="12" lg="12" >
              <AdminInput
                {...props}
                value={item[props.state]}
                setValue={(val) =>
                  setQuestions((p) => {
                    const full = [...p];
                    full[fullIndex][props.state] = val;
                    return full;
                  })
                }
              />
            </Col>
          ))}
        </React.Fragment>
      ))}

      <div className="col-12">
        <button
          type="button"
          className="bi bi-plus-lg text-success"
          onClick={handleAddNewQuestion}
        >
          افزودن سوال جدید
        </button>
      </div>

      {/* افزودن  سرفصل جدید */}

      {topic?.map((item, fullIndexTopic) => (
        <React.Fragment key={fullIndexTopic}>
          <div className="col-12 d-flex flex-center gap-2">
            <button
              onClick={() => handleDeleteTopic(fullIndexTopic)}
              className="bi bi-x-lg d-flex flex-center text-danger"
            />
            <h5>{`سرفصل ${indexTitles[fullIndexTopic + 1]}`}</h5>
          </div>
          {topicsFormControl.map((props, i) => (
            <Col key={i} xs="12" lg="12" >
              <AdminInput
                {...props}
                value={item[props.state]}
                setValue={(val) =>
                  setTopic((p) => {
                    const full = [...p];
                    full[fullIndexTopic][props.state] = val;
                    return full;
                  })
                }
              />
            </Col>
          ))}

          {item.sessions?.map((e, fullIndex) => (
            <React.Fragment key={fullIndex}>
              <div className="col-12 d-flex flex-center gap-2">
                <button
                  onClick={() => handleDeleteSession(fullIndexTopic , fullIndex)}
                  className="bi bi-x-lg d-flex flex-center text-danger"
                />
                <h5>{`جلسه ${indexTitles[fullIndex + 1]}`}</h5>
              </div>
              {sessionsFormControl.map((props, i) => (
                 <Col key={i} xs="12" lg="12" >
                 <AdminInput
                   {...props}
                   value={e[props.state]}
                   setValue={(val) =>

                     setTopic((p) => {
                       const full = [...p];
                       full[fullIndexTopic]["sessions"][fullIndex][props.state] = val;
                       return full;
                     })
                   }
                 />
               </Col>
              ))}
            </React.Fragment>
          ))}
          <div className="col-12">
            <button
              type="button"
              className="bi bi-plus-lg text-success"
              onClick={()=>handleAddNewSession(fullIndexTopic)}
            >
              افزودن جلسه جدید
            </button>
          </div>
        </React.Fragment>
      ))}

      <div className="col-12">
        <button
          type="button"
          className="bi bi-plus-lg text-success"
          onClick={handleAddNewTopic}
        >
          افزودن سرفصل جدید
        </button>
      </div>

      <div className="col-12 d-flex gap-1 flex-column flex-center">
        <Button type="submit">ثبت</Button>
        <ButtonGroup>
          {!isNewCourse && (
            <React.Fragment>
              {/* <Link
                className="Button btn btn-dark"
                to={{
                  pathname: "/admin/sessions",
                  search: `courseId=${params.courseId}`,
                }}
              >
                جلسات دوره
              </Link> */}
              <Button variant="danger" onClick={deleteCourse}>
                حذف
              </Button>
              {/* <Link
                className="Button btn btn-dark"
                to={{
                  pathname: "/admin/exams",
                  search: `courseId=${params.courseId}`,
                }}
              >
                آزمون‌های دوره
              </Link> */}
            </React.Fragment>
          )}
        </ButtonGroup>
      </div>
    </Form>
  );
}
