import React , { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Row , Col } from 'react-bootstrap'
import { Table , Pagination , AdminInput , Select } from '../../../components'
import { axios, moment } from "../../../boot"
import { adviceStatus } from '../../../constants'
import { toast } from '../../../methods'

const Advices = () => {
  const basicParams = JSON.parse(sessionStorage.getItem("params")) ?? {};
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1, ...basicParams });
  const [advices, setAdvices] = useState([])
  const [pages, setPages] = useState({});
  const getAdvices = () => {
    const url = "/admins/class"
    const config = {
      params: {
        sort: "createdAt:-1",
        perPage: 20,
        ...params,
      },
    };
    axios.get(url,config).then(({ data }) => {
      setAdvices(data.data)
      setPages(data.pages);
    })
  }
  const formControls = [
    
    {
      tag: Select,
      as : AdminInput,
      label: "وضعیت",
      state: "status",
      cancelBtn: true,
      items: adviceStatus.filter((e) => e.filterable),
    },
  ];
  const showStatus = (id = 0) => {
    const { color, name } = adviceStatus.find((e) => e.id === id) ?? {};
    return <span className={`text-${color} fw-bold`}>{name}</span>;
  };
  useEffect(getAdvices, [params])
  const handleClick = (event , id) => {
    let el = event.target.id
    if(el === "sendMessage" || el === "sendMessageBtn" || el === "telegram" || el === "telegramBtn" || el === "phone"){
      return
    }else{
      navigate(id) 
    }
  }
  const InstantSMS = (id) => {
    const url = `/admins/${id}/smsclass`
    axios.get(url).then(({data})=>{
      // console.log(data)
      let text = data?.message
      toast((text))
    })
  }
  const sendtoTelegram = (id) => {
    const url = `/admins/${id}/smstelegram`
    axios.get(url).then(({data})=>{
      // console.log(data)
      let text = data?.message
      toast((text))
    })
  }
  
  return (
    <Row className="align-items-stretch">
      <div className="col-12 d-flex justify-content-end">
        <Link
          to="new"
          className="bi bi-plus-lg d-flex flex-center fs-4 text-success"
        />
      </div>
      <Row className="d-print-none">
        {formControls.map((e) => (
          <Col key={e.state} xs="12" md="6" lg="4">
            {React.createElement(e.tag ?? AdminInput, {
              ...e,
              value: params[e.state],
              setValue: (val) => {
                setAdvices([]);
                setParams((p) => ({ ...p, page: 1, [e.state]: val }));
              },
            })}
          </Col>
        ))}
      </Row>
      <Table>
        <thead>
          <tr>
            <th>نام </th>
            <th>نام خانوادگی</th>
            <th>شماره همراه</th>
            <th>میزان سرمایه ریالی</th>
            <th>میزان سرمایه دلاری</th>
            <th>وضعیت</th>
            <th>زمان مشاوره</th>
            <th>پیامک فوری</th>
            <th>ارجاع به تلگرام</th>
          </tr>
        </thead>
        <tbody>
          {advices?.map((e) => (
            <tr key={e._id} onClick={(i) => handleClick(i,e._id)}>
              <td>{e.firstName}</td>
              <td>{e.lastName}</td>
              <td id='phone'>{e.phone}</td>
              <td className="text-success">{e.capital} تومان</td>
              <td className="text-success">{e.capitalUSD} تتر</td>
              <td>{showStatus(e.status)}</td>
              <td>{moment.miladiToShamsi({ date: e?.time , format : " HH:mm  jYYYY/jMM/jDD" })}</td>
              <td id='sendMessage'><button onClick={() => InstantSMS(e._id)} id="sendMessageBtn" className="btn btn-sm btn-primary px-2 py-1">ارسال</button></td>
              <td id='telegram'><button onClick={() => sendtoTelegram(e._id)} id="telegramBtn" className="btn btn-sm btn-success text-white px-2 py-1">ارجاع به تلگرام</button></td>

            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        totalPages={pages.totalPages}
        activePage={params.page}
        setActivePage={(page) => setParams((p) => ({ ...p, page }))}
      />
    </Row>
  )
}

export default Advices