import React, { useRef, useState, useEffect } from 'react'

import { Col, Row, Container } from 'react-bootstrap'
import { PopularClassCard } from '../../../../components/NewVersion'
import { Link, NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import SwiperCore, { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper'
const SubjectClass = ({ data = [] }) => {
    const swiperRef = useRef();
    SwiperCore.use([Navigation])
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    return (
        <div className="land-PopularClass">
            <Container className="d-flex flex-wrap align-items-center justify-content-md-between justify-content-start">
                <div>
                    <p className=" text-center text-lg-right fw-700 fs-18 lh-md text-white  d-lg-inline-block mx-2">پرطرفدارترین کلاس های {data[0]?.category?.title_fa}</p>
                    <Link className="text-primary fs-14 fw-400 mx-2 d-lg-inline-block " to='/tutorials'>مشاهده همه</Link>
                </div>
                {data?.length > 4 &&
                    <div className="d-flex">
                        <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-dark-black text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slidePrev()}> <i className="bi bi-arrow-right-short fs-20"></i> </button>
                        <button style={{ width: "25px", height: "25px" }} className="d-none d-lg-flex justify-content-center align-items-center bg-dark-black text-white  mx-1 rounded-circle p-4" onClick={() => swiperRef.current?.slideNext()}> <i className="bi bi-arrow-left-short fs-20"></i> </button>
                    </div>
                }
            </Container>
            {data?.length > 4
                ?
                <div className=" PopularClass-swiper w-100 d-flex  justify-content-between align-items-center py-2 ">
                    <Swiper
                        className="w-100 py-4"
                        // centeredSlides={true}
                        spaceBetween={10}
                        initialSlide={1}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Scrollbar, Pagination]}
                        breakpoints={{
                            1200: {
                                slidesPerView: 5,
                            },
                            992: {
                                slidesPerView: 4,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            0: {
                                slidesPerView: 2,
                            },
                        }}
                        slidesPerView={3}
                        autoplay={{
                            delay: 2500,
                        }}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                    // scrollbar={{ draggable: true }}
                    >
                        {data?.map((item) =>
                            <SwiperSlide key={item._id} className="h-auto d-flex justify-content-center">
                                <PopularClassCard {...item} />
                            </SwiperSlide>
                        )}

                    </Swiper>
                </div>
                :
                <Row>
                    {data?.map((item) =>
                        <Col xs="12" md="6" lg="3" key={item._id} className="h-auto d-flex justify-content-center">
                            <PopularClassCard {...item} />
                        </Col>
                    )}
                </Row>
            }
        </div>
    )
}

export default SubjectClass