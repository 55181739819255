import React, { useState } from 'react'

import './index.scss'
import AddChart from './AddChart'
import Context from './_context'
import { Col } from 'react-bootstrap'
import { Modal } from '../../../components'
import Charts from './Charts'
import Sms from './Sms'
const AstrologyChart = () => {
    const [page, setPage] = useState("signIn")
    const [ chartData , setChartData ] = useState([])
    return (
        <div className="ASTROLOGY row py-4 px-2">
            <Col style={{backgroundColor : "#1B1B1C"}} className='rounded p-4' xs="12" lg={page === "signIn" ? "6" : "10"}>
                <Context.Provider value={[page, setPage , chartData , setChartData]}>
                    {page === "signIn" && <Sms />}
                    {page === "charts" && <Charts />}
                    {page === "add" && <AddChart />}
                </Context.Provider>
            </Col>

        </div>
    )
}

export default AstrologyChart