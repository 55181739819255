import React from 'react'
import './index.scss'
import logo from "../../../assets/logos/logo.svg";
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { PaymentError } from '../../../components';
import hToast from "react-hot-toast";
import { toast } from '../../../methods';
import {axios} from "../../../boot"
const Order = () => {
    const { state } = useLocation();
    const buyCourse = () => {
        const url = "/purchases/buyCourse";
        const body = {};
        body.courseId = state?._id
        body.installment = state?.buyInstallment
        axios.post(url, body).then(({ data }) => {
            const link = data.url;
            window.location.href = link;
            const text = "در حال انتقال یه صفحه پرداخت";
            toast({ text, duration: 10000 });
        })
        // .catch((e) => {
        //         hToast(
        //             ({ id }) => <PaymentError id={id} tryAgain={buyCourse} />,
        //             { duration: 30000 }
        //         );
        // });
    }
    return (
        <div className="ORDER d-flex flex-column flex-center bg-black">
            <div className="Order-header d-flex align-items-start py-5">
                <img loading='lazy' src={logo} alt='logo' width={60} />
                <div className='mx-1'>
                    <p className="text-white fs-25 fw-600">آکادمی مارول</p>
                    <p className="text-white fs-14 fw-600">academymarvel.com</p>
                </div>
            </div>
            <div className="Order-body">
                <Container style={{ borderRadius: "16px" }} className="bg-dark-black my-4 py-2">
                    <Row>
                        <Col xs="12">
                            <img className="Order-img" src={state?.fullScreenCover} alt='course image' />
                        </Col>
                        <Col xs="12">
                            <p className="text-white fs-16 fw-700 mb-4">{state?.title}</p>
                            <hr className="bg-secondary" />
                        </Col>
                        <Col>
                            <div className="d-flex align-items-center justify-content-between mt-4">
                                <p className='text-white fw-700'>قیمت اصلی</p>
                                <p className=' fw-700'>{`${state?.price?.toLocaleString()} تومان `}</p>
                            </div>
                        </Col>
                        <div className="Order-slice d-flex flex-center">
                            <span></span>
                        </div>
                        <Col className="my-2">
                            <div className="d-flex align-items-center justify-content-between ">
                                <p className='text-white fs-18 fw-700'>مبلغ قابل پرداخت</p>
                                <p className='fw-700 fs-18 text-success'>{`${state?.buyInstallment ? state?.priceInstallment?.toLocaleString() : state?.price?.toLocaleString()} تومان `}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="px-0">
                    <button type='button' onClick={() => buyCourse()} className="w-100 btn BuyBtn text-white">
                        تکمیل خرید
                    </button>
                    <div className="w-100 d-flex flex-center my-2">
                        <Link to={`/tutorials/${state?._id}`} className="text-white">انصراف از خرید و بازگشت</Link>
                    </div>
                </Container>

            </div>

        </div>
    )
}

export default Order