import { useEffect, useState } from "react";
import MainSection from "./MainSection";
import Services from "./Services";
// import Subscriptions from "./Subscriptions";
import PopularClass from "./newVersion/PopularClass";
import Blogs from "./Blogs";
import Comments from "./Comments";
// import { BgImage } from "../../../components";
import Products from "./Products";
import "./index.scss";
// import TopicsTaught from "./newVersion/TopicsTaught";
import NewTopic from "./newVersion/NewTopic";
// import BestLeader from "./newVersion/BestLeader";
import AnimateCource from "./newVersion/AnimateCource";
import Subjects from "./newVersion/Subjects";
import { axios } from '../../../boot'
import NewReadingBook from "./newVersion/NewBookReading";

export default function Home() {
  const [tutorials, setTutorials] = useState([])
  const [products, setProducts] = useState([])
  
  const getTutorial = () => {
    const url = "/pub/shop/courses"
    axios.get(url).then(({ data }) => {
      setTutorials(data?.data)
    })
  }
  const getProducts = () => {
    const url = "/pub/shop/products";
    axios.get(url).then((res) => {
      setProducts(res?.data?.data)
      // const data = res?.data?.data?.filter(i => i.status !== "disable")?.map((e) => ({
      //   ...e,
      //   _id: `/products/${e._id}`,
      // }));
      // setProducts(data);
    });
  };
  useEffect(getTutorial, [])
  useEffect(getProducts, [])
  return (
    <div className="Home d-flex flex-column  ">
      <AnimateCource />
      <MainSection tutorials={tutorials} products={products} />
      <Subjects />
      {/* <PopularClass/> */}
      {/* <TopicsTaught/> */}
      <NewTopic tutorials={tutorials} />
      {/* <BestLeader/> */}

      {/* <div className="container position-relative d-flex justify-content-between mt-n5">
        <BgImage position="static" type={10} width="175" className="mb-auto" />
        <BgImage position="static" type={7} width="115" className="mt-auto" />
      </div> */}
      {/* <Subscriptions /> */}
      <Products products={products} />
      <NewReadingBook tutorials={tutorials} />
      {/* <Comments /> */}
      <Services />
      {/* <Blogs /> */}

    </div>
  );
}
