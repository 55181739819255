import React from "react";
import { Col } from "react-bootstrap";
import { useQuery } from "../../../hooks";
import okIcon from "../../../assets/icons/receipt ok.svg";
import nokIcon from "../../../assets/icons/receipt nok.svg";

const CooperativeReceipt = () => {
    const { status, refId } = useQuery();
    const isOk = status === "OK";
    return (
        <div className="py-5 row">
            <Col xs="12" md="10" lg="8">
                <div className="wrapper d-flex flex-column flex-center text-center gap-3 p-3">
                    {isOk ? (
                        <React.Fragment>
                            <img
                                width="75"
                                className="d-block mx-auto"
                                src={okIcon}
                                alt="status-ok"
                            />
                            <h4 className="text-primary">پرداخت شما با موفقیت ثبت شد</h4>
                            <p className="text-success">
                                <i className={`bi bi-ticket-detailed ms-1`} />
                                شماره سفارش : {refId}
                            </p>
                            <p>کارشناسان ما به زودی با شما تماس خواهند گرفت</p>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <img
                                width="100"
                                className="d-block mx-auto"
                                src={nokIcon}
                                alt="status-ok"
                            />
                            <h4 className="text-danger">متاسفانه پرداخت شما ناموفق بود!</h4>
                            <p className="text-warning">
                                <i className="bi bi-exclamation-triangle-fill ms-1" />
                                درصورت عدم پرداخت تا 1 ساعت دیگر تمام این سفارش به صورت خودکار
                                لغو خواهد شد.
                            </p>
                        </React.Fragment>
                    )}
                </div>
            </Col>
        </div>
    );
}

export default CooperativeReceipt