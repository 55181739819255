import { Navigate } from "react-router";
import { Landing, LoginLayout, UsersPanel, AdminPanel } from "../layouts";

import LoginLayoutSignIn from "../pages/LoginLayout/SignIn";
import LoginLayoutSignUp from "../pages/LoginLayout/SignUp";

import adminRoutes from "./adminRoutes";
import userRoutes from "./userRoutes";
import landingRoutes from "./landingRoutes";
import AccountantLayout from "../layouts/Accountants";
import accountantRoutes from "./accountantRoutes";
import AccountantLogin from "../pages/LoginLayout/AccountantLogin";
import Dental from "../pages/noLayout/Dental";
import Eghtesad from "../pages/noLayout/Eghtesad";
import AstrologyChart from "../pages/noLayout/AstrologyChart";
import Order from "../pages/Landing/Order";
import CartCountProvider from "../contexts/CartCount";

const NeedAuth = () => <Navigate to="/" replace />;

const routes = (isLogged = false, role = "") => {
  const checkRole = (roles = []) =>
    isLogged && ["Q9^B%^HWUc", ...roles].includes(role);
  return [
    {
      path: "/",
      element: <Landing />,
      children: landingRoutes(isLogged),
    },
    {
      path: "/accountantLogin",
      element: <LoginLayout />,
      children: [
        {
          path: "",
          element: <AccountantLogin />,
        },
      ],
    },
    {
      path: "/",
      element: checkRole(["student","6e5KXjhLJ", "unregistered"]) ? (
        <UsersPanel />
      ) : (
        <NeedAuth />
      ),
      children: userRoutes,
    },
    {
      path: "/admin",
      element: checkRole() ? <AdminPanel /> : <NeedAuth />,
      children: adminRoutes,
    },
    {
      path: "/accountant",
      element: checkRole(["6e5KXjhLJ", "unregistered"]) ? <AccountantLayout /> : <NeedAuth />,
      children: accountantRoutes,
    },
    {
      path: "/",
      children : [
        {path : "dental" , element : <Dental/>},
        {path : "eghtesadbook" , element : <Eghtesad/>},
        {path : "AstrologyChart" , element : <AstrologyChart/>},
        isLogged && {path : "order" , element : <CartCountProvider><Order/></CartCountProvider>}
      ]
    },
    {
      path: "*",
      element: <h1 className="text-center display-1 py-5">404</h1>,
    },
  ];
};
export default routes;
