import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Pagination, Table } from "../../../components";
import { axios } from "../../../boot";
import { indexTitles } from "../../../constants";
import { convertTime } from "../../../methods";
import { useObserved } from "../../../hooks";
import './Video.scss'
import Introduction from "./Introduction";
import AboutCourses from "./AboutCourses";
import StudentComments from "./StudentComments";
import TopicsTaught from "./TopicsTaught";
import ShowPrice from "../../../components/ShowPrice";
import { useSelector } from "react-redux";
export default function Tutorial() {
  const { observed } = useObserved();
  const navigate = useNavigate();
  const urlParams = useParams();
  const [tutorials, setTutorials] = useState([]);
  const [pages, setPages] = useState({});
  const [params, setParam] = useState({
    // sort: "createdAt:-1",
    // perPage: 6,
    // page: 1,
    category: urlParams.categoryId,
  });
  const isLogged = useSelector(s => s.isLogged)
  // const sortItems = [
  //   {
  //     name: "جدیدترین‌ها",
  //     id: "createdAt:-1",
  //   },
  //   {
  //     name: "اولویت بالا",
  //     id: "priority:-1",
  //   },
  //   {
  //     name: "پر بازدیدترین‌ها",
  //     id: "views:-1",
  //   },
  // ];

  const getTutorials = () => {
    let url = ""
    if(isLogged){
       url = `/pub/shop/usercourses/${urlParams.categoryId}`
    }else{
      url = `/pub/shop/courses/${urlParams.categoryId}`;
    }
    axios.get(url).then(({ data }) => {
      setTutorials(data);
      setPages(data.pages);
    });
  };

  useEffect(getTutorials, []);
  return (
    <div style={{overflowX : "none"}} className="w-100 FREETUTORIALS-VIDEO">
      <Introduction data={tutorials} />
      <AboutCourses data={tutorials ?? {}} />
      {/* <StudentComments/>
      <TopicsTaught/> */}
      <ShowPrice data={tutorials} />
    </div>

  );
}
