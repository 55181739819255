const astrologyStatus = [
    {
      name: "پرداخت شده",
      id: "paid",
      color: "primary",
    },
    {
        name: "درحال ساخت چارت",
        id: "exported",
        color: "warning",
      },
      {
        name: "تحویل داده شده",
        id: "uploaded",
        color: "success",
      },
  ];
  export default astrologyStatus;
  