import { useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { navItems } from "../../constants";
import { useShowFullName } from "../../hooks";
import { useSelector } from "react-redux";
// import Newlogin from "../../pages/LoginLayout/NewLogin";
import logo from "../../assets/logos/logo.svg";


export default function Menu({
  show = false,
  onHide = () => { },
  signInPath = "",
}) {
  const showFullName = useShowFullName();
  const sidebar = useRef();
  const location = useLocation();
  const isLogged = useSelector((s) => s.isLogged)
  const handleShow = () => {
    sidebar.current.classList.toggle("active", show);
  };
  const handleLocation = () => {
    onHide(false);
  };
  useEffect(handleShow, [show]);
  useEffect(handleLocation, [location.pathname]);
  return (
    <div
      ref={sidebar}
      className="sidebar-toggle d-flex d-xl-none position-fixed top-0 left-0 w-100 h-100 overflow-hidden"
    >

      <div className="menu bg-dark bg-opacity-90 d-flex flex-column gap-3 h-100">
        <img width="50" src={logo} alt="logo" className="d-block d-lg-none mx-auto my-4" />
        {navItems.landing.map((item, index) => (
          <NavLink
            key={index}
            to={item.path}
            className={({ isActive }) =>
              `text-${isActive ? "white" : "gray-white"} w-100 text-start py-3 px-2`
            }
          >
            {item.label}
          </NavLink>
        ))}
        {/* <li><a className="fs-16 fw-500 text-gray-white px-2 py-3" href="https://academymarvel.com/blog/">بلاگ</a></li> */}
        <li><a className="fs-16 fw-500 text-gray-white px-2 py-3" href="https://careers.academymarvel.com/">فرصت های شغلی</a></li>

        {isLogged ?
          <li>
            <Link to={signInPath} className="Button btn text-primary d-lg-none">
              {showFullName()}
            </Link>
          </li>
          :
          <NavLink
            to="/cart"
            className={({ isActive }) =>
              `text-${isActive ? "white" : "gray-white"} w-100 text-start py-3 px-2`
            }
          >
            سبد خرید
          </NavLink>
        }

      </div>
      <button
        className="hide-btn flex-1 opacity-0"
        onClick={() => onHide(false)}
      ></button>
    </div>
  );
}
