import { useState } from "react";
import { axios } from "../boot";

export default function useGetCategory() {
  const [categories, setCategories] = useState([]);
  const getCategories = () => {
    const url = "/pub/coursecats";
    axios.get(url).then((res) => {
      const data = res.data.data.map((e) => ({ ...e, isAdmin: true }));
      
      setCategories(data);
    });
  };
  return [categories, getCategories];
}