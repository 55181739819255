import React, { useEffect, useState } from "react";
import { ButtonGroup, Col } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Form, AdminInput, Select, MultiSelect } from "../../../components";
import { axios } from "../../../boot";
import { toast } from "../../../methods";
import { rules, blogStatus } from "../../../constants";
import { useGetProvinces, useGetCities, useGetProducts, useGetCourse } from "../../../hooks";

const AddCardPurchase = () => {
    const [data, setData] = useState({})
    const [activeTab, setActiveTab] = useState("course")
    const [selectOption, setSelectedOption] = useState()
    const [provinces] = useGetProvinces();
    const [cities, getCities] = useGetCities();
    const [products, getProducts] = useGetProducts()
    const [course, getCourses] = useGetCourse()
    const navigate = useNavigate()
    const installmentControl = [
        {
            name: "فعال",
            id: 1,

        },
        {
            name: "غیر فعال",
            id: 0,

        },
    ]
    const addCardpurchase = async () => {
        const url = `/admins/shop/addPay`;
        const body = {}
        body.date = data.date[0]
        body.phone = data.phone.slice(1)
        body.product = selectOption?.map((e) => e.value)?.toString()
        body.authority = data.authority
        body.refId = data.refId
        body.cardNumber = data.cardNumber
        body.firstName = data.firstName
        body.lastName = data.lastName
        
        if (activeTab == "book") {
            body.ostan = data.ostan
            body.city = data.city
            body.postCode = data.postCode
            body.address = data.address
        }else{
            body.installment = data?.installment == 1 ? true : false
        }
        axios.post(url, body).then(() => {

            toast({});
            setData({})
            setSelectedOption()
        });
    };
    useEffect(getCourses, [])
    useEffect(getProducts, [])
    let courseItem = course?.map((e) => ({ label: e.title, value: e._id }))
    let productItem = products?.map((e) => ({ label: e.title_fa, value: e._id }))
    let finalItem = [...courseItem, ...productItem]
    let types = [
        {
            name: "کتاب",
            id: "book"
        },
        {
            name: "دوره",
            id: "course"
        },
    ]
    const formControls = [
        {
            label: "شماره تلفن",
            state: "phone",
            labelColor: ""
        },
        {
            label: "نام",
            state: "firstName",
            labelColor: "black"
        },
        {
            label: "نام خانوادگی",
            state: "lastName",
            labelColor: "black"
        },
        {
            label: "کد ارجاع",
            state: "authority",
            labelColor: "black"
        },
        {
            label: "شماره پیگیری",
            state: "refId",
            labelColor: "black"
        },
        {
            tag: Select,
            label: "پرداخت اقساطی",
            state: "installment",
            items: installmentControl,
            as: AdminInput
        },
        // {
        //     tag: MultiSelect,
        //     label: "محصولات",
        //     state: "product",
        //     options: course.map((item) => ({ label: item.title, value: item._id }))
        // },
        {
            label: "4 رقم آخر شماره کارت",
            state: "cardNumber",
            labelColor: "black"

        },
        {
            type: "date",
            label: "زمان",
            state: "date",
            needTime: true,
            cancelBtn: true,
            labelColor: "black"

        },
    ]
    const formControls2 = [
        {
            label: "شماره تلفن",
            state: "phone",
            labelColor: "black"
        },
        {
            label: "نام",
            state: "firstName",
            labelColor: "black"
        },
        {
            label: "نام خانوادگی",
            state: "lastName",
            labelColor: "black"

        },
        {
            label: "کد ارجاع",
            state: "authority",
            labelColor: "black"

        },
        {
            label: "شماره پیگیری",
            state: "refId",
            labelColor: "black"

        },
        // {
        //     tag: Select,
        //     label: "محصولات",
        //     state: "product",
        //     items: products.map((item) => ({ name: item.title_fa, id: item._id }))
        // },
        {
            label: "کد پستی",
            state: "postCode",
            rules: rules.postCode,
            labelColor: "black"

        },
        {
            label: "4 رقم آخر شماره کارت",
            state: "cardNumber",
            labelColor: "black"

        },
        {
            tag: Select,
            label: "استان",
            state: "ostan",
            filter: true,
            items: provinces,
            as: AdminInput
        },
        {
            tag: Select,
            label: "شهر",
            state: "city",
            filter: true,
            items: cities,
            as: AdminInput
        },
        {
            type: "date",
            label: "زمان",
            state: "date",
            needTime: true,
            cancelBtn: true,
            labelColor: "black"
        },
        {
            as: "textarea",
            label: "آدرس",
            state: "address",
            labelColor: "black"
        }
    ]
    let t = {
        "book": formControls2,
        "course": formControls
    }
    useEffect(() => getCities(data.ostan), [data.ostan]);
    return (
        <div className="">
            <div className="d-flex flex-center my-4">
                <ButtonGroup>
                    {types.map((e) => (
                        <Button
                            key={e.id}
                            outline={e.id !== activeTab}
                            onClick={() => setActiveTab(e.id)}
                        >
                            {e.name}
                        </Button>
                    ))}
                </ButtonGroup>
            </div>
            <Form
                className="row"
                onSubmit={addCardpurchase}
            >
                <Col
                    xs="12"
                    lg="6" >
                    <MultiSelect selectedOptions={selectOption} setSelectedOptions={setSelectedOption} label="محصولات" options={finalItem} />
                </Col>
                {t[activeTab].map((item) => (
                    <Col
                        key={item.state}
                        xs="12"
                        lg={item.state === "address" ? "12" : "6"}
                    >
                        {React.createElement(item.tag ?? AdminInput, {
                            ...item,
                            value: data[item.state],
                            setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
                        })}
                    </Col>
                ))}

                <div className="col-12 d-flex flex-center">
                    <ButtonGroup>
                        {/* {!isNewCategory && (
                            <Button variant="danger" onClick={deleteCategory}>
                                حذف
                            </Button>
                        )} */}
                        <Button type="submit">ثبت</Button>
                    </ButtonGroup>
                </div>
            </Form>
        </div>
    )
}

export default AddCardPurchase