const installmentStatus = [
    {
      name: "پرداخت نشده",
      id: "new",
      color: "secondary",
    },
    {
      name: "در حال پرداخت",
      id: "pending",
      color: "warning",
      filterable: true,
    },
    {
      name: "پرداخت شده",
      id: "paid",
      color: "success",
      isUpdateStatus: true,
      filterable: true,
    },

    
  ];

export default installmentStatus