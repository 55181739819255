import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { axios } from "../../../boot";
import { Form as Formm, Button, Modal } from "../../../components";
import { toast } from "../../../methods";
import { useSelector } from "react-redux";
import AddAddress from "../MyAddress/AddAddress";
export default function Address({
  data = {},
  setData = () => { },
  activeAddress = false,
  setActiveAddress = () => { },
  setCartInfo = () => { },
}) {
  const [address, setAddress] = useState([])
  const [show, setShow] = useState(null)
  // const [provinces] = useGetProvinces();
  // const [cities, getCities] = useGetCities();
  const isLogged = useSelector((s) => s.isLogged);
  const navigate = useNavigate()
  const getAddress = () => {
    const url = "/address"
    axios.get(url).then(({ data }) => {
      // console.log("all address is : ",data)
      const res = data?.data
      setAddress(data?.data)
      if(res?.length > 0){
        chooseAddress(res[0]?._id , "")
      }
      
    })
  }
  const chooseAddress = (addressId = "" , text = "") => {
    const url = "/purchases/pre-purchase";
    setData(p => ({...p , "shippingId" : addressId }))
    const body = {};
    body.shippingId = addressId
    if (!body.shippingId) {
      let text = "آدرس نمی تواند خالی باشد"
      return toast({ text, type: "error" })
    }
    axios.post(url, body).then(({ data }) => {
      // const text = "آدرس با موفقیت ثبت شد. لطفا فرایند خرید را ادامه دهید";
      if(text?.length > 0){
        toast({ text });
      }
      setCartInfo(data);
      setActiveAddress(true);
    });
  };
  useEffect(() => {
    if (!isLogged) {
      const text = 'برای ادامه فرآیند خرید لطفا وارد حساب کاربری خود شوید.'
      toast({ text, type: "error" })
      navigate("/", { replace: true });
    }
  }, [])
  useEffect(getAddress, [])
  return (
    <React.Fragment>
      <Modal bg="black" size='lg' title={typeof show == "boolean" ? "اضافه کردن آدرس جدید" : "ویرایش آدرس"} show={show} onHide={() => setShow(null)} >
        <AddAddress getAddress={getAddress} setShow={setShow} info={show} />
      </Modal>
      <div className="wrapper">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center px-2 my-2 ">
          <div className="d-flex align-items-center my-1">
            <p className="fs-14 fw-400 text-white mx-1">آدرس های من</p>
          </div>
          <button onClick={() => setShow(true)} className=" btn bg-transparent text-primary fs-14 my-1"><i className="bi bi-plus mx-1"></i>ثبت آدرس جدید</button>
        </div>
        {address?.length > 0
          ?
          <Formm className="">
            {address?.map((e , index) =>
              <div key={e._id} className={`w-100 rounded-8 row d-flex align-items-center justify-content-start my-4 border ${data?.shippingId == e._id ? "border-primary" : ""} p-2`}>
                <Col xs="12" lg="1" className="d-flex align-items-center justify-content-start">
                  <Form.Check
                    type="radio"
                    name="address"
                    id={e._id}
                    value={e._id}
                    label={""}
                    defaultChecked={(address?.length > 0 && index ==0 ) && e._id}
                    onChange={e => chooseAddress(e?.target?.value ,"آدرس با موفقیت ثبت شد. لطفا فرایند خرید را ادامه دهید")}
                  />
                  <span style={{ width: "2px", height: "50px" }} className="bg-white mx-4 d-none d-lg-block"></span>
                </Col>
                <Col xs="12" lg="10">
                  <div className="row">
                    <Col xs="12">
                      <p className=""> <i className={`bi bi-geo-alt text-white fw-bold mx-1`}></i> <span className="text-white fw-bold">آدرس : </span>{e.address}</p>
                    </Col>
                    <Col xs="12" lg="6">
                      <p className=""> <i className={`bi bi-person text-white fw-bold mx-1`}></i> <span className="text-white fw-bold">تحویل گیرنده : </span>{e.firstName} {e.lastName}</p>
                    </Col>
                    <Col xs="12" lg="6">
                      <p className=""> <i className={`bi bi-phone text-white fw-bold mx-1`}></i> <span className="text-white fw-bold">شماره تماس : </span>{e.phone}</p>
                    </Col>
                  </div>
                </Col>
                <Col xs="12" lg="1" className="d-flex justify-content-end align-items-center">
                  <button type="button" onClick={() => setShow(e)} className="btn  text-white mx-1"><i className="bi bi-pencil"></i></button>
                </Col>

              </div>
            )}
            {/* <Button type="submit" className="w-100">
              <i className="bi bi-pen-fill ms-1" />
              ثبت اطلاعات گیرنده
            </Button> */}
          </Formm>
          :
          <p className="text-white text-center">برای شما آدرسی ثبت نشده است لطفا آدرس خود را اضافه کنید</p>
        }
      </div>
    </React.Fragment>
  );
}
