import { useState } from 'react'
import { Container, Row, Col, Fade } from 'react-bootstrap'
import VideoPlay from '../../assets/icons/play.svg'
import Clock from '../../assets/images/FreeTutorial/yellowClock.png'
import Notebook from '../../assets/icons/notebook2.svg'
import Paper from '../../assets/icons/paper.svg'

const TopicsTaughtCard = ({
    name = "",
    numberOfSessions = "",
    time = "",
    sessions = [],
    index,
    description = "",
}) => {
    const [open, setOpen] = useState(false)

    const typeHandler = (enter) =>{
        
        if(enter == "video"){
            return "ویدیو"
        }else if(enter == "Quiz"){
            return  "آزمون"
        }else{
            return "مقاله"   
        }
    }

    const svgHandler = (type) => {
        if(type == "video"){
            return VideoPlay
        }else if(type == "Quiz"){
            return  Paper
        }else{
            return Notebook  
        }
    }
    
    return (
        <Row className="d-lg-flex align-items-start">
            <Col className="position-relative" xs={12} lg={3}>
                <div className="">
                    <p className="fs-16 fw-400 text-start text-white mx-4 my-2">فصل</p>
                    <p className="fs-40 fw-500 text-start text-white mx-4 my-2">{index + 1}</p>
                </div>
            </Col>
            <Col xs={12} lg={9} className="p-2">
                <div className="d-flex align-items-center">
                    <span className=" YellowClock d-flex justify-content-center align-items-center rounded-circle">
                        <img src={Clock} alt="clock" />
                    </span>
                    <p className="mx-2 fs-16 fw-700 text-yellow">{time}</p>
                </div>
                <p className='fs-18 fw-700 mt-4 mb-2 text-white'>{name}</p>
                <p className="fs-12 fw-400 lh-md">
                    {description}
                </p>

                <div className="d-flex justify-content-between align-items-center w-100 my-4">
                    <div className="d-flex align-items-center">
                        <span className=" topicBook d-flex justify-content-center align-items-center rounded-circle">
                            <img src={Notebook} alt="book" />
                        </span>
                        <p className="mx-2 fs-16 fw-700 text-white">{numberOfSessions}</p>
                    </div>
                    <button onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open} className="border-0 bg-transparent text-primary">{open ? "مشاهده کمتر" : "مشاهده بیشتر"}</button>

                </div>
                
                <Fade timeout={500} in={open}>
                    <div className={open ? "h-auto" : "h-0"} id="example-fade-text" >
                        {sessions?.map((e) => 
                            <div key={e._id} className='my-4'>
                                <div className="d-flex align-items-center">
                                    <div style={{ width: "22px", height: "22px" }} className="rounded-circle border border-primary flex-center">
                                        <img width="12" hieght="12" src={svgHandler(e.type)} alt="pic" />
                                    </div>
                                    <p className="fs-12 fw-500 mx-2 text-white">{typeHandler(e.type)}</p>
                                </div>
                                <div className="d-flex align-items-center my-2">
                                    <p className="fs-10 text-white">{e.name}</p>
                                    <p className="fs-10 fw-500 mx-2">{e.time}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </Fade>
                <hr />

            </Col>
        </Row>
    )
                    }


export default TopicsTaughtCard