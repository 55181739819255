import React, { useState, useEffect } from 'react'

import { useNavigate, useParams } from "react-router";
import cloneDeep from "lodash/cloneDeep";
import { Col, ButtonGroup } from "react-bootstrap";
import { rules, roles, accept } from "../../../constants";
import { fileToBase64, toast } from "../../../methods";
import { Form, AdminInput, Select, Button } from "../../../components";
import { axios, moment } from "../../../boot";

const Calendar = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const classHandler = [
        {
            name : "بنفش",
            id : "bg-purple"
        },
        {
            name : "نارنجی",
            id : "bg-orange"
        },
        {
            name : "سبز",
            id : "bg-green"
        },
        {
            name : "قرمز",
            id : "bg-red"
        },
        {
            name : "آبی",
            id : "bg-blue"
        },
    ]
    const isNewUser = params.id === "new";
    const fromControls = [
        {
            label: "نام دوره",
            state: "title",
        },
        {
            label: "نام استاد",
            state: "teacher",
        },
        {
            label: "زمان کلاس",
            state: "time",
        },
        {
            label: "تاریخ برگزاری کلاس",
            state: "start",
            type : "date"
        },
        {
            tag: Select,
            label: "رنگ کلاس",
            state: "className",
            items : classHandler,
            as : AdminInput,

        },
    ];
    const getData = () => {
        if (!isNewUser) {
            const url = `/admins/calendar/${params.id}`;
            axios.get(url).then(({ data }) => {
                console.log(data)
                const body = {}
                body.teacher = data?.extendedProps?.teacher
                body.time = data?.extendedProps?.time
                body.start = data?.start
                body.title = data?.title
                body.className = data?.className
                body._id = data?._id
                setData(body);
            });
        }
    };
    const addCalendar = () => {
        const url = "/admins/calendar";
        axios.post(url, data).then(({ data }) => {
            toast({});
            navigate("/admin/calendars", { replace: true });
        });
    };
    const changeCalendar = () => {
        const url = "/admins/calendar";
        axios.put(url, data).then(() => {
            toast({});
            navigate("/admin/calendars", { replace: true });

        });
    };
    const deleteCalendar = () => {
        const message = "آیا از درخواست خود اطمیمان دارید؟";
        if (window.confirm(message)) {
            const url = "/admins/calendar";
            const body = { _id: params.id };
            axios.delete(url, { data: body }).then(() => {
                toast({});
                navigate("/admin/calendars", { replace: true });
            });
        }
    };
    useEffect(getData, []);
    return (
        <Form onSubmit={isNewUser ? addCalendar : changeCalendar} className="row">
            <h5 className="text-center col-12">
                {isNewUser ? "اضافه کردن تقویم کلاسی جدید" : "اطلاعات تقویم کلاس"}
            </h5>
            {fromControls.map((props, index) => (
                <Col key={index} xs="12" md="6">
                    {React.createElement(props.tag ?? AdminInput, {
                        ...props,
                        value: data[props.state],
                        setValue: (val) => setData((p) => ({ ...p, [props.state]: val })),
                    })}
                </Col>
            ))}
            <div className="col-12 d-flex flex-center">
                <ButtonGroup>
                    {!isNewUser && (
                        <Button variant="danger" onClick={() => deleteCalendar()}>
                            حذف
                        </Button>
                    )}
                    <Button type="submit">ثبت</Button>
                </ButtonGroup>
            </div>
        </Form>
    )
}

export default Calendar