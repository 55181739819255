import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { axios } from "../../boot";
import Notifications from "./Notifications";
import Menu from "./Menu";
import MenuContent from "./MenuContent";
import Breadcrumbs from "./Breadcrumbs";
import { FooterContent } from "../../components";
import { useShowFullName } from "../../hooks";
import CartCountProvider from "../../contexts/CartCount";
import "./index.scss";
import CartLink from "./CartLink";
import Header from "./Header";
import Footer from "./Footer";
import Notification from "../../components/Notification";
import SupportBtn from "../../components/SupportBtn.js";


export default function UsersPanel() {
  const showFullName = useShowFullName();
  // const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false)
  // const logTime = () => {
  //   const url = "/activity-logs/time";
  //   const time = 5 * 60 * 1000;
  //   const callback = () => axios.post(url);
  //   callback();
  //   const interval = setInterval(callback, time);
  //   return () => {
  //     callback();
  //     clearInterval(interval);
  //   };
  // };
  // useEffect(logTime, []);
  return (
    <CartCountProvider>
      <Row className="UsersPanel align-items-start ">
        <Menu show={show} onHide={setShow} />
        <Col xs="12" lg="3" xl="2" className="d-none d-lg-block p-0 ">
          <div className="sidebar d-flex align-items-center flex-column justify-content-start py-4  ">
            <MenuContent />
          </div>
        </Col>
        <Col xs="12" lg="9" xl="10" className="main-section p-0">
          <header className="d-lg-none d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-lg-between">
            <div className="user-controls d-flex align-items-center justify-content-between gap-3 p-2">
              <button
                onClick={() => setShow(true)}
                className="bi bi-list fs-20 d-flex fle-center d-lg-none text-primary"
              />
              {/* <Notifications /> */}
              
              <div className="d-flex align-items-center">
                <CartLink />
                {/* <Link
                  to="profile"
                  className="Button btn btn-primary d-flex flex-center col-gap-1 white-space-nowrap mx-2"
                >
                  {showFullName()}
                  <i className="bi bi-person-fill" />
                </Link> */}
              </div>
            </div>
          </header>
          <main className="position-relative">
            <Breadcrumbs />
            <Outlet />
            <SupportBtn/>
          </main>
          {/* <footer className="w-100">
            <FooterContent as="div" />
          </footer> */}
        </Col>
      </Row>
    </CartCountProvider>

  );
}
