import { Container, Row, Col, Fade } from 'react-bootstrap'
import { TopicsTaughtCard, Video } from '../../../components'

const TopicsTaught = ({ data = {} }) => {
  return (
    <Container id="topics" className="TopicsTaught">
      <Row>
        <Col xs="12" lg="6">
          <div style={{ width: "100%" }} >
            {data?.preview ?
              <Video
                width="100%"
                thumbnail={data?.fullScreenCover}
                sources={[
                  {
                    src: data?.preview,
                  },
                ]}
              />
              :
              <img loading='lazy' src={data?.fullScreenCover} alt={data?.title} className="object-fit-cover rounded-4" height="348" />
            }
          </div>
        </Col>
        <Col className='topic-section p-1' xs="12" lg="6">
          {/* <button className='d-flex align-items-center justify-content-start w-100 rounded-4 btn btn-dark-black p-1 text-white fs-14'>
            <span className='mx-1'><i className="bi bi-play-circle-fill text-primary fs-30"></i></span>
            ویدیو نمونه تدریس شده
          </button> */}
          <p className="fs-12 fw-700 mb-2">سرفصل ها</p>
          {data?.topic?.length > 0 ?
            <div className="topic-section-ul">
              <ul className="" >
                {data?.topic?.map((e, index) =>
                  <li key={index} className="w-100 bg-dark-black text-white rounded-4 d-flex align-items-center justify-content-start my-2 px-1">
                    <div className="d-flex align-items-center">
                      <span style={{ width: "32px", height: "32px" }} className="bg-black text-white rounded-circle d-flex flex-center">{index + 1}</span>
                      <p className="fs-14 mx-1">{e?.name}</p>
                    </div>
                  </li>
                )}
              </ul>
            </div>
            :
            <p>برای این دوره هنوز سرفصلی تعریف نشده است</p>
          }


        </Col>
      </Row>

      {data?.topics?.map((e, index) =>
        <TopicsTaughtCard key={e._id} {...e} index={index} />
      )}
    </Container>
  )
}

export default TopicsTaught