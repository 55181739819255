import { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const UserInfo = () => {
    const profile = useSelector(e => e.profile)
    const [data, setData] = useState({})
    const formControls2 = [
        {
            label: "نام",
            state: "firstName",
        },
        {
            label: "نام خانوادگی",
            state: "lastName",
        },
        {
            label: "ایمیل",
            state: "email",
        },
        {
            label: "شماره موبایل",
            state: "phone",
        },
    ];
    useEffect(() => setData(profile), [profile]);
    return (
        <div className="bg-dark-black rounded-4 px-2 py-4">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center px-2 my-2">
                <div className="d-flex align-items-center my-1">
                    <i className="bi bi-person text-primary fs-16"></i>
                    <p className="fs-14 fw-400 text-white mx-1">اطلاعات کاربری</p>
                </div>
                <Link to="/profile" className="bg-transparent text-primary fs-14 my-1"><i className="bi bi-pencil mx-1"></i>ویرایش اطلاعات حساب کاربری</Link>
            </div>
            <Row className="d-flex align-items-start justify-content-start my-4">
                {formControls2.map(e =>
                    <Col key={e.state} className="py-1" xs="12" sm="6" lg="3" >
                        <p className="text-white fs-16">{e.label} :</p>
                        <p className="fs-14 my-2">{data[e.state] ? data[e.state] : "-"}</p>
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default UserInfo