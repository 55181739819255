const blogStatus = [
  {
    name: "فعال",
    id: "enabled",
    color: "success",
  },
  {
    name: "غیر فعال",
    id: "disabled",
    color: "danger",
  },
];
export default blogStatus;
