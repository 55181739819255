import React, { useContext, useState } from 'react'
import { axios } from '../../../boot'
import { AdminInput, Form, Input, Select } from '../../../components'
import { Col } from 'react-bootstrap'
// import './index.scss'
import { convertPhone, toast } from '../../../methods'
import logo from '../../../assets/logos/Marvel academy.svg'
import { dateBirth, rules } from '../../../constants'
import { AiFillInfoCircle } from "react-icons/ai";
import Context from './_context'

const AddChart = () => {
    const [page , setPage] = useContext(Context)
    const [data, setData] = useState({})

    const formControls = [
        {
            label: "نام",
            labelColor: "white",
            state: "firstName"
        },
        {
            label: "نام خانوادگی",
            labelColor: "white",
            state: "lastName"
        },
        {
            label: "شماره تماس",
            type: "number",
            labelColor: "white",
            state: "phone"
        },
        {
            label: "آیدی تلگرام",
            labelColor: "white",
            state: "telegramid"
        },
        {
            label: "ایمیل",
            type: "email",
            labelColor: "white",
            state: "email",
        },
        {
            label: "محل تولد",
            labelColor: "white",
            state: "placeofbirth",
        },
        {
            tag: Select,
            as: Input,
            label: "سال تولد",
            labelColorr: "white",
            state: "birthYear",
            placeholder: "سال تولد به میلادی",
            items: dateBirth?.years
        },
        {
            tag: Select,
            label: "ماه تولد",
            labelColorr: "white",
            state: "birthMonth",
            placeholder: "ماه تولد به میلادی",
            items: dateBirth?.months
        },
        {
            tag: Select,
            as: Input,
            label: "روز تولد",
            labelColorr: "white",
            state: "birthday",
            placeholder: "روز تولد به میلادی",
            items: dateBirth?.days
        },
        {
            label: "ساعت تولد",
            labelColor: "white",
            state: "birthHour",
            placeholder: "ساعت تولد (اختیاری) را وارد کنید",
            type: "number",
            rules: rules.optional
        },
        {
            label: "دقیقه تولد",
            labelColor: "white",
            state: "birthMinute",
            placeholder: "دقیقه تولد (اختیاری) را وارد کنید",
            type: "number",
            rules: rules.optional
        },
        {
            as: "textarea",
            label: "توضیحات",
            placeholder: " توضیحات (اختیاری) را وارد کنید",
            labelColor: "white",
            state: "description",
            rules: rules.optional
        },
    ]
    const sendData = () => {
        const url = "https://api.academymarvel.com/api/class/payChart"
        console.log(data)
        axios.post(url, data).then(({ data }) => {
            console.log(data)
            let text = "در حال انتقال به درگاه پرداخت"
            toast({ type: "success", text })
            const link = data.url;
            window.location.href = link;
            setData({})
        })
    }
    return (
                <Form onSubmit={sendData} className="  row w-100">
                    <Col xs="12" className="d-flex justify-content-center">
                        <img src={logo} alt='logo' />
                    </Col>
                    {formControls.map((item) =>
                        <Col key={item.state} xs="12" lg={item.state == "description" ? "12" : "6"}>
                            {React.createElement(item.tag ?? Input, {
                                ...item,
                                value: data[item.state],
                                setValue: (val) => setData((p) => ({ ...p, [item.state]: val })),
                            })}
                            {item.state == "description" &&
                                <div>
                                    <p className="text-danger fw-500 fs-12"><span className="mx-1"><AiFillInfoCircle size={14} /></span>در صورتی که تاریخ تولد را به صورت دقیق وارد نکرده اید و یا تاریخ تولد شخص دیگری را وارد کرده اید در قسمت توضیحات برای ما شرح دهید.</p>
                                </div>
                            }
                        </Col>
                    )}
                    <div className="mt-4">
                        <p className="text-white text-center fs-14 fw-500">مدن زمان آماده شدن چارت ستاره شناسی : <span className="fs-14 fw-700">بعد از اتمام کلاس های ستاره شناسی</span></p>
                        <p className="text-center text-primary fs-500 fs-14 my-2">قیمت : <span className="fs-14 fw-700 text-primary ">30 دلار - یک میلیون پانصدهزارتومن</span></p>
                        <p className="text-center text-success fw-500 fs-14">هزینه پرداختی با تخفیف : <span className="fw-700 fs-14 text-success">1 میلیون تومان</span></p>
                    </div>
                    <Col>
                        <button className='btn btn-primary text-white w-100 rounded-sm'>ثبت و پرداخت</button>
                        <button type='button' onClick={()=>setPage("charts")} className='text-danger w-100 bg-transparent border-0 my-4'>بازگشت <i className="bi bi-arrow-left-short"></i></button>
                    </Col>

                </Form>
    )
}

export default AddChart