import React, { useState, useEffect } from 'react'
import { axios } from '../../../boot'
import { Container, Col } from 'react-bootstrap'
import { Button, Form, Input, Select } from '../../../components'
import { useGetCities, useGetProvinces } from '../../../hooks'

const ZeroPoint = () => {
  const [data, setData] = useState({})
  const [provinces] = useGetProvinces();
  // const [cities, getCities] = useGetCities();
  // const [times, getTimes] = useGetConsultation()
  // useEffect(getTimes, [])
  const genderItems = [
    { id : "male" , name : "مرد"},
    { id : "female" , name : "زن"},
  ]
  const items = [
    {
      id: "66bf4a5eb14eb4a9d83448a5",
      name: "نقطه صفر بازنشسته ها"
    },
    {
      id: "66bf4aafb14eb4a9d8356532",
      name: "نقطه صفر 10 تا 15 سال"
    },
    {
      id: "66bf4ad2b14eb4a9d835db83",
      name: "نقطه صفر 15 تا 22 سال"
    },
    {
      id: "66bf4af7b14eb4a9d8365f72",
      name: "نقطه صفر 22 تا 30 سال",
    },
    {
      id: "66bf4b23b14eb4a9d836f5fa",
      name: "نقطه صفر 30 تا 50 سال"
    }
  ]
  const formControls = [
    {
      label: "نام",
      state: "firstName",
      labelColor: "white",
    },
    {
      label: "نام خانوادگی",
      state: "lastName",
      labelColor: "white",
    },
    {
      label: "شماره همراه",
      state: "phone",
      labelColor: "white",
      type: "tel"
    },
    {
      tag: Select,
      label: "لیست کانال ها",
      state: "channel",
      labelColorr : "white",
      filter: true,
      items,
      cancelBtn: true,
    },
    {
      tag: Select,
      label: "استان",
      state: "state",
      filter: true,
      items: provinces,
      labelColorr: "white",
      cancelBtn: true,
  },
  // {
  //     tag: Select,
  //     label: "شهر",
  //     state: "city",
  //     filter: true,
  //     items: cities,
  //     labelColorr: "white",
  //     cancelBtn: true,
  // },
  {
    tag: Select,
    label: "جنسیت",
    state: "gender",
    items: genderItems,
    labelColorr: "white",
    cancelBtn: true,
},
  ]
  const Buyconsultation = () => {
    const url = "https://api.academymarvel.com/api/class/payZeroPoint"
    axios.post(url, data).then(({ data }) => {
      const link = data.url;
      window.location.href = link;
      setData({})
    })
  }
  // useEffect(() => getCities(data?.province), [data?.province]);
  // if (times.length === 0) return <p className='text-center fs-25 text-primary fw-700 my-4'>در حال حاضر تمامی زمان های مشاوره رزرو شده اند</p>
  return (
    <Container className="BuyConsultation py-4 ">
      <Form onSubmit={Buyconsultation} className="row flex justify-content-start my-4 ConsultationForm rounded p-4">
        <p className="text-white fs-25 fw-700 text-center my-4">ثبت نام کانال نقطه صفر</p>
        {formControls.map((e, index) =>
          <Col key={index} xs="12" md="12" lg="6">
            {React.createElement(e.tag ?? Input, {
              ...e,
              key: e.state,
              value: data[e.state],
              className: "bg-raisin2 text-white rounded-4 fs-14 fw-400",
              setValue: (val) => setData((p) => ({ ...p, [e.state]: val })),
            })}
            {/* {(e.state === "capital" && data[e.state]) && <p className='fs-12 text-success'>{wordifyfa(data[e.state])} تومان</p>} */}
          </Col>
        )}
        <p className="text-right fs-15 fw-500 text-primary" >هزینه ماهانه کانال نقطه صفر : 1 میلیون تومان</p>
        <div className="col-12 d-flex flex-center">
          <Button type="submit">ثبت‌ نام و پرداخت</Button>
        </div>
      </Form>
    </Container>
  )
}

export default ZeroPoint